// noinspection JSIgnoredPromiseFromCall

import {message} from 'antd';
import config from '../config';
import request from './request';
export default function useLogout() {
  return () => {
    request.post('/company/logout');
    config.token = undefined;
    config.userInfo = undefined;
    config.companyInfo = undefined;
    window.localStorage.removeItem('company.token');
    message.success('已经退出登录', 2, () => {
      window.location.href = `${window.location.origin}${config.staticPath}#/login`;
    });
  };
}
