import {Tabs} from 'antd';
import React from 'react';
import BarrierAlert from './barrierAlert';
// import StartAlert from './startAlert';
import DesignAlert from './designAlert';
import './index.scss';
import '../nodeAlert/nodeAlert.scss';
const NodeProcess = (props) => {
  return (
    <div>
      <Tabs defaultActiveKey="barrier" tabBarStyle={{paddingLeft: '20px', backgroundColor: 'white'}}>
        <Tabs.TabPane tab="保护层" key="barrier">
          <BarrierAlert />
        </Tabs.TabPane>
        <Tabs.TabPane tab="设计错误" key="design">
          <DesignAlert />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};
export default NodeProcess;
