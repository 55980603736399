// noinspection JSUnresolvedFunction,JSUnresolvedVariable

import { inject, observer } from 'mobx-react';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import ResultModal from './resultModal';
import ResultView from './resultView';
import StartEventModal from './startEventModal';
import StartEventView from './startEventView';
import config from '../../../config';
// import {BarrierPropsData} from './barrierView';
import log from 'loglevel';
log.setLevel(config.debug ? log.levels.DEBUG : log.levels.ERROR);
const StreamModal = (props, ref) => {
    const [startDragEnter, setStartDragEnter] = useState(false);
    const [resultDragEnter, setResultDragEnter] = useState(false);
    const streamViewRef = useRef();
    const [svgView, setSvgView] = useState();
    const [newStartIndex, setNewStartIndex] = useState(0);
    const [newResultIndex, setNewResultIndex] = useState(0);
    // 拖拽进入
    const onDragEnter = (e) => {
        const source = global.dragItem;
        log.debug('stream-view 拖拽进入', source);
        if (source === 'add_startEvent' || source.startsWith('start_event_')) {
            setStartDragEnter(true);
        }
        if (source === 'add_result' || source.startsWith('result_')) {
            setResultDragEnter(true);
        }
    };
    const onDragOver = (e) => {
        e.preventDefault();
        const source = global.dragItem;
        if (source === 'add_startEvent' || source.startsWith('start_event_')) {
            const startEventList = props.stream.startEventList || [];
            let newStartIndex = startEventList.length;
            for (let si = 0; si < startEventList.length; si++) {
                const start_Event_doc = document.getElementById(`start_event_${props.streamIndex}_${si}`);
                if (start_Event_doc) {
                    const react = start_Event_doc.getBoundingClientRect();
                    if (react.y + react.height > e.clientY) {
                        newStartIndex = si;
                        break;
                    }
                }
            }
            setNewStartIndex(newStartIndex);
        }
        if (source === 'add_result' || source.startsWith('result_')) {
            const resultList = props.stream.resultList || [];
            let newResultIndex = resultList.length;
            for (let si = 0; si < resultList.length; si++) {
                const result_doc = document.getElementById(`result_${props.streamIndex}_${si}`);
                if (result_doc) {
                    const react = result_doc.getBoundingClientRect();
                    if (react.y + react.height > e.clientY) {
                        newResultIndex = si;
                        break;
                    }
                }
            }
            setNewResultIndex(newResultIndex);
        }
    };
    // 拖拽离开
    const onDragLeave = (e) => {
        let relatedTarget = e.nativeEvent.relatedTarget;
        if (streamViewRef.current === relatedTarget || streamViewRef.current.contains(relatedTarget)) {
            return;
        }
        const source = global.dragItem;
        log.debug('stream-view 拖拽离开', source);
        if (source === 'add_startEvent' || source.startsWith('start_event_')) {
            setStartDragEnter(false);
        }
        if (source === 'add_result' || source.startsWith('result_')) {
            setResultDragEnter(false);
        }
    };
    // 拖拽放下
    const onDrop = (e) => {
        let _a, _b, _c, _d;
        const source = global.dragItem;
        log.debug('元素拖拽放在stream-view 位置：', e.clientX, e.clientY, '来源：', source);
        if (source === 'add_startEvent' || source.startsWith('start_event_')) {
            setStartDragEnter(false);
        }
        if (source === 'add_result' || source.startsWith('result_')) {
            setResultDragEnter(false);
        }
        if (source === 'add_startEvent') {
            (_a = startEventModalRef.current) === null || _a === void 0 ? void 0 : _a.open(newStartIndex);
        }
        if (source.startsWith('start_event_')) {
            const startIndexArr = source.split('_');
            const fromPosition = {
                streamIndex: parseInt(startIndexArr[2]),
                startIndex: parseInt(startIndexArr[3]),
            };
            const toPositoon = {
                streamIndex: props.streamIndex,
                startIndex: newStartIndex,
            };
            (_b = props.editNode) === null || _b === void 0 ? void 0 : _b.moveStart(fromPosition, toPositoon);
        }
        if (source === 'add_result') {
            (_c = resultModalRef.current) === null || _c === void 0 ? void 0 : _c.open(newResultIndex);
        }
        if (source.startsWith('result_')) {
            const resultIndexArr = source.split('_');
            const fromPosition = {
                streamIndex: parseInt(resultIndexArr[1]),
                resultIndex: parseInt(resultIndexArr[2]),
            };
            const toPositoon = {
                streamIndex: props.streamIndex,
                resultIndex: newResultIndex,
            };
            (_d = props.editNode) === null || _d === void 0 ? void 0 : _d.moveResult(fromPosition, toPositoon);
        }
    };
    const startEventList = useMemo(() => {
        if (startDragEnter) {
            const tempStartList = [...props.stream.startEventList];
            tempStartList.splice(newStartIndex, 0, { temp: true });
            return tempStartList;
        }
        else {
            return props.stream.startEventList || [];
        }
    }, [startDragEnter, newStartIndex, props.stream.startEventList]);
    const resultList = useMemo(() => {
        if (resultDragEnter) {
            const tempResultList = [...props.stream.resultList];
            tempResultList.splice(newResultIndex, 0, { temp: true });
            return tempResultList;
        }
        else {
            return props.stream.resultList || [];
        }
    }, [resultDragEnter, newResultIndex, props.stream.resultList]);
    // 起始事件弹窗
    const startEventModalRef = useRef();
    // 编辑起始事件
    const onEditStartEvent = (data) => {
        let _a;
        (_a = startEventModalRef.current) === null || _a === void 0 ? void 0 : _a.open(data.startIndex, data);
    };
    // 编辑后果
    const onEditResult = (data) => {
        let _a;
        (_a = resultModalRef.current) === null || _a === void 0 ? void 0 : _a.open(data.resultIndex, data);
    };
    // 后果弹窗
    const resultModalRef = useRef();
    // 双击事件
    const onDoubleClick = () => {
        props.onEditStream(props);
    };
    const [selectedBarrier, setSelectedBarrier] = useState();
    const onSelectBarrier = (data) => {
        if ((data === null || data === void 0 ? void 0 : data.barrier_work_type_id) === (selectedBarrier === null || selectedBarrier === void 0 ? void 0 : selectedBarrier.barrier_work_type_id)) {
            setSelectedBarrier(undefined);
        }
        else {
            setSelectedBarrier(data);
        }
    };
    useEffect(() => {
        const initiatot_group_doc = document.getElementById(`initiatot_group_${props.streamIndex}`);
        const top_event_doc = document.getElementById(`top_event_${props.streamIndex}`);
        const result_group_doc = document.getElementById(`result_group_${props.streamIndex}`);
        if (!initiatot_group_doc || !top_event_doc || !result_group_doc)
            return;
        const initRect = initiatot_group_doc.getBoundingClientRect();
        const topEventRect = top_event_doc.getBoundingClientRect();
        const resultRect = result_group_doc.getBoundingClientRect();
        const centerPoint = `${initRect.width + topEventRect.width / 2},${(topEventRect.height - 40) / 2 + 40}`;
        if (initRect && topEventRect && resultRect) {
            const style = {
                fill: '#00000000',
                stroke: '#4cd964',
                strokeWidth: 2,
                borderStyle: 'solid',
                borderWidth: 1,
                borderColor: '#eee',
            };
            const svgLineData = [];
            for (let si = 0; si < props.stream.startEventList.length; si++) {
                const itemHeight = (initRect.height - 40) / props.stream.startEventList.length;
                const point1 = `0,${itemHeight * si + 40 + 35}`;
                const point2 = `${initRect.width},${itemHeight * si + 40 + 35}`;
                svgLineData.push({
                    points: `${point1} ${point2} ${centerPoint}`,
                    style,
                });
            }
            for (let ri = 0; ri < props.stream.resultList.length; ri++) {
                const itemHeight = (resultRect.height - 40) / props.stream.resultList.length;
                const point1 = `${initRect.width + topEventRect.width},${itemHeight * ri + 40 + 35}`;
                const point2 = `${initRect.width + topEventRect.width + resultRect.width},${itemHeight * ri + 40 + 35}`;
                svgLineData.push({
                    points: `${centerPoint} ${point1} ${point2}`,
                    style,
                });
            }
            // noinspection JSCheckFunctionSignatures
            setSvgView(
                <svg width={initRect.width + topEventRect.width + resultRect.width} height={topEventRect.height} className="svg_view">
                    {svgLineData.map((lineData, lIndex) => (
                        <polyline key={lIndex} points={lineData.points} style={lineData.style} />
                    ))}
                </svg>,
            );
        }
    }, [props]);
    return (
        <div
            className={`stream-view`}
            id={`stream_${props.streamIndex}`}
            ref={streamViewRef}
            onDragEnter={onDragEnter}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}>
            {svgView}
            <div className={`start-event-view ${startDragEnter ? 'drag-enter-border' : ''}`} id={`initiatot_group_${props.streamIndex}`}>
                <div className="title-view">预防LOPC发生</div>
                {startEventList.map((start, index) => {
                    let number = 1;
                    for (let i = 0; i < index; i++) {
                        const e = startEventList[i];
                        if (start.start_event_type_id === e.start_event_type_id && start.deviation_type_id === e.deviation_type_id) {
                            number += 1;
                        }
                    }
                    start.number = number;
                    return (
                        <StartEventView
                            streamIndex={props.streamIndex}
                            startIndex={index}
                            start={start}
                            onEditStartEvent={onEditStartEvent}
                            onSelectBarrier={onSelectBarrier}
                            selectedBarrier={selectedBarrier}
                            key={start.start_event_id}
                        />
                    );
                })}
            </div>
            <div className="top_event-view" id={`top_event_${props.streamIndex}`}>
                <div className="title-view">顶级事件</div>
                <div className="top_event_content">
                    <div className="top_event_bg" onDoubleClick={onDoubleClick}>
                        <span>{props.publicData?.getTopEventType(props.stream.top_event_type_id)?.label || '顶级事件'}</span>
                    </div>
                </div>
            </div>
            <div className={`result_content_view ${resultDragEnter ? 'drag-enter-border' : ''}`} id={`result_group_${props.streamIndex}`}>
                <div className="title-view">降低LOPC后果</div>
                {resultList.map((r, index) => (
                    <ResultView
                        streamIndex={props.streamIndex}
                        resultIndex={index}
                        result={r}
                        onEditResult={onEditResult}
                        onSelectBarrier={onSelectBarrier}
                        selectedBarrier={selectedBarrier}
                        key={r.result_id}
                    />
                ))}
            </div>
            <StartEventModal ref={startEventModalRef} streamIndex={props.streamIndex} />
            <ResultModal ref={resultModalRef} streamIndex={props.streamIndex} />
        </div>
    );
};
export default inject('editNode', 'publicData')(observer(forwardRef(StreamModal)));
