// page：节点记录
import { Breadcrumb, Col, Row } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { loadNodeInfoDiff, request } from '../../util';
import config from '../../config';
import log from 'loglevel';
log.setLevel(config.debug ? log.levels.DEBUG : log.levels.ERROR);
const ChartRecord = () => {
    const navigate = useNavigate();
    const node_id = useParams().node_id;
    log.debug('page：节点记录', node_id);
    useEffect(() => {
        queryRecordList(node_id);
    }, [node_id]);
    const [recordList, setRecordList] = useState([]);
    const queryRecordList = (node_id) => {
        request.post('/company/queryNodeRecordList', { node_id }, true).then((res) => {
            setRecordList(res.recordList);
        });
    };
    const renderItem = (record, index) => {
        const node_old = record.node_old ? JSON.parse(record.node_old) : undefined;
        const node_new = record.node_new ? JSON.parse(record.node_new) : undefined;
        let texts = [];
        switch (record.action) {
            case 1:
                texts.push(`新增节点: ${node_new === null || node_new === void 0 ? void 0 : node_new.name}`);
                break;
            case 2:
                texts.push(`删除节点: ${(node_old === null || node_old === void 0 ? void 0 : node_old.name) || (node_new === null || node_new === void 0 ? void 0 : node_new.name)}`);
                break;
            case 3:
                if (node_old && node_new) {
                    texts = loadNodeInfoDiff(node_old, node_new);
                }
                else {
                    texts.push(`修改节点: ${(node_old === null || node_old === void 0 ? void 0 : node_old.name) || (node_new === null || node_new === void 0 ? void 0 : node_new.name)},修改内容缺失`);
                }
                break;
            default:
                break;
        }
        return (
            <div className="mb-5 mt-0 com-white-block">
            <Row justify={'space-between'} className="com-border-dashed-bottom">
                <Col md={10} sm={24} xs={24}>
                    节点名称: <span className="font-weight-bold">{node_old?.name || node_new?.name}</span>
                </Col>
                <Col span={7}>修改人: {record.operator_name}</Col>
                <Col span={7}>修改时间: {dayjs(record.create_time).format('YYYY-MM-DD HH:mm')}</Col>
            </Row>
            {record.action !== 1 && (
                <p style={{fontSize: 18, color: '#333', fontWeight: 'bold'}}>
                    {record.action === 2 ? '删除理由' : '修改理由'}:{record.info}
                </p>
            )}

            {texts.map((t) => (
                <Row justify={'space-between'} className="text-color-second mt-10">
                    <Col md={10} sm={24} xs={24}>
                        {t}
                    </Col>
                </Row>
            ))}
        </div>
        );
    };
    return (
        <div className="p-lg">
            {/* part:导航栏 */}
            <Breadcrumb className="mb-base">
                <Breadcrumb.Item>平台管理</Breadcrumb.Item>
                <Breadcrumb.Item>
                    <a href="/" onClick={() => navigate(-1)}>
                        节点列表
                    </a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>修改记录</Breadcrumb.Item>
            </Breadcrumb>
            {/* part:内容 */}
            {recordList.map(renderItem)}
        </div>
    );
};
export default ChartRecord;
