import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Input, InputNumber, message, Modal, Row, Select, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { request } from '../../util';
import config from '../../config';
import log from 'loglevel';

log.setLevel(config.debug ? log.levels.DEBUG : log.levels.ERROR);
const Grade = () => {
  const [severityList, setSeverityList] = useState([]);
  const [pfdList, setPfdList] = useState([]);
  const [riskList, setRiskList] = useState([]);
  const [riskRoleList, setRiskRoleList] = useState([]);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [apply_reason, setApply_reason] = useState();
  const riskColors = ['#a4ff00', '#64cc70', '#fee835', '#faa535', '#ff1919', '#5300eb']
  useEffect(() => {
    getAllGradeList();
  }, []);
  const getAllGradeList = () => {
    request.post('/company/getAllGradeList', {}, true).then((res) => {
      setSeverityList(res.severityList);
      setPfdList(res.pfdList);
      setRiskList(res.riskList);
      setRiskRoleList(res.riskRoleList);
    });
  };
  // 严重性处理
  const onSeverityHandle = (action, severity, index = 0) => {
    let nowSeverityList = [...severityList];
    if (action === 'add') {
      nowSeverityList.push({ value: severityList.length + 1 });
      setSeverityList(nowSeverityList);
    }
    else if (action === 'change' && severity) {
      nowSeverityList.splice(index, 1, severity);
      setSeverityList(nowSeverityList);
    }
    else if (action === 'delete') {
      nowSeverityList.splice(index, 1);
      nowSeverityList.map((sev, i) => {
        sev.value = i + 1;
        return sev;
      });
      setSeverityList(nowSeverityList);
    }
    else {
      message.error('操作不合法');
    }
  };
  // 可能性处理
  const onPfdHandle = (action, pfd, index = 0) => {
    let nowPfdList = [...pfdList];
    if (action === 'add') {
      nowPfdList.push({ value: pfdList.length + 1, min_pfd: 0, max_pfd: 1 });
      setPfdList(nowPfdList);
    }
    else if (action === 'change' && pfd) {
      nowPfdList.splice(index, 1, pfd);
      setPfdList(nowPfdList);
    }
    else if (action === 'delete') {
      nowPfdList.splice(index, 1);
      nowPfdList.map((pfd, i) => {
        pfd.value = i + 1;
        return pfd;
      });
      setPfdList(nowPfdList);
    }
    else {
      message.error('操作不合法');
    }
  };
  // 风险等级处理
  const onRiskHandle = (action, risk, index = 1) => {
    let nowRiskList = [...riskList];
    if (action === 'add') {
      nowRiskList.push({ value: riskList.length + 1 });
      setRiskList(nowRiskList);
    }
    else if (action === 'change' && risk) {
      nowRiskList.splice(index, 1, risk);
      setRiskList(nowRiskList);
    }
    else if (action === 'delete') {
      nowRiskList.splice(index, 1);
      nowRiskList.map((risk, i) => {
        risk.value = i + 1;
        return risk;
      });
      setRiskList(nowRiskList);
    }
    else {
      message.error('操作不合法');
    }
  };
  // 风险等级匹配处理
  const onRiskRoleHandle = (severityValue, pfdValue, riskValue) => {
    const resultRiskRule = riskRoleList.find((riskRule) => riskRule.severity_value === severityValue && riskRule.pfd_value === pfdValue);
    if (resultRiskRule) {
      const newRiskRuleList = riskRoleList.map((riskRule) => {
        if (riskRule.severity_value === severityValue && riskRule.pfd_value === pfdValue) {
          riskRule.risk_value = riskValue;
        }
        return riskRule;
      });
      setRiskRoleList(newRiskRuleList);
    }
    else {
      setRiskRoleList([...riskRoleList, { pfd_value: pfdValue, severity_value: severityValue, risk_value: riskValue }]);
    }
  };
  // 保存事件
  const saveAction = () => {
    setShowSubmitModal(false);
    if (!apply_reason) {
      message.error('请输入修改原因');
    }
    else if (severityList.length === 0) {
      message.error('请输入至少一个严重性等级');
    }
    else if (pfdList.length === 0) {
      message.error('请输入至少一个可能性等级');
    }
    else if (riskList.length === 0) {
      message.error('请输入至少一个风险等级');
    }
    else if (severityList.find((se) => !!!se.label)) {
      message.error('严重性表示符不能为空');
    }
    else if (pfdList.find((pfd) => !!!pfd.label || !!!pfd.max_pfd || pfd.min_pfd === undefined)) {
      message.error('可能性表示符和pfd区间值不能为空');
    }
    else if (pfdList[0].min_pfd === undefined || pfdList[0].min_pfd < 0) {
      message.error('最低风险等级最小pfd值不能小于0');
    }
    else if (pfdList[pfdList.length - 1].max_pfd === undefined || (pfdList[pfdList.length - 1].max_pfd || 0) < 1) {
      message.error('最高风险等级的最大pfd值不能小于1');
    }
    else if (riskList.find((risk) => !!!risk.label || !!!risk.color)) {
      message.error('风险等级表示符和颜色值不能为空');
    }
    else {
      // 发起保存
      let hasEmptyRole = false;
      severityList.forEach((se) => {
        pfdList.forEach((pfd) => {
          const riskRole = riskRoleList.find((rule) => rule.severity_value === se.value && rule.pfd_value === pfd.value);
          if (riskRole === undefined || !!!riskRole.risk_value)
            hasEmptyRole = true;
        });
      });
      if (hasEmptyRole) {
        message.error('风险等级匹配规则不能有未设置的项');
      }
      else {
        log.debug('开始设置');
        const askContent = {
          severityList,
          pfdList,
          riskList,
          riskRoleList,
        };
        request.post('/company/askForSetting', { askContent, apply_reason, type: 1 }, true).then((res) => {
          message.success('已经提交申请,等待审核中...');
        });
      }
    }
  };
  // 加载行
  const loadSeriousRow = () => {
    const rows = [];
    for (let si = severityList.length - 1; si >= 0; si--) {
      const serious = severityList[si];
      rows.push(
        <div className="row" key={si}>
          <span className="item">{serious.label}</span>
          {pfdList.map((pfd, pi) => {
            const severityValue = si + 1;
            const pfdValue = pi + 1;

            let resultRisk;
            const resultRiskRule = riskRoleList.find(
              (riskRule) => riskRule.severity_value === severityValue && riskRule.pfd_value === pfdValue,
            );
            if (resultRiskRule) {
              resultRisk = riskList.find((risk) => risk.value === resultRiskRule.risk_value);
            }
            return (
              <div className="item" key={pi} style={{ backgroundColor: resultRisk?.color || '#fff' }}>
                <Select
                  className="select"
                  bordered={false}
                  placeholder="风险等级"
                  value={resultRisk?.value}
                  onChange={(riskValue) => onRiskRoleHandle(severityValue, pfdValue, riskValue)}>
                  {riskList.map((risk, ri) => (
                    <Select.Option style={{ backgroundColor: risk.color }} key={ri} value={risk.value}>
                      {risk.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            );
          })}
        </div>,
      );
    }
    return rows;
  };
  return (
    <>
      {/* 严重性 */}
      <div className="setting-item serious">
        <div className="setting-title">设置严重性等级</div>
        {severityList.map((severity, si) => (
          <Row className="mt-base" align={'middle'} justify={'center'} key={si}>
            <Col span={6}>{`等级: ${si + 1}`}</Col>
            <Col span={4}>表示符:</Col>
            <Col span={10}>
              <Input
                className="serious-input"
                value={severity.label}
                placeholder="例如:C3"
                onChange={(e) => onSeverityHandle('change', { ...severity, label: e.target.value }, si)}
              />
            </Col>
            <Col span={4}>
              <MinusCircleOutlined className="delete-btn" onClick={() => onSeverityHandle('delete', severity, si)} />
            </Col>
          </Row>
        ))}
        <Button block size={'large'} className="add-btn-dashed mt-lg" type="dashed" onClick={() => onSeverityHandle('add')}>
          <PlusOutlined /> 添加严重性等级
        </Button>
      </div>

      {/* 可能性 */}
      <div className="border-top setting-item pfd">
        <div className="setting-title">设置可能性等级</div>
        {pfdList.map((pfdGrade, pi) => (
          <div className="pfd-item" key={pi}>
            <span className="pfd-label">{`等级: ${pi + 1}`}</span>
            <span className="pfd-label">表示符:</span>
            <Input
              className="pfd-label-input"
              value={pfdGrade.label}
              placeholder="例如:F3"
              onChange={(e) => onPfdHandle('change', { ...pfdGrade, label: e.target.value }, pi)}
            />
            <span className="pfd-label">最小值:</span>
            <InputNumber
              min={0}
              max={1}
              step={0.01}
              value={pfdGrade.min_pfd}
              onChange={(v) => onPfdHandle('change', { ...pfdGrade, min_pfd: v }, pi)}
            />

            <span className="pfd-label">最大值:</span>
            <InputNumber
              min={0}
              max={1}
              step={0.01}
              value={pfdGrade.max_pfd}
              onChange={(v) => onPfdHandle('change', { ...pfdGrade, max_pfd: v }, pi)}
            />

            <MinusCircleOutlined className="delete-btn" onClick={() => onPfdHandle('delete', pfdGrade, pi)} />
          </div>
        ))}
        <Button block size={'large'} className="add-btn-dashed mt-lg" type="dashed" onClick={() => onPfdHandle('add')}>
          <PlusOutlined /> 添加可能性等级
        </Button>
      </div>

      {/* 风险等级 */}
      <div className="border-top setting-item risk">
        <div className="setting-title">设置风险等级</div>
        {riskList.map((risk, ri) => (
          <div className="risk-item" key={ri}>
            <span className="risk-label">{`等级: ${ri + 1}`}</span>
            <span className="risk-value-label">表示符:</span>
            <Input
              className="risk-input"
              value={risk.label}
              placeholder="例如:V3"
              onChange={(e) => onRiskHandle('change', { ...risk, label: e.target.value }, ri)}
            />
            <span className="risk-value-label">RGB颜色:</span>
            {/* <Input */}
            {/*   className="risk-input" */}
            {/*   style={{ background: risk.color }} */}
            {/*   value={risk.color} */}
            {/*   placeholder="例如:#ffff00" */}
            {/*   onChange={(e) => onRiskHandle('change', { ...risk, color: e.target.value }, ri)} */}
            {/* /> */}
            <Select
              className="select risk-input"
              style={{ backgroundColor: risk.color }}
              bordered={false}
              placeholder="风险颜色"
              value={risk?.color}
              onChange={(color) => onRiskHandle('change', { ...risk, color }, ri)}>
              {riskColors.map((color, ri) => (
                <Select.Option style={{ backgroundColor: color }} key={ri} value={color}>
                </Select.Option>
              ))}
            </Select>
            <MinusCircleOutlined className="delete-btn" onClick={() => onRiskHandle('delete', risk, ri)} />
          </div>
        ))}
        <Button block size={'large'} className="add-btn-dashed mt-lg" type="dashed" onClick={() => onRiskHandle('add')}>
          <PlusOutlined /> 添加风险性等级
        </Button>
      </div>

      <div className="border-top mb-lg risk-lists">
        <div className="block-title">风险等级匹配规则 </div>
        <div className="risk-table">
          {/* 严重性 行 */}
          {loadSeriousRow()}
          {/* 可能性(文字) 行 */}
          <div className="row">
            <span className="item" />

            {pfdList.map((pfd, pi) => (
              <span className="item" key={pi}>
                {pfd.label}
              </span>
            ))}
          </div>
        </div>
      </div>

      <Row justify={'center'} align={'middle'} className="mt-lg">
        <Space size={'middle'}>
          {/* <Button size={'middle'} onClick={() => navigate(-1)} shape="default" className="com-w-btn">
            返回
          </Button> */}
          <Button size="middle" shape="default" className="com-btn com-btn-block green" onClick={() => setShowSubmitModal(true)}>
            提交申请
          </Button>
        </Space>
      </Row>

      <Modal
        title="修改原因"
        okButtonProps={{ shape: 'round' }}
        cancelButtonProps={{ shape: 'round' }}
        visible={showSubmitModal}
        onOk={saveAction}
        onCancel={() => setShowSubmitModal(false)}>
        <Col span={24}>
          <Input placeholder="请输入修改原因" value={apply_reason} onChange={(e) => setApply_reason(e.target.value)} />
        </Col>
      </Modal>
    </>
  );
};
export default Grade;
