import { Button, Col, Input, InputNumber, message, Radio, Row, Select, Space } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { request } from '../../util';
// page:参数设置
const ConfigPage = (props) => {
    useEffect(() => {
        getConfigList();
    }, []);
    const [configList, setConfigList] = useState([]);
    const getConfigList = () => {
        request.post('/company/queryConfigList', {}, true).then((res) => {
            setConfigList(res.configList);
        });
    };
    /**
     *  处理参数改变
     */
    const handleconfigValueChange = (config, index) => {
        const nowConfigList = [...configList];
        nowConfigList.splice(index, 1, config);
        setConfigList(nowConfigList);
    };
    const saveAction = () => {
        request.post('/company/updateConfigData', { configList }, true).then((res) => {
            message.success('更新成功');
        });
    };
    const loadUnitStr = (config) => {
        switch (config.keyword) {
            case 'refreshInterval':
                return '(单位毫秒)';
            default:
                break;
        }
    };
    const loadInput = (config, index) => {
        if (config.keyword === 'refreshInterval' || config.keyword === 'homeStatisticsTime') {
            return <InputNumber style={{ width: '150px' }} value={parseInt(config.value)} placeholder="请输入参数值" onChange={(v) => handleconfigValueChange({ ...config, value: v + '' }, index)} />;
        }
        else if (config.keyword === 'riskAlertValue') {
            return (
                <Select placeholder='请选择风险等级' style={{ width: '150px' }} defaultValue={parseInt(config.value)} onChange={value => handleconfigValueChange({ ...config, value: value + '' }, index)}>
                    {
                        props.publicData?.optionList.riskList?.map(risk => (
                            <Select.Option value={risk.value}>{risk.label}</Select.Option>
                        ))
                    }
                </Select>
            ) }
        else if (config.keyword === 'TagPrefix') {
            return <Input style={{ width: '150px' }} value={config.value} onChange={e => handleconfigValueChange({ ...config, value: e.target.value }, index)} />
        }
        else if (['send-high-risk-sms', 'send-barrier-sms', 'send-start-event-sms', 'send-design-sms', 'update-pfd', 'kepserver-offline-sms'].includes(config.keyword)) {
            return (
                <Radio.Group value={config.value} onChange={e => handleconfigValueChange({ ...config, value: e.target.value }, index)}>
                    <Radio value={'1'} >是</Radio>
                    <Radio value={'0'}>否</Radio>
                </Radio.Group>
            )
        }
    };
    return (
        <div className="setting-item">
            {configList.map((param, index) => (
                <Row className="mt-lg parameter-row" align={'middle'} justify={'space-between'}>
                    <Col>
                        参数名称：
                        <span className="font-weight-bold">
              {param.label}
                            {loadUnitStr(param)}
            </span>
                    </Col>
                    <Col>{loadInput(param, index)}</Col>
                </Row>
            ))}

            <Row justify={'center'} align={'middle'} className="mt-lg">
                <Space size={'middle'}>
                    <Button size="middle" shape="default" className="com-btn com-btn-block green" onClick={saveAction}>
                        提交
                    </Button>
                </Space>
            </Row>
        </div>
    );
};
export default inject('publicData')(observer(ConfigPage));
