import { Button, Form, Input, Modal, Row, Select, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { inject, observer } from 'mobx-react';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import config from '../../../config';
import log from 'loglevel';
log.setLevel(config.debug ? log.levels.DEBUG : log.levels.ERROR);
const ResultModal = (props, ref) => {
    const [streamForm] = useForm();
    const [showStreamModal, setShowStreamModal] = useState(false);
    const [itemIndex, setItemIndex] = useState(0);
    const [type, setType] = useState('add');
    const [editData, setEditData] = useState();
    // 点击完成
    const onFinish = (data) => {
        if (type === 'add') {
            data.barrierList = [];
            props.editNode.addResult(props.streamIndex, itemIndex, data);
        }
        else {
            // 保护层要缓存下来
            props.editNode.updateResult(props.streamIndex, itemIndex, Object.assign(Object.assign({}, editData === null || editData === void 0 ? void 0 : editData.result), data));
        }
        streamForm.resetFields();
        setShowStreamModal(false);
    };
    // 点击取消
    const onReset = () => {
        streamForm.resetFields();
        setShowStreamModal(false);
    };
    // 点击删除
    const onDelete = () => {
        streamForm.resetFields();
        setShowStreamModal(false);
        props.editNode.deleteResult(props.streamIndex, itemIndex);
    };
    // 复制操作
    const copyItem = () => {
        streamForm.resetFields();
        setShowStreamModal(false);
        props.editNode.copyResult(props.streamIndex, itemIndex);
    };
    useImperativeHandle(ref, () => ({
        open: (index, data) => {
            log.debug('后果弹窗', data, index);
            setShowStreamModal(true);
            setItemIndex(index);
            if (data) {
                setType('edit');
                setEditData(data);
                streamForm.setFieldsValue(data.result);
            }
            else {
                setType('add');
            }
        },
        close: () => {
            setShowStreamModal(false);
        },
    }));
    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };
    return (
        <Modal title="添加后果" visible={showStreamModal} closable={false} footer={null}>
            <Form {...layout} form={streamForm} onFinish={onFinish} onReset={onReset}>
                <Form.Item label="后果名称：" name="info" rules={[{required: true, message: '请输入后果名称'}]}>
                    <Input placeholder="请输入后果名称" maxLength={200} />
                </Form.Item>
                <Form.Item label="严重等级：" name="severity_id" rules={[{required: true, message: '请选择严重等级'}]}>
                    <Select placeholder="请选择严重等级">
                        {props.publicData?.optionList.severityList?.map((v) => (
                            <Select.Option value={v.severity_id || ''} key={v.severity_id}>
                                {v.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Row justify="end">
                        <Space size="middle">
                            {type === 'edit' && (
                                <Button type="link" htmlType="button" onClick={copyItem}>
                                    复制
                                </Button>
                            )}
                            {type === 'edit' && (
                                <Button type="link" danger htmlType="button" onClick={onDelete}>
                                    删除
                                </Button>
                            )}
                            <Button type="default" htmlType="reset">
                                取消
                            </Button>
                            <Button type="primary" htmlType="submit">
                                确定
                            </Button>
                        </Space>
                    </Row>
                </Form.Item>
            </Form>
        </Modal>
    );
};
export default inject('editNode', 'publicData')(observer(forwardRef(ResultModal)));
