import {Breadcrumb, Button, Tabs} from 'antd';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Config from './config';
import Grade from './grade';
import './index.scss';
import Option from './option';
import Parameter from './parameter';
import HearBeat from './heartbeat';
import TriggerSetting from './trigger';
import TelegrafSetting from './telegraf';
const SettingIndex = () => {
    const [type, setType] = useState('Grade');
    const navigate = useNavigate();
    return (
        <div className="setting-wrapper">
            {/* part:导航栏 */}
            <Breadcrumb>
                <Breadcrumb.Item>平台管理</Breadcrumb.Item>
                <Breadcrumb.Item>设置</Breadcrumb.Item>
            </Breadcrumb>
            {/* part:内容 */}
            <div className="setting-tabs">
                <div className="right-btn-group">
                    <Button shape="default" type="primary" className="com-btn ml-10" onClick={() => navigate('/home/my-apply')}>
                        我的申请
                    </Button>
                    <Button shape="default" type="primary" className="com-btn ml-10" onClick={() => navigate('/home/apply-lists')}>
                        申请列表
                    </Button>
                    <Button
                        shape="default"
                        type="primary"
                        className="com-btn com-btn-block yellow ml-10"
                        onClick={() => navigate('/home/setting-record')}
                    >
                        设置记录
                    </Button>
                </div>
                <Tabs className="com-white-block pt-10 " activeKey={type} onTabClick={(key) => setType(key)}>
                    <Tabs.TabPane tab="矩阵设置" key="Grade">
                        <Grade />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="参数设置" key="Parameter">
                        <Parameter />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="选项设置" key="Option">
                        <Option />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="推送设置" key="config">
                        <Config />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="数据链路设置" key="data-link">
                        <HearBeat />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="失效和触发逻辑" key="trigger">
                        <TriggerSetting />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Telegraf配置文件" key="telegraf">
                        <TelegrafSetting />
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </div>
    );
};
export default SettingIndex;
