// noinspection JSUnresolvedVariable

import {action, observable} from 'mobx';
import request from './request';
import config from '../config';
import log from 'loglevel';

log.setLevel(config.debug ? log.levels.DEBUG : log.levels.ERROR);
const optionList = observable({
  severityList: [],
  pfdList: [],
  riskList: [],
  topEventTypeList: [],
  startEventTypeList: [],
  deviationTypeList: [],
  barrierWorkTypeList: [],
  departmentList: [],
  parameterList: [],
  processList: [],
  riskRoleList: [],
  configData: [],
});
const getAllOptionList = action(function () {
  if (!config.token || config.token === '') {
    return;
  }
  Promise.all([
    request.post('/company/getAllOptionList', {}, false),
    request.post('/company/getAllGradeList', {}, false),
    request.post('/company/queryDepartments', {}, false),
    request.post('/company/queryAllTagList', {}, false),
    request.post('/company/queryConfigList', {}, false),
  ]).then((res) => {
    optionList.topEventTypeList = res[0].topEventTypeList;
    optionList.startEventTypeList = res[0].startEventTypeList;
    optionList.deviationTypeList = res[0].deviationTypeList;
    optionList.barrierWorkTypeList = res[0].barrierWorkTypeList;
    optionList.severityList = res[1].severityList;
    optionList.pfdList = res[1].pfdList;
    optionList.riskList = res[1].riskList;
    optionList.riskRoleList = res[1].riskRoleList;
    optionList.departmentList = res[2].departmentList;
    optionList.parameterList = res[3].parameterList;
    optionList.processList = res[3].processList;
    optionList.configData = res[4].configList;
  });
});
const getSeverity = (id) => {
  return optionList.severityList?.find((v) => v.severity_id === id);
};
const getPfd = (id) => {
  const pfdList = optionList.pfdList;
  return pfdList?.find((v) => v.pfd_id === id);
};
const getRisk = (id) => {
  const riskList = optionList.riskList;
  return riskList?.find((v) => v.risk_id === id);
};
const getRiskForValue = (value) => {
  const riskList = optionList.riskList;
  return riskList?.find((v) => v.value === value);
};
const getRiskRole = (id) => {
  const riskRoleList = optionList.riskRoleList;
  return riskRoleList?.find((v) => v.risk_role_id === id);
};
const getTopEventType = (id) => {
  const {topEventTypeList} = optionList;
  return topEventTypeList?.find((v) => v.top_event_type_id === id);
};
const getStartEventType = (id) => {
  const {startEventTypeList} = optionList;
  return startEventTypeList?.find((v) => v.start_event_type_id === id);
};
const getDeviationType = (id) => {
  const {deviationTypeList} = optionList;
  return deviationTypeList?.find((v) => v.deviation_type_id === id);
};
const getBarrierWorkType = (id) => {
  const {barrierWorkTypeList} = optionList;
  return barrierWorkTypeList?.find((v) => v.barrier_work_type_id === id);
};
const getDepartment = (department_id) => {
  const departmentList = optionList.departmentList;
  return departmentList?.find((depart) => depart.department_id === department_id);
};
const getRiskAlertValue = () => {
  const configData = optionList.configData;
  const riskAlertValue = configData?.find((c) => c.keyword === 'riskAlertValue')?.value || '0';
  return parseInt(riskAlertValue);
};
/**
 *  根据pfd值,得到pfd等级对象
 */
const exchangePfdGrade = (pfd) => {
  if (pfd < 0) pfd = 0;
  if (pfd > 1) pfd = 1;
  return optionList?.pfdList?.find((grade) => pfd >= (grade.min_pfd ?? 0) && pfd <= (grade.max_pfd ?? 1));
};
/**
 *  根据后果值和可能性值得到风险等级
 * severityValue: 风险等级
 * pfdValue :可能性等级
 */
const exchangeRiskGrade = (severityValue, pfdValue) => {
  const {riskRoleList, riskList} = optionList;
  const riskRole = riskRoleList?.find((role) => role.severity_value === severityValue && role.pfd_value === pfdValue);
  if (riskRole) {
    return riskList?.find((risk) => risk.value === riskRole.risk_value);
  }
};
getAllOptionList();
export default {
  optionList,
  getAllOptionList,
  getSeverity,
  getPfd,
  exchangeRiskGrade,
  getBarrierWorkType,
  getDeviationType,
  getStartEventType,
  getTopEventType,
  getRisk,
  getRiskForValue,
  getRiskRole,
  getDepartment,
  exchangePfdGrade,
  getRiskAlertValue,
};
