// noinspection JSUnusedLocalSymbols

import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {Button, Form, Input} from 'antd';
import React, {useCallback, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import config from '../../config';
import {request, stringUtil} from '../../util';
import './login.scss';

const Login = (props) => {
  const navigate = useNavigate();
  // const [visible, setVisible] = useState(false);
  /**
   *  跳转首页
   */
  const navToHome = useCallback(() => {
    navigate('/dashboard');
  }, [navigate]);
  config.token = window.localStorage.getItem('company.token');
  useEffect(() => {
    if (config.token) {
      request.post('/company/queryMineInfo', {}, false).then((res) => {
        config.userInfo = res;
        navToHome();
      });
      request.post('/company/queryMyCompanyInfo', {}, false).then((res) => {
        config.companyInfo = res;
      });
    }
  }, [navToHome]);
  const onFinish = (param) => {
    const enPassword = stringUtil.encryption(param.password);
    request.post('/company/login', {account: param.account, password: enPassword}, true).then((res) => {
      config.token = res.token;
      config.userInfo = res.user;
      window.localStorage.setItem('company.token', res.token);
      navToHome();
    });
  };
  // const changePWD = (param) => {
  //     const enPassword = stringUtil.encryption(param.password);
  //     const enNewPassword = stringUtil.encryption(param.newPassword);
  //     request.post('/company/changePassword', {
  //         account: param.account,
  //         password: enPassword,
  //         newPassword: enNewPassword
  //     }, true).then((res) => {
  //         config.token = res.token;
  //         config.userInfo = res.user;
  //         window.localStorage.setItem('company.token', res.token);
  //         navToHome();
  //     });
  // };
  return (
    <div className="login-container">
      <div style={{width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <span style={{color: 'white', fontSize: 26, fontWeight: 'bold'}}>“危可视”重大危险源/危险工艺风险在线监测及动态预警平台</span>
        <div style={{marginTop: 40}}>
          <Form name="normal_login" className="login-form" size={'large'} initialValues={{remember: true}} onFinish={onFinish}>
            <Form.Item name="account" rules={[{required: true, message: '请输入正确的手机号码'}]}>
              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="请输入手机号码" />
            </Form.Item>
            <Form.Item name="password" rules={[{required: true, message: '请输入登录密码'}]}>
              <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="请输入登录密码" />
            </Form.Item>
            <Form.Item>
              <Button block type="primary" htmlType="submit" style={{backgroundColor: '#0A3D73', borderColor: '#0A3D73'}}>
                立即登录
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff', width: '100%', height: 60}}>
        <img src="/img/logo1.png" alt="logo" style={{width: 100, height: 36}} />
        <span style={{paddingLeft: 20, fontSize: 20, fontWeight: 'bold'}}>看不见风险，就是最大的风险</span>
      </div>
    </div>
  );
};
export default Login;
// const LoginModal = ({visible, onFinish, onCancel}) => {
//   // const [form] = Form.useForm();
//   return (
//     <Modal visible={visible} size="small" maskClosable={true} closable={true} onCancel={onCancel} footer={null} width={280} centered={true}>
//       <div className="login-wrapper">
//         <Image className="logo" width={70} height={70} src={'/img/logo256.png'} preview={false} />
//         <Form name="normal_login" className="login-form" size={'large'} initialValues={{remember: true}} onFinish={onFinish}>
//           <Form.Item name="account" rules={[{required: true, message: '请输入正确的手机号'}]}>
//             <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="请输入手机号" />
//           </Form.Item>

//           <Form.Item name="password" rules={[{required: true, message: '请输入密码'}]}>
//             <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="密码" />
//           </Form.Item>
//           <Form.Item>
//             <Button block type="primary" htmlType="submit" className="login-form-button">
//               立即登录
//             </Button>
//           </Form.Item>
//         </Form>
//       </div>
//     </Modal>
//   );
// };
