// noinspection JSUnresolvedVariable

import {Button, DatePicker, Form, Select, Space, Table} from 'antd';
import dayjs from 'dayjs';
import React, {useEffect, useRef, useState} from 'react';
import {request} from '../../util';
import './index.scss';
import config from '../../config';
import log from 'loglevel';

log.setLevel(config.debug ? log.levels.DEBUG : log.levels.ERROR);
const timeUnitStr = ['无', '日', '月', '季度', '年'];
const {RangePicker} = DatePicker;
// component:缴费记录
const RecordPage = () => {
    const tableColumns = [
        {
            title: '序号',
            align: 'center',
            render: (text, order, index) => `${index + 1}`,
        },
        {
            title: '企业名称',
            dataIndex: 'company_name',
            key: 'company_name',
            align: 'center',
        },
        {
            title: '销售方案',
            dataIndex: 'edition_name',
            key: 'edition_name',
            align: 'center',
        },
        {
            title: '缴费时间',
            dataIndex: 'create_time',
            key: 'create_time',
            align: 'center',
            render: (text, order) => dayjs(order.create_time).format('YYYY-MM-DD HH:mm:ss'),
        },
        {
            title: '节点数量',
            dataIndex: 'max_nodes',
            key: 'max_nodes',
            align: 'center',
        },
        {
            title: '人员数量',
            dataIndex: 'max_users',
            key: 'max_users',
            align: 'center',
        },
        {
            title: '延长期限',
            key: 'delayDate',
            align: 'center',
            render: (text, order) => `${order.time_count} - ${timeUnitStr[order.time_unit]}`,
        },
        {
            title: '金额',
            key: 'price',
            align: 'center',
            render: (text, order) => `${order.pay_num * order.price}`,
        },
        {
            title: '状态',
            key: 'status',
            align: 'center',
            render: (text, order) => (order.order_state === 1 ? '未支付' : '已支付'),
        },
    ];
    const searchFormInstance = useRef(null);
    const [recordList, setRecordList] = useState([]);
    useEffect(() => {
        querryEditionList();
        queryOrderRecordList({});
    }, []);
    const [editionList, setEditionList] = useState([]);
    const querryEditionList = () => {
        request.post('/public/queryEditions', {}, false).then((res) => {
            setEditionList(res.editionList);
        });
    };
    const queryOrderRecordList = (param) => {
        request.post('/company/queryMyOrderRecords', param, true).then((res) => {
            setRecordList(res.recordList);
        });
    };
    /**
     *  查询缴费记录
     */
    const searchAction = (param) => {
        log.debug(param);
        if (param.order_create) {
            param.order_create_start = param.order_create[0].format('YYYY-MM-DD HH:mm:ss');
            param.order_create_end = param.order_create[1].format('YYYY-MM-DD HH:mm:ss');
        }
        if (param.edition_id === 0)
            param.edition_id = undefined;
        queryOrderRecordList(param);
    };
    /**
     *  重置查询条件
     */
    const resetAction = () => {
        let _a;
        (_a = searchFormInstance.current) === null || _a === void 0 ? void 0 : _a.resetFields();
        queryOrderRecordList({});
    };
    // noinspection JSValidateTypes
    return (
        <div>
            {/* part:筛选条件 */}
            <Form
                className="com-filter-form-wrapper pt-0 record-form"
                colon={true}
                layout={'inline'}
                ref={searchFormInstance}
                onFinish={searchAction}>
                <Form.Item name="edition_id" label="开通版本">
                    <Select placeholder="请选择销售方案" style={{width: '200px'}}>
                        <Select.Option value="0">全部</Select.Option>
                        {editionList.map((edition) => (
                            <Select.Option value={edition.edition_id || '0'} key={edition.edition_id}>
                                {edition.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="缴费时间" name="order_create">
                    <RangePicker/>
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button shape="default" type="primary" className="com-btn" htmlType="submit">
                            查询
                        </Button>
                        <Button shape="default" className="com-btn" onClick={resetAction}>
                            重置
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
            <Table
                className="mt-lg"
                pagination={{position: ['bottomCenter'], defaultPageSize: 10}}
                bordered
                columns={tableColumns}
                dataSource={recordList}></Table>
        </div>
    );
};
export default RecordPage;
