/* eslint-disable react-hooks/exhaustive-deps */
// noinspection JSUnresolvedVariable,JSUnresolvedFunction,JSValidateTypes,NpmUsedModulesInstalled

import {Button, DatePicker, Select, Table, Modal, Form, Row, Col, Input, Radio, message} from 'antd';
import React, {useEffect, useState} from 'react';
import dayjs from 'dayjs';
import {request} from '../../util';
import {inject, observer} from 'mobx-react';
import {formatAlertEvent} from '../../util/publicFunc';
import './nodeAlert.scss';
import config from '../../config';
import log from 'loglevel';
log.setLevel(config.debug ? log.levels.DEBUG : log.levels.ERROR);
// const moment = require('moment');

const AlertSolution = (props) => {
  const [nodeList, setNodeList] = useState([]);
  const [selectNode, setSelectNode] = useState('');
  const [selectRiskValue, setSelectRiskValue] = useState('');
  const [selectDepartment, setSelectDepartment] = useState('');
  const [alertList, setAlertList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNum, setPageNum] = useState(0);
  const [timeRange, setTimeRange] = useState([dayjs().subtract(1, 'months'), dayjs()]);
  const [workers, setWorkers] = useState([]);
  const [isModalVisible0, setIsModalVisible0] = useState(false);
  const [form0] = Form.useForm();
  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const [form1] = Form.useForm();
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [form2] = Form.useForm();
  const [alert, setAlert] = useState();
  const loadNodeList = () => {
    request.post('/company/queryNodeList', {}, true).then((res) => {
      setNodeList(res.nodeList);
    });
  };
  const loadAlertBarrierList = (pageNum, pageSize) => {
    const param = {
      owner_node_id: selectNode,
      department_id: selectDepartment,
      risk_value: selectRiskValue,
      startTime: dayjs(timeRange[0]).format('YYYY-MM-DD 00:00:00'),
      endTime: dayjs(timeRange[1]).format('YYYY-MM-DD 23:59:59'),
      pageNum,
      pageSize,
    };
    request.post('/company/getAlertNode', param, true).then((res) => {
      const alertList = res.alertList.map(formatAlertEvent);
      alertList.map((alert) => (alert.key = alert.alert_event_id));
      setAlertList(alertList);
      setTotalCount(res.count);
      setPageNum(res.pageNum);
    });
  };
  useEffect(() => {
    loadNodeList();
  }, []);
  useEffect(() => {
    loadAlertBarrierList(0, 10);
  }, [selectNode, selectDepartment, selectRiskValue, timeRange]);
  const queryWorkers = () => {
    request.post('/company/queryWorkers', {}, true).then((res) => {
      setWorkers(res.users);
    });
  };
  useEffect(() => {
    queryWorkers();
  }, []);
  const solution_states = ['未处理', '处理中', '待审核', '已驳回', '已关闭'];
  const now = dayjs().format('YYYY-MM-DD HH:mm:ss');
  const getSolutionState = (alert) => {
    if (alert.solution_state !== 4 && alert.solution_date && alert.sotion_date < now) return '逾期';
    return solution_states[alert.solution_state];
  };
  const assignWorker = (alert_event_id, solution_leader_id, solution_worker_id, solution_date) => {
    // console.log(alert_event_id, solution_leader_id, solution_worker_id, solution_date);
    request.post('/company/assignWorker', {alert_event_id, solution_leader_id, solution_worker_id, solution_date}, true).then((res) => {
      console.log('assignWorker: ', res);
    });
  };
  const submitSolution = (alert_event_id, solution) => {
    console.log(alert_event_id, solution);
    request.post('/company/submitSolution', {alert_event_id, solution}, true).then((res) => {});
  };
  const auditSolution = (alert_event_id, solution_state) => {
    request.post('/company/auditSolution', {alert_event_id, solution_state}, true).then((res) => {});
  };
  const onOpenModal0 = (alert) => {
    setAlert(alert);
    if (!config.userInfo || config.userInfo.user_id !== alert.leader_id) {
      message.error('您不是该事件的负责人，无法确认');
    } else {
      setIsModalVisible0(true);
    }
  };
  const onOpenModal1 = (alert) => {
    setAlert(alert);
    if (!config.userInfo || config.userInfo.user_id !== alert.solution_worker_id) {
      message.error('您不是该事件的行动人，无法确认');
    } else {
      setIsModalVisible1(true);
    }
  };
  const onOpenModal2 = (alert) => {
    setAlert(alert);
    if (!config.userInfo || config.userInfo.user_id !== alert.leader_id) {
      message.error('您不是该事件的负责人，无法确认');
    } else {
      setIsModalVisible2(true);
    }
  };
  const disabledDate = (current) => {
    const after = current && current <= dayjs().startOf('day');
    return after;
  };
  const columns = [
    {
      title: '序号',
      align: 'center',
      key: 'alert_event_id',
      render: (text, alert, index) => `${index + 1 + pageNum * 10}`,
      width: 70,
    },
    {
      title: '节点',
      align: 'center',
      key: 'owner_node_info',
      dataIndex: 'owner_node_info',
      width: 150,
    },
    {
      title: '风险等级',
      align: 'center',
      key: 'risk_value',
      dataIndex: 'risk_value',
      width: 100,
    },
    {
      title: '开始时间',
      align: 'center',
      key: 'start_time',
      dataIndex: 'start_time',
      width: 200,
    },
    {
      title: '结束时间',
      align: 'center',
      key: 'end_time',
      dataIndex: 'end_time',
      width: 200,
    },
    {
      title: '持续时间（分钟）',
      align: 'center',
      key: 'useTime',
      dataIndex: 'useTime',
      width: 150,
    },
    {
      title: '描述',
      align: 'center',
      key: 'describe_info',
      dataIndex: 'describe_info',
      width: 250,
    },
    {
      title: '事件流',
      align: 'center',
      key: 'owner_stream_info',
      dataIndex: 'owner_stream_info',
      width: 150,
    },
    {
      title: '起始事件',
      align: 'center',
      key: 'owner_start_event_info',
      dataIndex: 'owner_start_event_info',
      width: 150,
    },
    {
      title: '相关事件',
      align: 'center',
      key: 'cause_info',
      dataIndex: 'cause_info',
      width: 150,
    },
    {
      title: '后果',
      align: 'center',
      key: 'owner_result_info',
      dataIndex: 'owner_result_info',
      width: 150,
    },
    {
      title: '所属部门',
      align: 'center',
      key: 'department_name',
      dataIndex: 'department_name',
      width: 150,
    },
    {
      title: '短信接收人',
      align: 'center',
      key: 'leader_name',
      dataIndex: 'leader_name',
      width: 150,
    },
    {
      title: '短信接收号码',
      align: 'center',
      key: 'leader_phone',
      dataIndex: 'leader_phone',
      width: 150,
    },
    {
      title: '发送状态',
      align: 'center',
      key: 'sms_state',
      dataIndex: 'sms_state',
      width: 100,
    },
    {
      title: '短信内容',
      align: 'center',
      key: 'message_content',
      dataIndex: 'message_content',
      width: 250,
    },
    {
      title: '处理状态',
      key: 'solution_state',
      align: 'center',
      fixed: 'right',
      width: 100,
      render: (_, alert) => {
        return getSolutionState(alert);
      },
    },
    {
      title: '操作',
      key: 'action',
      align: 'center',
      fixed: 'right',
      width: 100,
      render: (_, alert) => {
        if (alert.solution_state !== 4 && alert.solution_date && alert.sotion_date < now) {
          return (
            <Button type="link" onClick={() => onOpenModal2(alert)}>
              确认
            </Button>
          );
        } else if (alert.solution_state === 0) {
          return (
            <Button type="link" onClick={() => onOpenModal0(alert)}>
              确认
            </Button>
          );
        } else if (alert.solution_state === 1 || alert.solution_state === 3) {
          return (
            <Button type="link" onClick={() => onOpenModal1(alert)}>
              确认
            </Button>
          );
        } else if (alert.solution_state === 2) {
          return (
            <Button type="link" onClick={() => onOpenModal2(alert)}>
              确认
            </Button>
          );
        } else {
          return (
            <Button disabled type="link" onClick={() => onOpenModal2(alert)}>
              确认
            </Button>
          );
        }
      },
    },
  ];
  const pageChange = (page, pageSize = 10) => {
    loadAlertBarrierList(page - 1, pageSize);
  };
  return (
    <div className="barrier-alert">
      <div className="search-view">
        <div className="filter">
          <div className="label">节点：</div>
          <Select className="select" defaultValue={selectNode} style={{width: 150}} onChange={(v) => setSelectNode(v)}>
            <Select.Option value={''} key={'all'}>
              全部
            </Select.Option>
            {nodeList.map((node) => (
              <Select.Option value={node.node_id || ''} key={node.node_id}>
                {node.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="filter">
          <div className="label">风险等级：</div>
          <Select className="select" style={{width: 150}} defaultValue={selectRiskValue} onChange={(v) => setSelectRiskValue(v)}>
            <Select.Option value={''}>全部</Select.Option>
            {props.publicData?.optionList.riskList
              ?.filter((risk) => risk.value >= props.publicData?.getRiskAlertValue())
              .map((risk) => (
                <Select.Option value={`${risk.value}`} key={`${risk.value}`}>{`等级${risk.value}`}</Select.Option>
              ))}
          </Select>
        </div>
        <div className="filter">
          <div className="label">部门：</div>
          <Select className="select" style={{width: 150}} defaultValue={selectDepartment} onChange={(v) => setSelectDepartment(v)}>
            <Select.Option value={''} key={'all'}>
              全部
            </Select.Option>
            {props.publicData?.optionList.departmentList?.map((dep) => (
              <Select.Option value={dep.department_id} key={dep.department_id}>
                {dep.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="filter">
          <div className="label">发生时间：</div>
          <DatePicker.RangePicker
            className="time-picker"
            clearIcon={false}
            value={timeRange}
            onChange={(v) => setTimeRange(v)}></DatePicker.RangePicker>
        </div>
      </div>
      <div style={{marginTop: 20}}>
        <Table
          pagination={{position: ['bottomRight'], total: totalCount, onChange: pageChange}}
          dataSource={alertList}
          columns={columns}
          scroll={{x: 2500}}
        />
      </div>
      <Modal
        title="事件处理"
        width={'30%'}
        maskClosable={false}
        okButtonProps={{shape: 'round'}}
        cancelButtonProps={{shape: 'round'}}
        visible={isModalVisible0}
        onOk={() => {
          form0
            .validateFields()
            .then((values) => {
              const index = alertList.findIndex((v) => v.alert_event_id === alert.alert_event_id);
              const newAlertList = [...alertList];
              newAlertList[index] = {
                ...newAlertList[index],
                solution_state: 1,
                solution_leader_id: values.solution_leader_id,
                solution_worker_id: values.solution_worker_id,
                solution_date: dayjs(values.solution_date).format('YYYY-MM-DD 23:59:59'),
              };
              setAlertList(newAlertList);
              setIsModalVisible0(false);
              form0.resetFields();
              const alert_event_id = alert.alert_event_id;
              const solution_leader_id = config.userInfo.user_id;
              const solution_worker_id = values.solution_worker_id;
              const solution_date = dayjs(values.solution_date).format('YYYY-MM-DD 23:59:59');
              assignWorker(alert_event_id, solution_leader_id, solution_worker_id, solution_date);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
        onCancel={() => setIsModalVisible0(false)}>
        <Form className="flex-start" colon={true} labelCol={{span: 5}} form={form0}>
          <Row gutter={[20, 0]}>
            <Col span={24}>
              <Form.Item label="行动人员" name="solution_worker_id" rules={[{required: true, message: '请选择行动人员'}]}>
                <Select placeholder="请选择行动人员">
                  {workers.map((worker) => (
                    <Select.Option key={worker.user_id} value={worker.user_id}>
                      {worker.name}({worker.account})
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[20, 0]}>
            <Col span={24}>
              <Form.Item label={'完成日期'} name="solution_date" rules={[{required: true, message: '请选择完成日期'}]}>
                <DatePicker placeholder="选择日期" disabledDate={disabledDate} allowClear={false} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        title="事件处理"
        width={'30%'}
        maskClosable={false}
        okButtonProps={{shape: 'round'}}
        cancelButtonProps={{shape: 'round'}}
        visible={isModalVisible1}
        onOk={() => {
          form1
            .validateFields()
            .then((values) => {
              const index = alertList.findIndex((v) => v.alert_event_id === alert.alert_event_id);
              const newAlertList = [...alertList];
              newAlertList[index] = {...newAlertList[index], solution_state: 2, solution: values.solution};
              setAlertList(newAlertList);
              setIsModalVisible1(false);
              form1.resetFields();
              const alert_event_id = alert.alert_event_id;
              const solution = values.solution;
              submitSolution(alert_event_id, solution);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
        onCancel={() => setIsModalVisible1(false)}>
        <Form className="flex-start" colon={true} labelCol={{span: 5}} form={form1}>
          <Row gutter={[20, 0]}>
            <Col span={24}>
              <Form.Item label="解决方案" name="solution" rules={[{required: true, message: '请填写解决方案'}]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        title="事件处理"
        width={'30%'}
        maskClosable={false}
        okButtonProps={{shape: 'round'}}
        cancelButtonProps={{shape: 'round'}}
        visible={isModalVisible2}
        onOk={() => {
          form2
            .validateFields()
            .then((values) => {
              const index = alertList.findIndex((v) => v.alert_event_id === alert.alert_event_id);
              const newAlertList = [...alertList];
              newAlertList[index] = {...newAlertList[index], solution_state: values.solution_state};
              setAlertList(newAlertList);
              setIsModalVisible2(false);
              form1.resetFields();
              const alert_event_id = alert.alert_event_id;
              const solution_state = values.solution_state;
              auditSolution(alert_event_id, solution_state);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
        onCancel={() => setIsModalVisible2(false)}>
        <Form className="flex-start" colon={true} labelCol={{span: 5}} form={form2}>
          <Row gutter={[20, 0]}>
            <Col span={24}>
              <Form.Item label="处理结果" name="solution_state" rules={[{required: true, message: '请选择处理结果'}]}>
                <Radio.Group>
                  <Radio defaultChecked value={4}>
                    关闭
                  </Radio>
                  <Radio value={3}>驳回</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
export default inject('publicData')(observer(AlertSolution));
