// noinspection JSUnresolvedVariable

import {Button, Col, Modal, Row} from 'antd';
import dayjs from 'dayjs';
import React, {useEffect, useState} from 'react';
import config from '../../config';
import {request} from '../../util';
import './index.scss';
// component:账号信息
const Message = () => {
    const [company, setCompany] = useState(config.companyInfo);
    useEffect(() => {
        loadMineCompanyInfo();
    }, []);
    const loadMineCompanyInfo = () => {
        request.post('/company/queryMyCompanyInfo', {}, false).then((res) => {
            config.companyInfo = res;
            setCompany(res);
        });
    };

    function tipsInfo() {
        Modal.info({
            title: '',
            className: 'tips-modal',
            okText: '确定',
            content: <div>请联系厚德邦安：13801184625</div>,
            onOk() {},
        });
    }

    return (
        <div className="message-content pt-10">
            <Row>
                <Col span={12}>
                    <span className="label">账号状态</span>
                    <span>{company?.state === 1 ? '正常使用' : '停止使用'}</span>
                </Col>
                <Col span={12}>
                    <span className="label">管理员账号</span>
                    <span>{company?.account}</span>
                </Col>
                <Col span={12}>
                    <span className="label">用户数量 </span>
                    <span>
                        {company?.user_count} / {company?.max_users}
                    </span>
                </Col>
                <Col span={12}>
                    <span className="label">节点数量</span>
                    <span>
                        {company?.node_count} / {company?.max_nodes}
                    </span>
                </Col>
                <Col span={12} className="mt-10">
                    <span className="label">开通版本</span>
                    <span>{company?.editionName}</span>
                </Col>
                <Col span={12}>
                    <span className="label">容量不够用,立即升级</span>
                    {/**/}
                    <Button onClick={tipsInfo} size={'small'} shape="default" type="primary">
                        立即升级
                    </Button>
                </Col>
                <Col span={12}>
                    <span className="label">到期时间</span>
                    <span>{company?.due_date ? dayjs(company?.due_date).format('YYYY-MM-DD') : '无'}</span>
                    <Button onClick={tipsInfo} size={'small'} shape="default" type="primary" danger>
                        立即续费
                    </Button>
                </Col>
            </Row>
        </div>
    );
};
export default Message;
