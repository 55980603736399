// noinspection JSUnusedLocalSymbols,JSUnusedGlobalSymbols,JSValidateTypes,JSCheckFunctionSignatures,JSUnresolvedVariable,ES6CheckImport

const equalObj = (oldObj, newObj, propertyList) => {
    let equal = true;
    propertyList.forEach((p) => {
        if (oldObj[p.key] !== newObj[p.key]) {
            equal = false;
        }
    });
    return equal;
};
const getPropertyContent = (item, propertyList) => {
    const content = propertyList.map((property) => `${property.name}:${item[property.key]}`).join(' | ');
    return `【 ${content} 】`;
};
const diffList = (list_old, list_new, id_key, propertyList) => {
    const diffContent = [];
    // 数据为空的情况
    if (!list_old || !list_new)
        return diffContent;
    const newIds = list_new.map((o) => o[id_key]);
    const oldIds = list_old.map((o) => o[id_key]);
    // 1. 找到已经删除的数据
    list_old.forEach((obj) => {
        if (!newIds.includes(obj[id_key])) {
            diffContent.push(`删除 ${getPropertyContent(obj, propertyList)}`);
        }
    });
    // 2.遍历新数据 做出插入或更新操作
    list_new.forEach((obj) => {
        if (oldIds.includes(obj[id_key])) {
            // 有对应id的旧数据
            const oldObj = list_old.find((o) => o[id_key] === obj[id_key]);
            if (!equalObj(oldObj, obj, propertyList)) {
                diffContent.push(` ${getPropertyContent(oldObj, propertyList)} 更新为  ${getPropertyContent(obj, propertyList)}`);
            }
        }
        else {
            // 没id 是插入
            diffContent.push(`新增  ${getPropertyContent(obj, propertyList)}`);
        }
    });
    return diffContent;
};
const getRecordDiffList = (record) => {
    let diffGroupList = [];
    if (!record)
        return diffGroupList;
    if (record.type === 1) {
        const oldGrade = JSON.parse(record.content_old);
        const newGrade = JSON.parse(record.content_new);
        const severityDiffList = diffList(oldGrade.severityList, newGrade.severityList, 'severity_id', [{ key: 'label', name: '严重性等级' }]);
        if (severityDiffList.length > 0) {
            diffGroupList.push({
                title: '严重性等级修改',
                diffList: severityDiffList,
            });
        }
        const riskDiffList = diffList(oldGrade.riskList, newGrade.riskList, 'risk_id', [
            { key: 'label', name: '风险等级' },
            { key: 'color', name: '颜色值' },
        ]);
        if (riskDiffList.length > 0) {
            diffGroupList.push({
                title: '风险等级修改',
                diffList: riskDiffList,
            });
        }
        const pfdDiffList = diffList(oldGrade.pfdList, newGrade.pfdList, 'pfd_id', [
            { key: 'label', name: '可能性等级' },
            { key: 'min_pfd', name: '可能性下限' },
            { key: 'max_pfd', name: '可能性上限' },
        ]);
        if (pfdDiffList.length > 0) {
            diffGroupList.push({
                title: '可能性等级修改',
                diffList: pfdDiffList,
            });
        }
        const riskRoleDiffList = diffList(oldGrade.riskRoleList, newGrade.riskRoleList, 'risk_role_id', [
            { key: 'pfd_value', name: '可能性等级' },
            { key: 'severity_value', name: '严重性等级' },
            { key: 'risk_value', name: '对应的风险等级' },
        ]);
        if (riskRoleDiffList.length > 0) {
            diffGroupList.push({
                title: '风险匹配矩阵修改',
                diffList: riskRoleDiffList,
            });
        }
    }
    else if (record.type === 2) {
        const oldParameterList = JSON.parse(record.content_old).parameterList;
        const newParameterList = JSON.parse(record.content_new).parameterList;
        const parameterDiffList = diffList(oldParameterList, newParameterList, 'label', [
            { key: 'label', name: '参数名称' },
            { key: 'value', name: '参数值' },
        ]);
        if (parameterDiffList.length > 0) {
            diffGroupList.push({
                title: '参数修改',
                diffList: parameterDiffList,
            });
        }
    }
    else if (record.type === 3) {
        const oldGrade = JSON.parse(record.content_old);
        const newGrade = JSON.parse(record.content_new);
        const topEventDiffList = diffList(oldGrade.topEventTypeList, newGrade.topEventTypeList, 'top_event_type_id', [
            { key: 'label', name: '顶级事件类型' },
        ]);
        if (topEventDiffList.length > 0) {
            diffGroupList.push({
                title: '顶级事件类型修改',
                diffList: topEventDiffList,
            });
        }
        const startEventTypeList = diffList(oldGrade.startEventTypeList, newGrade.startEventTypeList, 'start_event_type_id', [
            { key: 'label', name: '起始事件类型' },
        ]);
        if (startEventTypeList.length > 0) {
            diffGroupList.push({
                title: '起始事件类型修改',
                diffList: startEventTypeList,
            });
        }
        const deviationTypeList = diffList(oldGrade.deviationTypeList, newGrade.deviationTypeList, 'deviation_type_id', [
            { key: 'label', name: '偏差类型' },
        ]);
        if (deviationTypeList.length > 0) {
            diffGroupList.push({
                title: '偏差类型修改',
                diffList: deviationTypeList,
            });
        }
        const barrierWorkTypeList = diffList(oldGrade.barrierWorkTypeList, newGrade.barrierWorkTypeList, 'barrier_work_type_id', [
            { key: 'label', name: '保护层类型' },
        ]);
        if (barrierWorkTypeList.length > 0) {
            diffGroupList.push({
                title: '保护层类型修改',
                diffList: barrierWorkTypeList,
            });
        }
    }
    return diffGroupList;
};
export { getRecordDiffList };
