/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {request} from '../../util';
import {Select, Button, DatePicker, Table, Space, message} from 'antd';
import dayjs from 'dayjs';
import {formatSms_state, formatAlertState} from '../../util/publicFunc';
import {inject, observer} from 'mobx-react';
import './index.scss';
import config from '../../config';
import log from 'loglevel';
log.setLevel(config.debug ? log.levels.DEBUG : log.levels.ERROR);
// var moment = require('moment');
const RiskMessage = (props) => {
  const [timeRange, setTimeRange] = useState([dayjs().subtract(1, 'months'), dayjs()]);
  const [nodeHistoryList, setNodeHistoryList] = useState([]);
  const [selectDepartment, setSelectDepartment] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [pageNum, setPageNum] = useState(0);
  const navigate = useNavigate();
  const loadNodeHistoryList = (pageNum, pageSize) => {
    const param = {
      department_id: selectDepartment,
      startTime: dayjs(timeRange[0]).format('YYYY-MM-DD 00:00:00'),
      endTime: dayjs(timeRange[1]).format('YYYY-MM-DD 23:59:59'),
      pageNum,
      pageSize,
      event_type: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    };
    request.post('/company/getNodeAlertHistory', param, true).then((res) => {
      setNodeHistoryList(res.list);
      setTotalCount(res.count);
      setPageNum(res.pageNum);
    });
  };
  useEffect(() => {
    loadNodeHistoryList(0, 10);
  }, [selectDepartment, timeRange]);
  const columns = [
    {
      title: '序号',
      align: 'center',
      key: 'index',
      render: (text, alert, index) => `${index + 1 + pageNum * 10}`,
      width: 70,
    },
    {
      title: '消息时间',
      align: 'center',
      key: 'start_time',
      render: (text, alert, index) => dayjs(alert.start_time).format('YYYY-MM-DD HH:mm:ss'),
      width: 150,
    },
    {
      title: '消息类型',
      align: 'center',
      key: 'event_type',
      dataIndex: 'event_type',
      render: (text, alert, index) => formatAlertState(alert.event_type),
      width: 100,
    },
    {
      title: '相关节点',
      align: 'center',
      key: 'owner_node_info',
      dataIndex: 'owner_node_info',
      width: 100,
    },
    {
      title: '归属部门',
      align: 'center',
      key: 'department_name',
      dataIndex: 'department_name',
      width: 100,
    },
    {
      title: '消息接收人',
      align: 'center',
      key: 'leader_name',
      dataIndex: 'leader_name',
      width: 100,
    },
    {
      title: '消息接收号码',
      align: 'center',
      key: 'leader_phone',
      dataIndex: 'leader_phone',
      width: 100,
    },
    {
      title: '消息内容',
      align: 'center',
      key: 'message_content',
      dataIndex: 'message_content',
      width: 250,
    },
    {
      title: '发送状态',
      align: 'center',
      key: 'leader_phone',
      render: (text, alert, index) => formatSms_state(alert.sms_state),
      width: 50,
    },
    {
      title: '消息备注',
      align: 'center',
      key: 'sms_remark',
      dataIndex: 'sms_remark',
      width: 100,
    },
    {
      title: '操作',
      key: 'action',
      align: 'center',
      fixed: 'right',
      width: 100,
      render: (text, alert, index) => (
        <Space>
          <Button type="link" onClick={() => navChartInfo(alert)}>
            查看节点
          </Button>
          <Button type="link" danger onClick={() => resendSms(alert)}>
            重新发送
          </Button>
        </Space>
      ),
    },
  ];
  const navChartInfo = (alert) => {
    navigate(`/home/chart-info/${alert.owner_node_id}`);
  };
  const resendSms = (alert) => {
    request.post('/company/resendSms', {alert_event_id: alert.alert_event_id}, true).then((res) => {
      message.success('发送成功', 1.5);
    });
  };
  const pageChange = (page, pageSize) => {
    loadNodeHistoryList(page - 1, 10);
  };
  return (
    <div className="node-history">
      <div className="search-view">
        <div className="filter">
          <div className="label">部门：</div>
          <Select className="select" defaultValue={selectDepartment} onChange={(v) => setSelectDepartment(v)}>
            <Select.Option value={''}>全部</Select.Option>
            {props.publicData?.optionList.departmentList?.map((dep) => (
              <Select.Option value={dep.department_id}>{dep.name}</Select.Option>
            ))}
          </Select>
        </div>
        <div className="filter">
          <div className="label">时间范围：</div>
          <DatePicker.RangePicker
            className="time-picker"
            clearIcon={false}
            value={timeRange}
            onChange={(v) => setTimeRange(v)}></DatePicker.RangePicker>
        </div>
      </div>
      <Table
        pagination={{position: ['bottomRight'], total: totalCount, onChange: pageChange}}
        dataSource={nodeHistoryList}
        columns={columns}
        scroll={{x: 2500}}
      />
    </div>
  );
};
export default inject('publicData')(observer(RiskMessage));
