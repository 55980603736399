import {Breadcrumb, Tabs} from 'antd';
import React from 'react';
import Message from './message';
import Record from './record';
// page:账号管理
const Account = () => {
    return (
        <div className="p-lg">
            {/* part:导航栏 */}
            <Breadcrumb>
                <Breadcrumb.Item>平台管理</Breadcrumb.Item>
                <Breadcrumb.Item>账号管理</Breadcrumb.Item>
            </Breadcrumb>
            {/* part:内容 */}
            <Tabs className="com-white-block pt-10" defaultActiveKey="1">
                <Tabs.TabPane tab="账户信息" key="1">
                    <Message/>
                </Tabs.TabPane>
                <Tabs.TabPane tab="缴费记录" key="2">
                    <Record/>
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
};
export default Account;
