import {ConfigProvider} from 'antd';
// import 'antd/dist/antd.css';
import {createRoot} from 'react-dom/client';
import locale from 'antd/lib/locale/zh_CN';
import {Provider} from 'mobx-react';
import 'moment/locale/zh-cn';
import {default as React} from 'react';
// import ReactDOM from 'react-dom';
import {HashRouter, Route, Routes} from 'react-router-dom';
import './index.scss';
import editNode from './page/editChart/editNodeStore';
import Home from './page/home';
import Login from './page/login/login';
import NoMatch from './page/noMatch';
import Dashboard from './page/dashboard';
import publicData from './util/publicData';
import Account from './page/account';
import ApplyLists from './page/apply/lists';
import MyApply from './page/apply/my';
import ChartInfo from './page/chartInfo';
import ChartList from './page/chartList';
import ChartRecord from './page/chartRecord';
import CompanyInfo from './page/companyInfo';
import EditChart from './page/editChart';
import NoticeDetail from './page/notice/detail';
import Notice from './page/notice/index';
import RoleIndex from './page/role';
import DepartmentRecord from './page/role/departmentRecord';
import RoleRecord from './page/role/roleRecord';
import UserRecord from './page/role/userRecord';
import Setting from './page/setting';
import SettingRecord from './page/setting/record';
import SettingRecordDetail from './page/setting/record-detail';
import NodeAlert from './page/nodeAlert/nodeAlert';
import NodeRiskChart from './page/nodeRiskChart';
import NodeHistory from './page/nodeHistory';
import NodeProcess from './page/nodeProcess';
import RiskReport from './page/riskReport';
import RiskMessage from './page/riskMessage';
import LoginLogs from './page/login/logs';
import ProcessSolution from './page/solution';
import AlertSolution from './page/solution/nodeAlert';
import PubNodeChart from './page/chartInfo/pub';

const Root = () => (
  <Provider editNode={editNode} publicData={publicData}>
    <ConfigProvider locale={locale}>
      <HashRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          {/*风险动态*/}
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/home" element={<Home />}>
            {/*风险统计*/}
            <Route path="/home/risk-node" element={<NodeAlert />} />
            <Route path="/home/node-risk-chart" element={<NodeRiskChart />} />
            <Route path="/home/node-history" element={<NodeHistory />} />
            <Route path="/home/node-process" element={<NodeProcess />} />
            <Route path="/home/risk-report" element={<RiskReport />} />
            {/* 闭环管理 */}
            <Route path="/home/process-solution" element={<ProcessSolution />} />
            <Route path="/home/risk-solution" element={<AlertSolution />} />
            {/*节点管理*/}
            <Route path="/home/charts" element={<ChartList />} />
            <Route path="/home/chart-info/:node_id" element={<ChartInfo />} />
            <Route path="/home/edit-chart/:node_id" element={<EditChart />} />
            <Route path="/home/edit-chart/" element={<EditChart />} />
            <Route path="/home/chart-record/:node_id" element={<ChartRecord />} />
            <Route path="/home/chart-record/" element={<ChartRecord />} />
            <Route path="/home/setting" element={<Setting />} />
            <Route path="/home/setting-record" element={<SettingRecord />} />
            <Route path="/home/apply-lists" element={<ApplyLists />} />
            <Route path="/home/apply-lists" element={<ApplyLists />} />
            <Route path="/home/my-apply" element={<MyApply />} />
            <Route path="/home/setting-record-detail/:recordId" element={<SettingRecordDetail />} />
            <Route path="/home/role" element={<RoleIndex />} />
            <Route path="/home/account" element={<Account />} />
            <Route path="/home/department-record" element={<DepartmentRecord />} />
            <Route path="/home/role-record" element={<RoleRecord />} />
            <Route path="/home/user-record" element={<UserRecord />} />
            {/*企业信息*/}
            <Route path="/home/company-info" element={<CompanyInfo />} />
            {/*系统消息*/}
            <Route path="/home/risk-message" element={<RiskMessage />} />
            <Route path="/home/notice" element={<Notice />} />
            <Route path="/home/notice-detail/:notice_id" element={<NoticeDetail />} />
            <Route path="/home/loginlogs" element={<LoginLogs />} />
          </Route>
          <Route path="/notFound" element={<NoMatch />} />
          <Route path="/pub/node-bowtie/:node_id" element={<PubNodeChart />} />
        </Routes>
      </HashRouter>
    </ConfigProvider>
  </Provider>
);

// ReactDOM.render(<Root />, document.getElementById('root'));
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Root />);
