import {Button, Cascader, Col, Form, Image, Input, message, Select, Space, Upload} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import dayjs from 'dayjs';
import {request} from '../../util';
import config from '../../config';
import Modal from 'antd/lib/modal/Modal';
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import region from './region.json';
import './index.scss';

const CompanyInfo = (props) => {
    const [company, setCompany] = useState(config.companyInfo);
    const [editVisible, setEditVisible] = useState(false);
    const companyFormInstance = useRef(null);
    const [logo, setLogo] = useState();
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const loadMineCompanyInfo = () => {
        request.post('/company/queryMyCompanyInfo', {}, true).then((res) => {
            setLogo(res.logo);
            setCompany(res);
        });
    };
    useEffect(() => {
        loadMineCompanyInfo();
        loadAddressOptions();
    }, []);
    const editAction = () => {
        setEditVisible(true);
        setTimeout(() => {
            var _a;
            const ssq = (company === null || company === void 0 ? void 0 : company.province) ? [company.province, company.city, company.district] : [];
            (_a = companyFormInstance.current) === null || _a === void 0 ? void 0 : _a.setFieldsValue(Object.assign(Object.assign({}, company), {ssq}));
        }, 1000);
    };
    const editCompanyInfo = (param) => {
        setEditVisible(false);
        const ssq = param.ssq || [];
        param.province = ssq[0];
        param.city = ssq[1];
        param.district = ssq[2];
        param.logo = logo;
        request.post('/company/editMyCompanyInfo', param, true).then((res) => {
            message.success('更新成功', 1.5, loadMineCompanyInfo);
        });
    };

    function beforeUpload(file, fileList) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('您只能选择图片');
        }
        const isLt2M = file.size / 1024 / 1024 < 4;
        if (!isLt2M) {
            message.error('图片大小不能超过 4MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{marginTop: 8}}>上传图片</div>
        </div>
    );
    const loadAddressOptions = () => {
        // 省市区联动
        const regionOption = region.map((province) => {
            const citys = province.city.map((city) => {
                const areas = city.area.map((area) => ({label: area, value: area}));
                return {
                    label: city.name,
                    value: city.name,
                    children: areas,
                };
            });
            return {
                label: province.name,
                value: province.name,
                children: citys,
            };
        });
        setOptions(regionOption);
    };
    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            setLogo(undefined);
            return;
        }
        setLoading(false);
        if (info.file.status === 'done') {
            const response = info.file.response;
            if (response.code === 200) {
                setLogo(response.data.url);
            } else {
                message.error(response.msg);
            }
        } else if (info.file.status === 'error') {
            message.error('图片上传失败');
        }
    };
    const cancleEdit = () => {
        setEditVisible(false);
    };
    return (
        <div className="company-info">
            <Form labelCol={{span: 12}}>
                <Form.Item label="LOGO">
                    <Image src={company?.logo} alt="avatar" className="logo"/>
                </Form.Item>
                <Form.Item label="企业名称">{company?.company_name}</Form.Item>
                <Form.Item label="行业分类">{company?.industry_type}</Form.Item>
                <Form.Item label="联系人">{company?.contact_name}</Form.Item>
                <Form.Item label="手机号">{company?.contact_phone}</Form.Item>
                <Form.Item label="邮箱">{company?.contact_email}</Form.Item>
                <Form.Item label="地址">
                    {company?.province} {company?.city} {company?.district} {company?.address}
                </Form.Item>
                <Form.Item label="状态">{company?.state === 1 ? '使用中' : '停用'}</Form.Item>
                <Form.Item label="当前版本">{company?.editionName}</Form.Item>
                <Form.Item label="用户总量">
                    {company?.max_users}, 当前已用：{company?.user_count} 
                </Form.Item>
                <Form.Item label="节点总量">
                     {company?.max_nodes}, 当前已用：{company?.node_count}
                </Form.Item>
                <Form.Item
                    label="到期时间">{company?.due_date ? dayjs(company?.due_date).format('YYYY-MM-DD') : '无'}</Form.Item>
                <Form.Item label="管理员账号">{company?.account}</Form.Item>
                <Form.Item label="企业编号">{company?.company_id}</Form.Item>
            </Form>
            <Modal title="编辑企业" visible={editVisible} closable={false} footer={null}>
                <Form className="com-white-block form-row-box" ref={companyFormInstance} onFinish={editCompanyInfo}
                      labelCol={{span: 6}}>
                    <Form.Item label="企业LOGO" className="logo-input-item" name="logo">
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            action={config.apiHost + '/public/uploadLogoImage'}
                            onChange={handleChange}
                            headers={{Authorization: config.token || ''}}
                            accept="img">
                            {logo ?
                                <img src={logo} alt="avatar" style={{width: '100px', height: '100px'}}/> : uploadButton}
                        </Upload>
                    </Form.Item>
                    <Form.Item label="企业名称" name="company_name">
                        <Input name="companyName" placeholder="请输入企业名称"/>
                    </Form.Item>
                    <Form.Item label="行业分类" name="industry_type">
                        <Select className="input-select" placeholder="请选择行业分类">
                            {config.industryList.map((ins) => (
                                <Select.Option value={ins.title}>{ins.title}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="联系人" name="contact_name">
                        <Input placeholder="请输入联系人"/>
                    </Form.Item>
                    <Form.Item label="手机号" name="contact_phone">
                        <Input placeholder="请输入手机号码"/>
                    </Form.Item>
                    <Form.Item label="邮箱" name="contact_email">
                        <Input placeholder="请输入邮箱"/>
                    </Form.Item>
                    <Form.Item label="所属地区" name="ssq">
                        <Cascader options={options} placeholder="请选择省市区"/>
                    </Form.Item>
                    <Form.Item label="详情地址" name="address">
                        <Input className="input-address" placeholder="请输入详细地址"/>
                    </Form.Item>
                    <Col className="com-align_center pt-lg pb-lg" sm={24} xs={24} md={18}>
                        <Space size={'middle'}>
                            <Button size={'middle'} shape="default" className="com-btn com-btn-block green"
                                    htmlType="submit">
                                保存
                            </Button>
                            <Button size={'middle'} onClick={cancleEdit} shape="default" className="com-btn">
                                取消
                            </Button>
                        </Space>
                    </Col>
                </Form>
            </Modal>
            <Button type="primary" onClick={editAction}>
                编辑
            </Button>
        </div>
    );
};
export default CompanyInfo;
