import {Upload, Button, Form, Input, message} from 'antd';
import {inject, observer} from 'mobx-react';
import React, {useState} from 'react';
import {request} from '../../util';
import {FileExcelOutlined} from '@ant-design/icons';
import {saveAs} from 'file-saver';

const OpcDA = () => {
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [opcdaConf, setOpcdaConf] = useState();
    const [opcdaMysqlConf, setOpcdaMysqlConf] = useState();
    const [form] = Form.useForm();
    const props = {
        name: 'file',
        accept: '.csv',
        fileList,
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([file]);
            return false;
        },
    };
    const onParse = async () => {
        if (!fileList) {
            message.error('请选择文件');
            return;
        }
        const formData = new FormData();
        fileList.forEach((f) => {
            formData.append('files[]', f);
        });
        const name = form.getFieldValue('name') || 'opcda_processdata';
        formData.append('name', name);
        const serverName = form.getFieldValue('serverName') || 'Kepware.KEPServerEX.V6';
        formData.append('serverName', serverName);
        const serverIP = form.getFieldValue('serverIP') || '127.0.0.1';
        formData.append('serverIP', serverIP);
        const mysql = form.getFieldValue('mysql') || '127.0.0.1';
        formData.append('mysql', mysql);
        setUploading(true);
        request
            .post('/company/parseOpcda', formData, true)
            .then((res) => {
                setUploading(false);
                const opcdaConf1 = new Blob([res.opcda_conf], {type: 'text/plain;charset=utf-8'});
                setOpcdaConf(opcdaConf1);
                const opcdaMysqlConf1 = new Blob([res.opcda_mysql_conf], {type: 'text/plain;charset=utf-8'});
                setOpcdaMysqlConf(opcdaMysqlConf1);
            })
            .catch((err) => {
                setUploading(false);
                console.error(err);
            });
    };
    const template = `Address
Channel1.Device1.Tag001
Channel1.Device2.Tag002`;
    return (
        <div className="setting-item">
            <div className="setting-title">OPC DA</div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                <Form form={form} labelCol={{span: 8}} wrapperCol={{span: 16}}>
                    <Form.Item style={{marginTop: 6, marginBottom: 6}} name="serverName" label="OPC服务器名称">
                        <Input placeholder="Kepware.KEPServerEX.V6" />
                    </Form.Item>
                    <Form.Item style={{marginTop: 6, marginBottom: 6}} name="serverIP" label="OPC服务器地址">
                        <Input placeholder="127.0.0.1" />
                    </Form.Item>
                    <Form.Item style={{marginTop: 6, marginBottom: 6}} name="mysql" label="数据库服务器地址">
                        <Input placeholder="127.0.0.1" />
                    </Form.Item>
                    <Form.Item style={{marginTop: 6, marginBottom: 6}} name="name" label="数据库表名">
                        <Input placeholder="opcda_processdata" />
                    </Form.Item>
                </Form>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                    <Button
                        onClick={() => saveAs(new Blob([template], {type: 'text/plain;charset=utf-8'}), 'opcda_template.csv')}
                        style={{margin: 6}}
                    >
                        {'CSV模版'}
                    </Button>
                    <Upload {...props}>
                        <Button icon={<FileExcelOutlined />}>选择文件</Button>
                    </Upload>
                </div>
                <Button
                    type="primary"
                    onClick={onParse}
                    disabled={fileList.length === 0}
                    loading={uploading}
                    style={{
                        marginTop: 16,
                    }}
                >
                    生成配置文件
                </Button>
                {opcdaConf && (
                    <Button onClick={() => saveAs(opcdaConf, 'opcda.conf')} style={{marginTop: 6, marginBottom: 6}}>
                        {'opcda.conf'}
                    </Button>
                )}
                {opcdaMysqlConf && (
                    <Button onClick={() => saveAs(opcdaMysqlConf, 'opcda_mysql.conf')} style={{marginTop: 6, marginBottom: 6}}>
                        {'opcda_mysql.conf'}
                    </Button>
                )}
            </div>
        </div>
    );
};
export default inject('publicData')(observer(OpcDA));
