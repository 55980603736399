/* eslint-disable react-hooks/exhaustive-deps */
// noinspection NpmUsedModulesInstalled,JSUnresolvedVariable,JSValidateTypes,JSUnresolvedFunction

import React, {useEffect, useState} from 'react';
import {request} from '../../util';
import './index.scss';
import {Select, Button, DatePicker, Table} from 'antd';
import {inject, observer} from 'mobx-react';
import dayjs from 'dayjs';
import {formatAlertEvent} from '../../util/publicFunc';
import config from '../../config';
import log from 'loglevel';
import {Column} from '@ant-design/plots';
log.setLevel(config.debug ? log.levels.DEBUG : log.levels.ERROR);
const ExportJsonExcel = require('js-export-excel');
// const moment = require('moment');
const NodeHistory = (props) => {
  const [selectNode, setSelectNode] = useState('');
  const [nodeList, setNodeList] = useState([]);
  const [timeRange, setTimeRange] = useState([dayjs().subtract(1, 'months'), dayjs()]);
  const [nodeHistoryList, setNodeHistoryList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNum, setPageNum] = useState(0);
  const [selectBarrierWorkType, setSelectBarrierWorkType] = useState('');
  const [eventType, setEventType] = useState(0);
  const [selectDepartment, setSelectDepartment] = useState('');
  const loadNodeList = () => {
    request.post('/company/queryNodeList', {}, true).then((res) => {
      setNodeList(res.nodeList);
      // if (res.nodeList[0]) {
      //   setSelectNode(res.nodeList[0].node_id);
      // }
    });
  };
  const loadNodeHistoryList = (pageNum, pageSize) => {
    const param = {
      node_id: selectNode,
      department_id: selectDepartment,
      event_type: eventType === 0 ? [1, 2, 3, 4] : [eventType],
      barrierWorkType: selectBarrierWorkType,
      startTime: dayjs(timeRange[0]).format('YYYY-MM-DD 00:00:00'),
      endTime: dayjs(timeRange[1]).format('YYYY-MM-DD 23:59:59'),
      pageNum,
      pageSize,
    };
    request.post('/company/getNodeAlertHistory', param, true).then((res) => {
      setNodeHistoryList(res.list.map(formatAlertEvent));
      setTotalCount(res.count);
      setPageNum(res.pageNum);
    });
  };
  useEffect(() => {
    loadNodeList();
  }, []);
  useEffect(() => {
    loadNodeHistoryList(0, 10);
  }, [selectNode, selectDepartment, eventType, selectBarrierWorkType, timeRange]);
  const columns = [
    {
      title: '序号',
      align: 'center',
      key: 'index',
      render: (text, alert, index) => `${index + 1 + pageNum * 10}`,
      width: 70,
    },
    {
      title: '事件时间',
      align: 'center',
      key: 'start_time',
      dataIndex: 'start_time',
      width: 200,
    },
    {
      title: '事件类型',
      align: 'center',
      key: 'event_type',
      dataIndex: 'event_type',
      width: 100,
    },
    {
      title: '相关描述',
      align: 'center',
      key: 'describe_info',
      dataIndex: 'describe_info',
      width: 150,
    },
    {
      title: '相关节点',
      align: 'center',
      key: 'owner_node_info',
      dataIndex: 'owner_node_info',
      width: 100,
    },
    {
      title: '相关事件流',
      align: 'center',
      key: 'owner_stream_info',
      dataIndex: 'owner_stream_info',
      width: 150,
    },
    {
      title: '相关起始事件',
      align: 'center',
      key: 'owner_start_event_info',
      dataIndex: 'owner_start_event_info',
      width: 150,
    },
    {
      title: '相关后果',
      align: 'center',
      key: 'owner_result_info',
      dataIndex: 'owner_result_info',
      width: 150,
    },
    {
      title: '相关保护层',
      align: 'center',
      key: 'owner_barrier_info',
      dataIndex: 'owner_barrier_info',
      width: 150,
    },
    {
      title: '相关部门',
      align: 'center',
      key: 'department_name',
      dataIndex: 'department_name',
      width: 150,
    },
    {
      title: '短信接收人',
      align: 'center',
      key: 'leader_name',
      dataIndex: 'leader_name',
      width: 150,
    },
    {
      title: '短信接收号码',
      align: 'center',
      key: 'leader_phone',
      dataIndex: 'leader_phone',
      width: 150,
    },
    {
      title: '消息发送状态',
      align: 'center',
      key: 'sms_state',
      dataIndex: 'sms_state',
      width: 100,
    },
    {
      title: '短信内容',
      align: 'center',
      key: 'message_content',
      dataIndex: 'message_content',
      width: 250,
    },
  ];
  // 下载excel文件
  const downloadExcel = () => {
    const option = {};
    option.fileName = '节点历史事件';
    const param = {
      node_id: selectNode,
      department_id: selectDepartment,
      event_type: eventType === 0 ? [1, 2, 3, 4] : [eventType],
      barrierWorkType: selectBarrierWorkType,
      startTime: dayjs(timeRange[0]).format('YYYY-MM-DD 00:00:00'),
      endTime: dayjs(timeRange[1]).format('YYYY-MM-DD 23:59:59'),
      pageNum: 0,
      pageSize: totalCount,
    };
    request.post('/company/getNodeAlertHistory', param, true).then((res) => {
      const nodeHistoryList = res.list.map(formatAlertEvent);
      const data = nodeHistoryList.map((alert, index) => [
        index,
        alert.start_time,
        alert.event_type,
        alert.describe_info,
        alert.owner_node_info,
        alert.owner_stream_info,
        alert.owner_start_event_info,
        alert.owner_result_info,
        alert.owner_barrier_info,
        alert.department_name,
        alert.leader_name,
        alert.leader_phone,
        alert.sms_state,
        alert.message_content,
      ]);
      option.datas = [
        {
          sheetData: data,
          sheetName: 'sheet',
          sheetHeader: [
            '序号',
            '事件时间',
            '事件类型',
            '相关描述',
            '相关节点',
            '相关事件流',
            '相关起始事件',
            '相关后果',
            '相关保护层',
            '相关部门',
            '短信接收人',
            '短信接收号码',
            '发送状态',
            '短信内容',
          ],
          columnWidths: [5, 5, 5, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20],
        },
      ];
      const toExcel = new ExportJsonExcel(option); //new
      toExcel.saveExcel(); //保存
    });
  };
  const pageChange = (page, pageSize = 10) => {
    loadNodeHistoryList(page - 1, pageSize);
  };
  // Chart 1: 风险事件统计-发生次数
  const [data1, setData1] = useState([]);
  const [total1, setTotal1] = useState('0');
  useEffect(() => {
    getNodeAlertHistoryCount();
  }, [selectNode, selectDepartment, eventType, selectBarrierWorkType, timeRange]);
  const getNodeAlertHistoryCount = () => {
    const param = {
      node_id: selectNode,
      department_id: selectDepartment,
      event_type: eventType === 0 ? [1, 2, 3, 4] : [eventType],
      barrierWorkType: selectBarrierWorkType,
      startTime: dayjs(timeRange[0]).format('YYYY-MM-DD 00:00:00'),
      endTime: dayjs(timeRange[1]).format('YYYY-MM-DD 23:59:59'),
    };
    request.post('/company/getNodeAlertHistoryCount', param, true).then((res) => {
      const data1 = res.data;
      setData1(data1);
      const total1 = data1.reduce((pre, cur) => pre + cur.total, 0);
      setTotal1(total1.toLocaleString());
    });
  };
  const config1 = {
    data: data1,
    isStack: true,
    xField: 'date',
    yField: 'total',
    seriesField: 'node_name',
    dodgePadding: 2,
    padding: 80,
    label: {
      // position: 'middle', // 'top', 'bottom', 'middle'
    },
    interactions: [
      {
        type: 'active-region',
        enable: false,
      },
    ],
  };
  // Chart 2: 风险事件统计-持续时间
  const [data2, setData2] = useState([]);
  const [duration2, setDuration2] = useState('0');
  useEffect(() => {
    getNodeAlertHistoryDuration();
  }, [selectNode, selectDepartment, eventType, selectBarrierWorkType, timeRange]);
  const getNodeAlertHistoryDuration = () => {
    const param = {
      node_id: selectNode,
      department_id: selectDepartment,
      event_type: eventType === 0 ? [1, 2, 3, 4] : [eventType],
      barrierWorkType: selectBarrierWorkType,
      startTime: dayjs(timeRange[0]).format('YYYY-MM-DD 00:00:00'),
      endTime: dayjs(timeRange[1]).format('YYYY-MM-DD 23:59:59'),
    };
    request.post('/company/getNodeAlertHistoryDuration', param, true).then((res) => {
      const data2 = res.data;
      setData2(data2);
      const total2 = data2.reduce((pre, cur) => pre + cur.total, 0);
      setDuration2(total2.toLocaleString());
    });
  };
  const config2 = {
    data: data2,
    isStack: true,
    xField: 'date',
    yField: 'total',
    seriesField: 'node_name',
    dodgePadding: 2,
    padding: 80,
    label: {
      // position: 'middle', // 'top', 'bottom', 'middle'
    },
    interactions: [
      {
        type: 'active-region',
        enable: false,
      },
    ],
  };
  return (
    <div className="node-history">
      <div className="search-view">
        <div className="filter">
          <div className="label">节点：</div>
          <Select className="select" defaultValue={selectNode} style={{width: 150}} onChange={(v) => setSelectNode(v)}>
            <Select.Option value={''} key={'all'}>
              全部
            </Select.Option>
            {nodeList.map((node) => (
              <Select.Option value={node.node_id || ''} key={node.node_id}>
                {node.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="filter">
          <div className="label">保护层类型：</div>
          <Select className="select" defaultValue={selectBarrierWorkType} onChange={(v) => setSelectBarrierWorkType(v)}>
            <Select.Option value={''}>全部</Select.Option>
            {props.publicData?.optionList.barrierWorkTypeList?.map((dep) => (
              <Select.Option value={dep.barrier_work_type_id || ''}>{dep.label}</Select.Option>
            ))}
          </Select>
        </div>
        <div className="filter">
          <div className="label">事件类型：</div>
          <Select className="select" defaultValue={eventType} onChange={(v) => setEventType(v)}>
            <Select.Option value={0}>全部</Select.Option>
            <Select.Option value={1}>触发</Select.Option>
            <Select.Option value={2}>失效</Select.Option>
            <Select.Option value={3}>未知</Select.Option>
            <Select.Option value={4}>缺少tag</Select.Option>
          </Select>
        </div>
        <div className="filter">
          <div className="label">部门：</div>
          <Select className="select" defaultValue={selectDepartment} onChange={(v) => setSelectDepartment(v)}>
            <Select.Option value={''}>全部</Select.Option>
            {props.publicData?.optionList.departmentList?.map((dep) => (
              <Select.Option value={dep.department_id}>{dep.name}</Select.Option>
            ))}
          </Select>
        </div>
        <div className="filter">
          <div className="label">发生时间：</div>
          <DatePicker.RangePicker
            className="time-picker"
            clearIcon={false}
            value={timeRange}
            onChange={(v) => setTimeRange(v)}></DatePicker.RangePicker>
        </div>
        <div className="report">
          <Button type="primary" className="getExcel" onClick={downloadExcel}>
            生成报告
          </Button>
        </div>
      </div>
      <div style={{height: '400px', backgroundColor: 'white', marginTop: 20}}>
        <h4 style={{paddingLeft: 50, marginTop: 20, color: 'gray'}}>事件发生次数（{total1}次）</h4>
        <Column {...config1} />
      </div>

      <div style={{height: '400px', backgroundColor: 'white', marginTop: 20}}>
        <h4 style={{paddingLeft: 50, marginTop: 20, color: 'gray'}}>事件发生持续时间（{duration2}分钟）</h4>
        <Column {...config2} />
      </div>
      <div className="">
        <div style={{marginTop: 20}}>
          <Table
            pagination={{position: ['bottomRight'], total: totalCount, onChange: pageChange}}
            dataSource={nodeHistoryList}
            columns={columns}
            scroll={{x: 2500}}
          />
        </div>
      </div>
    </div>
  );
};
export default inject('publicData')(observer(NodeHistory));
