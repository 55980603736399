import { Button, Form, Input, message, Modal, Row, Select, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { inject, observer } from 'mobx-react';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import FormulaInput from '../../../component/formulaInput/formulaInput';
import config from '../../../config';
import log from 'loglevel';
log.setLevel(config.debug ? log.levels.DEBUG : log.levels.ERROR);
const StartEventModal = (props, ref) => {
    const [streamForm] = useForm();
    const [showStreamModal, setShowStreamModal] = useState(false);
    const [itemIndex, setItemIndex] = useState(0);
    const [type, setType] = useState('add');
    const [editData, setEditData] = useState();
    // 执行公式
    const evaldata = async function (estr) {
        // eslint-disable-next-line no-eval
        return eval(estr);
    };
    const onFinish = (data) => {
        var _a, _b;
        const rule = /【.*?】/g;
        // pfd公式
        const pfdE = ((_a = data.pfdTags) === null || _a === void 0 ? void 0 : _a.replace(rule, '1')) || '';
        // 触发公式
        const touchE = ((_b = data.touchTags) === null || _b === void 0 ? void 0 : _b.replace(rule, '1')) || '';
        Promise.all([evaldata(pfdE), evaldata(touchE)])
            .then((res) => {
            if (type === 'add') {
                data.barrierList = [];
                props.editNode.addStartEvent(props.streamIndex, itemIndex, data);
            }
            else {
                // 保护层要缓存下来
                props.editNode.updateStartEvent(props.streamIndex, itemIndex, Object.assign(Object.assign({}, editData === null || editData === void 0 ? void 0 : editData.start), data));
            }
            streamForm.resetFields();
            setShowStreamModal(false);
        })
            .catch((err) => {
            message.error('公式不能运行，请检查');
        });
    };
    const onReset = () => {
        streamForm.resetFields();
        setShowStreamModal(false);
    };
    // 点击删除
    const onDelete = () => {
        streamForm.resetFields();
        setShowStreamModal(false);
        props.editNode.deleteStartEvent(props.streamIndex, itemIndex);
    };
    // 复制操作
    const copyItem = () => {
        streamForm.resetFields();
        setShowStreamModal(false);
        props.editNode.copyStartEvent(props.streamIndex, itemIndex);
    };
    useImperativeHandle(ref, () => ({
        open: (index, data) => {
            log.debug('起始事件弹窗 ', data, index);
            setShowStreamModal(true);
            setItemIndex(index);
            if (data) {
                setType('edit');
                setEditData(data);
                streamForm.setFieldsValue(data.start);
            }
            else {
                setType('add');
            }
        },
        close: () => {
            setShowStreamModal(false);
        },
    }));
    const layout = {
        labelCol: { span: 2 },
        wrapperCol: { span: 22 },
    };
    return (
        <Modal title="添加起始事件" visible={showStreamModal} closable={false} footer={null} width={1200}>
            <Form {...layout} form={streamForm} onFinish={onFinish} onReset={onReset}>
                <Form.Item label="名称：" name="info" rules={[{required: true, message: '请输入事件名称'}]}>
                    <Input placeholder="请输入事件名称" maxLength={200} />
                </Form.Item>
                <Form.Item label="起始事件类型：" name="start_event_type_id" rules={[{required: true, message: '请选择起始事件类型'}]}>
                    <Select placeholder="请选择起始事件类型">
                        {props.publicData?.optionList.startEventTypeList?.map((v) => (
                            <Select.Option value={v.start_event_type_id || ''} key={v.start_event_type_id}>
                                {v.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="偏差类型：" name="deviation_type_id" rules={[{required: true, message: '请选择偏差类型'}]}>
                    <Select placeholder="请选择偏差类型">
                        {props.publicData?.optionList.deviationTypeList?.map((v) => (
                            <Select.Option value={v.deviation_type_id || ''} key={v.deviation_type_id}>
                                {v.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="所属部门：" name="department_id" rules={[{required: true, message: '请选择部门'}]}>
                    <Select placeholder="请选择部门">
                        {props.publicData?.optionList.departmentList?.map((v) => (
                            <Select.Option value={v.department_id || ''} key={v.department_id}>
                                {v.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="触发逻辑" name="touchTags" rules={[{required: true, message: '请输入触发逻辑'}]}>
                    <FormulaInput
                        parameterList={props.publicData?.optionList.parameterList || []}
                        processList={props.publicData?.optionList.processList || []}
                    />
                </Form.Item>

                <Form.Item label="PFD逻辑" name="pfdTags" rules={[{required: true, message: '请输入PFD逻辑'}]}>
                    <FormulaInput
                        parameterList={props.publicData?.optionList.parameterList || []}
                        processList={props.publicData?.optionList.processList || []}
                    />
                </Form.Item>

                <Form.Item>
                    <Row justify="center">
                        <Space size="middle">
                            {type === 'edit' && (
                                <Button type="link" htmlType="button" onClick={copyItem}>
                                    复制
                                </Button>
                            )}
                            {type === 'edit' && (
                                <Button type="link" danger htmlType="button" onClick={onDelete}>
                                    删除
                                </Button>
                            )}
                            <Button type="default" htmlType="reset">
                                取消
                            </Button>
                            <Button type="primary" htmlType="submit">
                                确定
                            </Button>
                        </Space>
                    </Row>
                </Form.Item>
            </Form>
        </Modal>
    );
};
export default inject('editNode', 'publicData')(observer(forwardRef(StartEventModal)));
