/* eslint-disable no-eval */
// noinspection JSUnusedLocalSymbols,JSUnresolvedVariable,JSDeprecatedSymbols,JSIgnoredPromiseFromCall

import {message} from 'antd';
import config from '../config';
import {request} from './index';
import dayjs from 'dayjs';

import log from 'loglevel';
log.setLevel(config.debug ? log.levels.DEBUG : log.levels.ERROR);

// ======  等级信息  ========
let severityList = [];
let pfdList = [];
let riskList = [];
let riskRoleList = [];
// ======  选项信息  =========
let topEventTypeList = [];
let startEventTypeList = [];
let deviationTypeList = [];
let barrierWorkTypeList = [];
// ========  部门信息  ==========
let departmentList = [];
/**
 *  加载配置信息
 */
const loadSetting = () => {
    if (severityList.length > 0 && topEventTypeList.length > 0 && departmentList.length > 0) {
        return Promise.resolve();
    } else {
        const gradePromise = loadGradeList();
        const optionPromise = loadOptionList();
        const departPromise = loadDepartmentList();
        return Promise.all([gradePromise, optionPromise, departPromise]).then((r) => Promise.resolve());
    }
};
// 查询等级信息
const loadGradeList = () => {
    return request.post('/company/getAllGradeList', {}, true).then((res) => {
        severityList = res.severityList;
        pfdList = res.pfdList;
        riskList = res.riskList;
        riskRoleList = res.riskRoleList;
    });
};
// 查询选项信息
const loadOptionList = () => {
    return request.post('/company/getAllOptionList', {}, true).then((res) => {
        topEventTypeList = res.topEventTypeList;
        startEventTypeList = res.startEventTypeList;
        deviationTypeList = res.deviationTypeList;
        barrierWorkTypeList = res.barrierWorkTypeList;
    });
};
// 查询部门
const loadDepartmentList = () => {
    return request.post('/company/queryDepartments', {}, true).then((res) => {
        departmentList = res.departmentList;
    });
};
const getSeverity = (id) => {
    return severityList.find((v) => v.severity_id === id);
};
const getPfd = (id) => {
    return pfdList.find((v) => v.pfd_id === id);
};
const getRisk = (id) => {
    return riskList.find((v) => v.risk_id === id);
};
const getRiskRole = (id) => {
    return riskRoleList.find((v) => v.risk_role_id === id);
};
const getTopEventType = (id) => {
    return topEventTypeList.find((v) => v.top_event_type_id === id);
};
const getStartEventType = (id) => {
    return startEventTypeList.find((v) => v.start_event_type_id === id);
};
const getDeviationType = (id) => {
    return deviationTypeList.find((v) => v.deviation_type_id === id);
};
const getBarrierWorkType = (id) => {
    return barrierWorkTypeList.find((v) => v.barrier_work_type_id === id);
};
/**
 *  根据后果值和可能性值得到风险等级
 * severityValue: 风险等级
 * pfdValue :可能性等级
 */
const exchangeRiskGrade = (severityValue, pfdValue) => {
    const riskRole = riskRoleList.find((role) => role.severity_value === severityValue && role.pfd_value === pfdValue);
    if (riskRole) {
        return riskList.find((risk) => risk.value === riskRole.risk_value);
    }
};
/*
 *获取配置参数
 */
const getConfig = (keyword, callback) => {
    request.post('/company/queryConfigList', {}, true).then((res) => {
        const configData = res.configList.find((config) => config.keyword === keyword);
        callback(configData);
    });
};
/**
 *  根据pfd值,得到pfd等级对象
 */
const exchangePfdGrade = (pfd) => {
    if (pfd < 0)
        pfd = 0;
    if (pfd > 1)
        pfd = 1;
    return pfdList.find((grade) => pfd >= (grade.min_pfd || 0) && pfd <= (grade.max_pfd || 1));
};
///  正则提取 tag字符串中的tagid
const expStr = (tagStr) => {
    let _a;
    const rule = /【.*?】/g;
    let tags = (_a = tagStr === null || tagStr === void 0 ? void 0 : tagStr.match(rule)) === null || _a === void 0 ? void 0 : _a.map((s) => s.trim());
    return tags || [];
};
/**
 *  判断tag 是不是参数设定值
 */
const isDepartmentTag = (tag) => {
    const reg = new RegExp(`${config.paramSuffix}$`);
    return reg.test(tag);
};
// 获取所有节点的tagid 数组
const getNodesTags = (nodes) => {
    let _a, _b;
    let tagIds = [];
    const extractTagId = (tagStr) => {
        tagIds = tagIds.concat(expStr(tagStr));
    };
    for (const nodeInfo of nodes) {
        (_a = nodeInfo.streamList) === null || _a === void 0 ? void 0 : _a.forEach((stream) => {
            let _a, _b;
            (_a = stream.startEventList) === null || _a === void 0 ? void 0 : _a.forEach((init) => {
                let _a;
                extractTagId(init.touchTags);
                extractTagId(init.pfdTags);
                (_a = init.barrierList) === null || _a === void 0 ? void 0 : _a.forEach((barrier) => {
                    extractTagId(barrier.touchTags);
                    extractTagId(barrier.pfdTags);
                });
            });
            (_b = stream.resultList) === null || _b === void 0 ? void 0 : _b.forEach((result) => {
                let _a;
                (_a = result.barrierList) === null || _a === void 0 ? void 0 : _a.forEach((barrier) => {
                    extractTagId(barrier.touchTags);
                    extractTagId(barrier.pfdTags);
                });
            });
        });
    }
    // 用set 去重
    tagIds = Array.from(new Set(tagIds));
    // 去掉[] 和头尾空格
    tagIds = (_b = tagIds === null || tagIds === void 0 ? void 0 : tagIds.map((tag) => tag.substr(1, tag.length - 2))) === null || _b === void 0 ? void 0 : _b.map((s) => s.trim());
    // 去掉后缀
    tagIds = tagIds === null || tagIds === void 0 ? void 0 : tagIds.map((tag) => {
        if (isDepartmentTag(tag)) {
            return tag.substring(0, tag.length - config.paramSuffix.length);
        } else {
            return tag;
        }
    });
    return tagIds;
};
/**
 *  处理 tag 字符串
 */
const handleTagStr = (tagStr, tagStates) => {
    let _a, _b;
    // 1.获得字符串中所有tag 并去掉 []
    const tagList = (_b = (_a = expStr(tagStr)) === null || _a === void 0 ? void 0 : _a.map((tag) => tag.substr(1, tag.length - 2))) === null || _b === void 0 ? void 0 : _b.map((s) => s.trim());
    // 2.遍历tag
    tagList.forEach((tag) => {
        if (isDepartmentTag(tag)) {
            if (tagStates.parameterList.length === 0 && tagStates.processList.length === 0)
                return [{}, null];
            // 2.1 如果tag是参数设定值,就从参数设定列表里面寻找,找到就替换 找不到就上报
            const param = tagStates.parameterList.find((p) => `${p.label}${config.paramSuffix}` === tag);
            if (param) {
                tagStr = tagStr.replace(`【${param.label}${config.paramSuffix}】`, `${param.value}`);
            } else {
                // 如果找不到tag 向上报错
                const msgStr = `tag:${tag} 没有对应的参数设定值,请检查设置`;
                message.error(msgStr);
                return [{message: msgStr}, ''];
            }
        } else {
            if (tagStates.parameterList.length === 0 && tagStates.processList.length === 0)
                return [{}, null];
            // 2.2 如果tag是实时的值,那么就从实时数据中寻找,找到就替换 找不到就上报
            const param = tagStates.processList.find((p) => p.label === tag);
            if (param) {
                tagStr = tagStr.replace(`【${param.label}】`, `${param.value}`);
            } else {
                //  如果找不到tag 向上报错
                const msgStr = `tag:${tag} 没有对应的tag值,请检查设置`;
                message.error(msgStr);
                return [{message: msgStr}, ''];
            }
        }
    });
    return [null, tagStr];
};
/**
 *  判断tag是否可信
 */
const tagStrTrust = (tagStr, tagStates = {parameterList: [], processList: []}) => {
    let _a, _b;
    let trust = true;
    // 1.获得字符串中所有tag 并去掉 []
    const tagList = (_b = (_a = expStr(tagStr)) === null || _a === void 0 ? void 0 : _a.map((tag) => tag.substr(1, tag.length - 2))) === null || _b === void 0 ? void 0 : _b.map((s) => s.trim());
    // 2.遍历tag
    tagList.forEach((tag) => {
        if (tagStates.parameterList.length === 0 && tagStates.processList.length === 0) {
            trust = false;
            return;
        }
        let param;
        if (isDepartmentTag(tag)) {
            // 2.1 如果tag是参数设定值,就从参数设定列表里面寻找
            param = tagStates.parameterList.find((p) => `${p.label}${config.paramSuffix}` === tag);
        } else {
            // 2.2 如果tag是实时的值,那么就从实时数据中寻找
            param = tagStates.processList.find((p) => p.label === tag);
        }
        // 2.3 如果找到tag ,就把tag字符串替换为 tag真实值
        if (param) {
            if (param.quality !== 192) {
                trust = false;
            }
        } else {
            // 2.4 如果找不到tag 向上报错
            // 2.4 如果找不到tag 向上报错
            const msgStr = `tag:${tag} 没有对应的值,请检查设置`;
            message.error(msgStr);
            trust = false;
        }
    });
    return trust;
};
// 判断节点是否正常运行
const checkTagWorking = (tagStr, tagStates = {parameterList: [], processList: []}) => {
    try {
        const [error, realTagStr] = handleTagStr(tagStr, tagStates);
        if (!error) {
            return !!eval(realTagStr);
        }
    } catch (error) {
        return false;
    }
    return false;
};
/**
 *  计算pfd的值
 */
const getTagPfdResult = (pfdStr, tagStates) => {
    try {
        const [error, realTagStr] = handleTagStr(pfdStr, tagStates);
        if (!error) {
            return eval(realTagStr);
        }
    } catch (error) {
        return 1;
    }
    return 1;
};
/**
 *  计算 tag字符串的pfd值
 */
const getTagStrPFD = (state, current_pfd) => {
    if (state === 0) {
        // 工作的tag 计算真实pfd
        return current_pfd || 1;
    } else {
        // 不工作的tag
        return 1;
    }
};
/**
 *  根据短信发送状态 返回短信状态描述
 */
const formatSms_state = (sms_state) => {
    if (sms_state === 0)
        return '无负责人';
    if (sms_state === 1)
        return '发送成功';
    if (sms_state === 2)
        return '发送失败';
};
/**
 *  根据事件类型 返回事件类型描述
 */
const formatAlertState = (alertState) => {
    // *  事件类型 0：未知  1：保护层触发 2：保护层失效 3：保护层tag离线  4：保护层tag缺失  5：起始事件触发  6：起始事件tag离线  7:起始事件tag缺失 8：设计错误-保护层未正确触发 9：设计错误-保护层保护时间不足  10：保护层高风险
    const alertStateList = [
        '未知',
        '保护层触发',
        '保护层失效',
        '保护层tag离线',
        '保护层tag缺失',
        '起始事件触发',
        '起始事件tag离线',
        '起始事件tag缺失',
        '设计错误-保护层未正确触发',
        '设计错误-保护层保护时间不足',
        '节点高风险',
    ];
    return alertStateList[alertState];
};
/**
 *  计算保护层组的pfd值
 */
const getBarrierListPDF = (barrierList) => {
    let pfd_init = 1;
    barrierList.forEach((b) => {
        pfd_init = getTagStrPFD(b.bypass_state, b.current_pfd) * pfd_init;
    });
    return pfd_init;
};
/**
 *  删选掉不参与计算 的保护层
 */
const getHandleBarrierList = (barrierList = [], exist_type) => {
    // 筛选一下存续类型
    return barrierList === null || barrierList === void 0 ? void 0 : barrierList.filter((b) => {
        if (exist_type === 'exit') {
            return b.exist_type === 1;
        } else {
            return true;
        }
    });
};
/**
 *  获取节点各个情况的概率
 */
const getNodePfdInfo = (nodeInfo, exist_type = 'all') => {
    let _a;
    const dangerChainList = [];
    (_a = nodeInfo.streamList) === null || _a === void 0 ? void 0 : _a.forEach((stream, si) => {
        let _a;
        // 遍历起始事件
        (_a = stream.startEventList) === null || _a === void 0 ? void 0 : _a.forEach((init, ii) => {
            let _a;
            const handelInitBarrierList = getHandleBarrierList(init.barrierList, exist_type);
            const initPFD = getBarrierListPDF(handelInitBarrierList) * init.current_pfd;
            // 遍历后果
            (_a = stream.resultList) === null || _a === void 0 ? void 0 : _a.forEach((result, ri) => {
                let _a, _b;
                const handelResultBarrierList = getHandleBarrierList(result.barrierList, exist_type);
                const resultPfd = getBarrierListPDF(handelResultBarrierList);
                // 最终pfd值
                const pfdGrade = exchangePfdGrade(initPFD * resultPfd);
                const severity = severityList.find((s) => s.severity_id === result.severity_id);
                if ((pfdGrade === null || pfdGrade === void 0 ? void 0 : pfdGrade.value) && (severity === null || severity === void 0 ? void 0 : severity.value)) {
                    const riskGrade = exchangeRiskGrade(severity?.value, pfdGrade?.value);
                    if (riskGrade) {
                        dangerChainList.push({
                            streamIndex: si,
                            initiatotIndex: ii,
                            initiatotBarrierCount: handelInitBarrierList.length || 0,
                            startEventCount: ((_a = stream.startEventList) === null || _a === void 0 ? void 0 : _a.length) || 0,
                            resultIndex: ri,
                            resultCount: ((_b = stream.resultList) === null || _b === void 0 ? void 0 : _b.length) || 0,
                            resultBarrierCount: handelResultBarrierList.length || 0,
                            riskGrade,
                        });
                    }
                }
            });
        });
    });
    return dangerChainList;
};
/**
 *  报告相关
 */
const getTotalTime = (alert) => {
    const date1 = alert.end_time ? dayjs(alert.end_time) : dayjs();
    const date2 = alert.start_time ? dayjs(alert.start_time) : dayjs();
    const diff = date1.diff(date2, 'minute', true);
    return diff ? parseFloat(diff.toFixed(1)) : 0;
};
const formatAlertEvent = (r) => {
    // dayjs.extend(utc);
    return Object.assign(Object.assign({}, r), {
        risk_value: `等级${r.risk_value}`,
        start_time: r.start_time ? dayjs(r.start_time).format('YYYY-MM-DD HH:mm:ss') : '--',
        end_time: r.end_time ? dayjs(r.end_time).format('YYYY-MM-DD HH:mm:ss') : '--',
        create_time: r.create_time ? dayjs(r.create_time).format('YYYY-MM-DD HH:mm:ss') : '--',
        useTime: getTotalTime(r),
        sms_state: formatSms_state(r.sms_state),
        event_type: formatAlertState(r.event_type)
    });
};
export {
    severityList,
    pfdList,
    riskList,
    riskRoleList,
    topEventTypeList,
    startEventTypeList,
    deviationTypeList,
    barrierWorkTypeList,
    departmentList,
    getTopEventType,
    getStartEventType,
    getDeviationType,
    getBarrierWorkType,
    getSeverity,
    getPfd,
    getRisk,
    getRiskRole,
    getConfig,
    tagStrTrust,
    loadGradeList,
    loadOptionList,
    loadDepartmentList,
    getTagPfdResult,
    getNodesTags,
    expStr,
    checkTagWorking,
    getNodePfdInfo,
    exchangeRiskGrade,
    exchangePfdGrade,
    loadSetting,
    formatSms_state,
    formatAlertState,
    formatAlertEvent,
};
