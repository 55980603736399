// noinspection JSUnusedLocalSymbols,JSUnusedGlobalSymbols,JSValidateTypes,JSCheckFunctionSignatures,JSUnresolvedVariable,ES6CheckImport,SpellCheckingInspection,PointlessBooleanExpressionJS

// page:节点详情
import { Breadcrumb, Button, Col, Radio, Row } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { request } from '../../util';
import Chart from './chart';
import './index.scss';
import Table from './table';
import { inject, observer } from 'mobx-react';
import publicData from '../../util/publicData';
import { BrowserView, MobileView } from 'react-device-detect';
import config from '../../config';
import log from 'loglevel';

log.setLevel(config.debug ? log.levels.DEBUG : log.levels.ERROR);
const ExcelJS = require('exceljs');
const ChartInfo = (props) => {
    let _a, _b, _c;
    const navigate = useNavigate();
    const node_id = useParams().node_id;
    const [nodeInfo, setNodeInfo] = useState();
    const [type, setType] = useState('chart');
    const [updateTime, setUpdateTime] = useState(dayjs().format('YYYY-MM-DD HH:mm:ss'));
    const [linkState, setLinkState] = useState(true);
    // =========   定时刷新  ========
    const refreshTimer = parseInt(((_c = (_b = (_a = props.publicData) === null || _a === void 0 ? void 0 : _a.optionList.configData) === null || _b === void 0 ? void 0 : _b.find((c) => c.keyword === 'refreshInterval')) === null || _c === void 0 ? void 0 : _c.value) || '5000');
    const queryNodeInfo = async () => {
        try {
            const res = await request.post('/company/queryNodeInfo', { node_id });
            setNodeInfo(res);
            setUpdateTime(dayjs().format('YYYY-MM-DD HH:mm:ss'));
            setLinkState(true);
        } catch (err) {
            console.error(err);
            setLinkState(false);
        };
    };
    useEffect(() => {
        queryNodeInfo();
        const interval = setInterval(queryNodeInfo, refreshTimer);
        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // 跳转编辑
    const gotoEdit = () => {
        if (nodeInfo === null || nodeInfo === void 0 ? void 0 : nodeInfo.node_id)
            navigate(`/home/edit-chart/${nodeInfo === null || nodeInfo === void 0 ? void 0 : nodeInfo.node_id}`);
    };
    // 跳转修改记录
    const gotoNodeRecord = () => {
        if (nodeInfo === null || nodeInfo === void 0 ? void 0 : nodeInfo.node_id)
            navigate(`/home/chart-record/${nodeInfo === null || nodeInfo === void 0 ? void 0 : nodeInfo.node_id}`);
    };
    // 下载表格
    const downloadExcel = async (sheetName) => {
        const workbook = new ExcelJS.Workbook();
        //
        workbook.creator = '危可视';
        workbook.lastModifiedBy = '危可视';
        // 将工作簿日期设置为 1904 年日期系统
        workbook.properties.date1904 = true;
        const sheet = workbook.addWorksheet(sheetName || 'My Sheet');
        sheet.properties.defaultRowHeight = 25;
        sheet.properties.defaultColWidth = 100;
        sheet.columns = [
            {
                header: '顶级事件',
                key: 'top_event_type',
                width: 15,
                style: { alignment: { vertical: 'middle', horizontal: 'center' } }
            },
            {
                header: '后果',
                key: 'result_info',
                width: 40,
                style: { alignment: { vertical: 'middle', horizontal: 'center' } }
            },
            {
                header: '',
                key: 'result_severity',
                width: 15,
                style: { alignment: { vertical: 'middle', horizontal: 'center' } }
            },
            {
                header: '偏差',
                key: 'deviation_type',
                width: 15,
                style: { alignment: { vertical: 'middle', horizontal: 'center' } }
            },
            {
                header: '起始事件',
                key: 'start_info',
                width: 40,
                style: { alignment: { vertical: 'middle', horizontal: 'center' } }
            },
            {
                header: '',
                key: 'start_touch_tags',
                width: 100,
                style: { alignment: { vertical: 'middle', horizontal: 'center' } }
            },
            {
                header: '保护层',
                key: 'barrier_info',
                width: 60,
                style: { alignment: { vertical: 'middle', horizontal: 'center' } }
            },
            {
                header: '',
                key: 'barrier_touch_tags',
                width: 100,
                style: { alignment: { vertical: 'middle', horizontal: 'center' } }
            },
            {
                header: '',
                key: 'barrier_bypass_tags',
                width: 100,
                style: { alignment: { vertical: 'middle', horizontal: 'center' } }
            },
            {
                header: '',
                key: 'barrier_work_type',
                width: 40,
                style: { alignment: { vertical: 'middle', horizontal: 'center' } }
            },
            { header: '', key: 'current_pfd', width: 15, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
            { header: '', key: 'init_pfd', width: 15, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
            { header: '可能性', key: 'pfdGrade', width: 15, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
            {
                header: '风险等级',
                key: 'riskGrade',
                width: 15,
                style: { alignment: { vertical: 'middle', horizontal: 'center' } }
            },
            {
                header: '建议额外措施',
                key: 'unExit_barrier_info',
                width: 15,
                style: { alignment: { vertical: 'middle', horizontal: 'center' } }
            },
            {
                header: '',
                key: 'unExit_barrier_work_type',
                width: 15,
                style: { alignment: { vertical: 'middle', horizontal: 'center' } }
            },
            {
                header: '',
                key: 'unExit_barrier_pfd',
                width: 15,
                style: { alignment: { vertical: 'middle', horizontal: 'center' } }
            },
            {
                header: '',
                key: 'unExit_barrier_type',
                width: 15,
                style: { alignment: { vertical: 'middle', horizontal: 'center' } }
            },
            {
                header: '',
                key: 'unExit_pfdGrade',
                width: 15,
                style: { alignment: { vertical: 'middle', horizontal: 'center' } }
            },
            {
                header: '',
                key: 'unExit_riskGrade',
                width: 15,
                style: { alignment: { vertical: 'middle', horizontal: 'center' } }
            },
        ];
        // 第二行列名
        sheet.addRow([
            '',
            '描述',
            '等级',
            '',
            '描述',
            '触发逻辑',
            '描述',
            '触发逻辑',
            '失效逻辑',
            '种类',
            '当前PFD',
            '初始PFD',
            '',
            '',
            '描述',
            '种类',
            'PFD',
            '类别',
            '可能性',
            '风险等级', //建议额外措施
        ]);
        // 合并列名
        sheet.mergeCells('A1:A2');
        sheet.mergeCells('B1:C1');
        sheet.mergeCells('D1:D2');
        sheet.mergeCells('E1:F1');
        sheet.mergeCells('G1:L1');
        sheet.mergeCells('M1:M2');
        sheet.mergeCells('N1:N2');
        sheet.mergeCells('O1:T1');
        // 设置数据行
        if (nodeInfo) {
            const cellList = [];
            const mergeCellList = [];
            nodeInfo.streamList.forEach((stream, si) => {
                const streamCode = ['A'];
                const streamStartPoint = cellList.length + 3;
                stream.resultList.forEach((result, ri) => {
                    const resultCodes = ['B', 'C'];
                    const resultStartPoint = cellList.length + 3;
                    stream.startEventList.forEach((start, ai) => {
                        let _a, _b, _c, _d;
                        const initCodes = ['D', 'E', 'F', 'M', 'N', 'S', 'T'];
                        const initStartPoint = cellList.length + 3;
                        const allBarrier = [...(start.barrierList || []), ...(result.barrierList || [])];
                        const exitBarrierList = allBarrier.filter((b) => b.exist_type === 1);
                        const unExitBarrierList = allBarrier.filter((b) => b.exist_type !== 1);
                        // eslint-disable-next-line no-eval
                        const exitTotalPfd = eval([...exitBarrierList.map((b) => b.current_pfd || 1), start.current_pfd || 1].join('*'));
                        const exitPfdGrade = (_a = props.publicData) === null || _a === void 0 ? void 0 : _a.exchangePfdGrade(exitTotalPfd);
                        const severity = publicData.getSeverity(result.severity_id);
                        const exitRiskGrade = (_b = props.publicData) === null || _b === void 0 ? void 0 : _b.exchangeRiskGrade((severity === null || severity === void 0 ? void 0 : severity.value) || 5, (exitPfdGrade === null || exitPfdGrade === void 0 ? void 0 : exitPfdGrade.value) || 1);
                        // eslint-disable-next-line no-eval
                        const unExitTotalPfd = eval([...allBarrier.map((b) => b.current_pfd || 1), start.current_pfd || 1].join('*'));
                        const unExitPfdGrade = (_c = props.publicData) === null || _c === void 0 ? void 0 : _c.exchangePfdGrade(unExitTotalPfd);
                        const unExitRiskGrade = (_d = props.publicData) === null || _d === void 0 ? void 0 : _d.exchangeRiskGrade((severity === null || severity === void 0 ? void 0 : severity.value) || 5, (unExitPfdGrade === null || unExitPfdGrade === void 0 ? void 0 : unExitPfdGrade.value) || 1);
                        // noinspection PointlessBooleanExpressionJS
                        exitBarrierList.forEach((barrier, bi) => {
                            let _a, _b, _c, _d;
                            const unExitBarrier = unExitBarrierList[bi];
                            const cell = {
                                top_event_type: (_a = publicData.getTopEventType(stream.top_event_type_id)) === null || _a === void 0 ? void 0 : _a.label,
                                result_info: result.info,
                                result_severity: severity === null || severity === void 0 ? void 0 : severity.label,
                                deviation_type: (_b = publicData.getDeviationType(start.deviation_type_id)) === null || _b === void 0 ? void 0 : _b.label,
                                start_info: start.info,
                                start_touch_tags: start.touchTags,
                                barrier_info: barrier.info,
                                barrier_touch_tags: barrier.touchTags,
                                barrier_bypass_tags: barrier.bypassTags,
                                barrier_work_type: (_c = publicData.getBarrierWorkType(barrier.barrier_work_type_id)) === null || _c === void 0 ? void 0 : _c.label,
                                current_pfd: barrier.current_pfd,
                                init_pfd: barrier.init_pfd,
                                pfdGrade: exitPfdGrade === null || exitPfdGrade === void 0 ? void 0 : exitPfdGrade.label,
                                riskGrade: exitRiskGrade === null || exitRiskGrade === void 0 ? void 0 : exitRiskGrade.label,
                                unExit_barrier_info: unExitBarrier === null || unExitBarrier === void 0 ? void 0 : unExitBarrier.info,
                                unExit_barrier_work_type: unExitBarrier && ((_d = publicData.getBarrierWorkType(unExitBarrier.barrier_work_type_id)) === null || _d === void 0 ? void 0 : _d.label),
                                unExit_barrier_pfd: unExitBarrier === null || unExitBarrier === void 0 ? void 0 : unExitBarrier.current_pfd,
                                unExit_barrier_type: unExitBarrier && ((unExitBarrier === null || unExitBarrier === void 0 ? void 0 : unExitBarrier.owner_result_id) ? '补救型' : '预防型'),
                                unExit_pfdGrade: unExitPfdGrade === null || unExitPfdGrade === void 0 ? void 0 : unExitPfdGrade.label,
                                unExit_riskGrade: unExitRiskGrade === null || unExitRiskGrade === void 0 ? void 0 : unExitRiskGrade.label,
                            };
                            cellList.push(cell);
                        });
                        const initEndPoint = cellList.length + 2;
                        if (initStartPoint < initEndPoint)
                            mergeCellList.push(...initCodes.map((code) => `${code}${initStartPoint}:${code}${initEndPoint}`));
                    });
                    const resultEndPoint = cellList.length + 2;
                    if (resultStartPoint < resultEndPoint)
                        mergeCellList.push(...resultCodes.map((code) => `${code}${resultStartPoint}:${code}${resultEndPoint}`));
                });
                const streamEndPoint = cellList.length + 2;
                if (streamStartPoint < streamEndPoint)
                    mergeCellList.push(...streamCode.map((code) => `${code}${streamStartPoint}:${code}${streamEndPoint}`));
            });
            // 设置数据
            sheet.addRows(cellList);
            // 合并数据行
            mergeCellList.forEach((role) => {
                log.debug('合并数据行', role);
                sheet.mergeCells(role);
            });
        }
        // 下载文件
        const buffer = await workbook.xlsx.writeBuffer();
        let blob = new Blob([buffer]);
        let blobUrl = window.URL.createObjectURL(blob);
        const aElement = document.createElement('a');
        let filename = `${(nodeInfo === null || nodeInfo === void 0 ? void 0 : nodeInfo.name) || '节点'}.xlsx`; // 'AA.xlsx';//设置文件名称
        aElement.href = blobUrl; //设置a标签路径
        aElement.download = filename;
        aElement.click();
        window.URL.revokeObjectURL(blobUrl);
    };
    return (
        <div>
            <div className="chartInfo-content">
                {/* part:导航栏 */}
                <Breadcrumb>
                    <Breadcrumb.Item>节点管理</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a href="#/home/charts">节点列表</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>节点详情</Breadcrumb.Item>
                </Breadcrumb>
                <div className="com-white-block">
                    <Row align={'middle'} justify={'space-between'}>
                        <Col>
                            <Row align={'middle'}>
                                <div className="font-size-xxl font-weight-bold com-align_center">
                                    {nodeInfo?.name} (<span
                                        className="ml-5 text-color-second">更新时间:{updateTime}</span> )
                                </div>
                                <div
                                    style={{
                                        width: '50px',
                                        height: '20px',
                                        backgroundColor: linkState ? '#4cd964' : '#FF2E20',
                                        marginLeft: 20,
                                        color: '#fff',
                                        textAlign: 'center',
                                    }}>
                                    {linkState ? '正常' : '异常'}
                                </div>
                            </Row>
                        </Col>
                        <Col>
                            <Row justify={'end'} align={'middle'}>
                                <Button
                                    size={'small'}
                                    className="ml-base com-btn com-btn-block green hide-on-mobile"
                                    onClick={gotoEdit}
                                    shape="default">
                                    去编辑
                                </Button>
                                <Button
                                    size={'small'}
                                    className="ml-base com-btn com-btn-block yellow hide-on-mobile"
                                    shape="default"
                                    onClick={gotoNodeRecord}>
                                    修改记录
                                </Button>
                                <Button
                                    size={'small'}
                                    className="ml-base com-btn com-btn-block"
                                    shape="default"
                                    onClick={() => downloadExcel(nodeInfo?.name || 'My Sheet')}>
                                    下载表格
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-lg text-color-second" justify={'space-around'}>
                        <Col span={6}>所属部门:{props.publicData?.getDepartment(nodeInfo?.department_id)?.name} </Col>
                        <Col span={6}>编号:{nodeInfo?.number} </Col>
                        <Col span={6}>P&ID图号:{nodeInfo?.pid}</Col>
                        <Col span={6}>创建时间:{dayjs(nodeInfo?.create_time).format('YYYY-MM-DD HH:mm')}</Col>
                    </Row>
                    <Row className="mt-base text-color-second">
                        <Col span={12}>意图:{nodeInfo?.intent}</Col>
                        <Col span={12}>危害:{nodeInfo?.harm}</Col>
                    </Row>
                </div>
                <Row justify={'center'} className="mt-10">
                    <Radio.Group
                        size={'middle'}
                        className="element-tab-radio"
                        defaultValue={type}
                        buttonStyle="solid"
                        onChange={(v) => setType(v.target.value)}>
                        <Radio.Button className="radio_button" value="table">
                            节点表格
                        </Radio.Button>
                        <Radio.Button className="radio_button hide-on-mobile" value="chart">
                            领结图
                        </Radio.Button>
                    </Radio.Group>
                </Row>
                <BrowserView>
                    {type === 'table' ?
                        <Table nodeInfo={nodeInfo} key="Table" /> :
                        <Chart nodeInfo={nodeInfo} key="Chart" />
                    }
                </BrowserView>
                <MobileView>
                    <Table nodeInfo={nodeInfo} key="Table" />
                </MobileView>
            </div>
        </div>
    );
};
export default inject('publicData')(observer(ChartInfo));
