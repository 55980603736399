import { Tabs } from 'antd';
import React from 'react';
import CompanyInfo from './companyInfo';
import PayRecord from './payRecord';
import './index.scss';
// page:企业信息
const CompanyPage = () => {
    return (
        <div className="company-page">
            <Tabs defaultActiveKey="info" tabBarStyle={{ paddingLeft: '20px' }} className='tabs-view'>
                <Tabs.TabPane tab="账户信息" key="info">
                    <CompanyInfo />
                </Tabs.TabPane>
                <Tabs.TabPane tab="缴费记录" key="record" >
                    <PayRecord />
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
};
export default CompanyPage;
