import { Popover } from 'antd';
import React from 'react';
/**
 *  在组件上套一层pop
 */
export const createPopoverItem = (element, content, placement = 'topLeft') => {
    return (
        <Popover
            key={content?.join()}
            trigger="hover"
            placement={placement}
            content={
                <div>
                    {content?.map((text) => (
                        <p key={text}>{text}</p>
                    ))}
                </div>
            }>
            {element}
        </Popover>
    );
};
