import {Button, Col, message, Row, Select, Space} from 'antd';
import {inject, observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import {request} from '../../util';
// page:参数设置
const TriggerSetting = (props) => {
    useEffect(() => {
        getTriggerLogic();
    }, []);
    const [logic, setLogic] = useState({bypass: 0, touch: 0});
    const getTriggerLogic = () => {
        request.post('/company/getTriggerLogic', {}, true).then((res) => {
            setLogic(res);
        });
    };
    const bypassOptions = [
        {label: '0是正常，1是失效', value: 0},
        {label: '0是失效，1是正常', value: 1},
    ];
    const touchOptions = [
        {label: '0是正常，1是触发', value: 0},
        {label: '0是触发，1是正常', value: 1},
    ];
    /**
     *  处理参数改变
     */
    const bypassChange = (val) => {
        setLogic({bypass: val, touch: logic.touch});
    };
    const touchChange = (val) => {
        setLogic({bypass: logic.bypass, touch: val});
    };
    const saveAction = () => {
        request.post('/company/updateTriggerLogic', {...logic}, true).then((res) => {
            console.log(res);
            message.success('更新成功');
        });
    };
    return (
        <div className="setting-item">
            <Row className="mt-lg parameter-row" align={'middle'} justify={'center'}>
                <Col>
                    <span className="font-weight-bold">失效逻辑:</span>
                </Col>
                <Col>
                    <Select
                        className="select risk-input"
                        style={{width: 180}}
                        bordered={false}
                        placeholder="未设置"
                        options={bypassOptions}
                        value={logic.bypass}
                        onChange={(val) => bypassChange(val)}
                    ></Select>
                </Col>
            </Row>
            <Row className="mt-lg parameter-row" align={'middle'} justify={'center'}>
                <Col>
                    <span className="font-weight-bold">触发逻辑:</span>
                </Col>
                <Col>
                    <Select
                        className="select risk-input"
                        style={{width: 180}}
                        bordered={false}
                        placeholder="未设置"
                        options={touchOptions}
                        value={logic.touch}
                        onChange={(val) => touchChange(val)}
                    ></Select>
                </Col>
            </Row>

            <Row justify={'center'} align={'middle'} className="mt-lg">
                <Space size={'middle'}>
                    <Button size="middle" shape="default" className="com-btn com-btn-block green" onClick={saveAction}>
                        提交
                    </Button>
                </Space>
            </Row>
        </div>
    );
};
export default inject('publicData')(observer(TriggerSetting));
