import { Badge, Breadcrumb, Button, Col, message, Row, Space } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { request } from '../../util';
// page：消息
const NoticePage = () => {
    const navigate = useNavigate();
    const [noticeList, setNoticeList] = useState([]);
    useEffect(() => {
        queryNoticeList();
    }, []);
    /**
     *  查询公告
     */
    const queryNoticeList = () => {
        request.post('/company/queryNoticeList', {}, true).then((res) => {
            setNoticeList(res.noticeList);
        });
    };
    /**
     *  设为已读
     */
    const readNotice = (notice_id) => {
        request.post('/company/readNotice', { notice_id }, true).then((res) => {
            queryNoticeList();
        });
    };
    /**
     *  全部已读
     */
    const readNoticeList = () => {
        const notice_ids = noticeList.filter((notice) => notice.state === 0).map((notice) => notice.notice_id);
        if (notice_ids.length === 0) {
            message.warning('没有未读的公告');
            return;
        }
        request.post('/company/allNoticeRead', { notice_ids }, true).then((res) => {
            queryNoticeList();
        });
    };
    return (
        <div className="p-lg">
            {/* part:导航栏 */}
            <Breadcrumb>
                <Breadcrumb.Item>平台管理</Breadcrumb.Item>
                <Breadcrumb.Item>消息</Breadcrumb.Item>
            </Breadcrumb>
            {/* part:内容 */}
            <Row justify={'end'}>
                <Button shape="default" size="middle" type="primary" className="com-btn com-btn-block green mb-10" onClick={readNoticeList}>
                    全部已读
                </Button>
            </Row>

            {noticeList.map((notice) => {
                return (
                    <div className="com-white-block mt-5">
                        <Badge dot={notice.state === 0}>
                            <div>标题：{notice.title}</div>
                        </Badge>
                        <div className="mt-base">
                            消息内容：<span>{notice.content}</span>
                        </div>
                        <Row className="mt-lg com-border-dashed-top pt-base" justify={'space-between'} align={'middle'}>
                            <Col className="font-size-sm text-color-light mt-5" md={20} sm={24} xs={24}>
                                <Space size="middle" className="wrap">
                  <span>
                    创建时间：<span>{dayjs(notice.create_time).format('YYYY-MM-DD HH:mm')}</span>
                  </span>
                                    <span>{!!notice.reader_name && <span>阅读人:{notice.reader_name}</span>}</span>
                                    <span>{!!notice.read_time && <span>阅读时间:{dayjs(notice.read_time).format('YYYY-MM-DD HH:mm')}</span>}</span>
                                </Space>
                            </Col>
                            <Col md={4} sm={24} xs={24} className="com-align_right mt-5">
                                <Space size="middle">
                                    {notice.state === 0 && (
                                        <Button className="com-btn com-btn-block green" type="default" size={'small'} shape="default" onClick={readNotice.bind(undefined, notice.notice_id)}>
                                            设为已读
                                        </Button>
                                    )}
                                    <Button type="primary" size={'small'} shape="default" onClick={() => navigate( `/home/notice-detail/${notice.notice_id}`)}>
                                        查看详情
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </div>
                );
            })}
        </div>
    );
};
export default NoticePage;
