/* eslint-disable no-unused-vars */
import {Image, Popover} from 'antd';
import React, {useEffect, useState} from 'react';
import './pubChart.scss';
// import config from '../../config';
// import {FullScreen, useFullScreenHandle} from 'react-full-screen';
// import {ArrowsAltOutlined} from '@ant-design/icons';

const PubChart = (props) => {
  const {nodeInfo} = props;
  const [type, setType] = useState('exit');
  const [dangerChainList, setDangerChainList] = useState([]);
  const barrierWorkStateStrList = ['正常', '触发', '失效', '未知', '缺少tag'];
  const barrierWorkStateColorList = ['#4cd964', '#1059FA', '#dd524d', '#f0ad4e', '#f0ad4e'];

  const [nodeRisk, setNodeRisk] = useState();

  useEffect(() => {
    const r = props.publicData?.optionList.riskList?.find((risk) => risk.value === nodeInfo?.risk_value);
    if (r) {
      setNodeRisk(r);
    }
    // eslint-disable-next-line
  }, [nodeInfo]);
  const riskAlertValue = props.publicData?.getRiskAlertValue();

  // 计算各条线的概率
  useEffect(() => {
    if (!nodeInfo) return;
    const dangerChainList = [];
    const calculatePFD = (barrierList, type) => {
      let barrierPFD = 1;
      const filteredBarrierList = barrierList.filter((b) => type === 'all' || b.exist_type === 1);
      if (filteredBarrierList.length > 0) {
        barrierPFD = filteredBarrierList.map((b) => (b.bypass_state === 2 ? 1 : b.current_pfd || 1)).reduce((b1, b2) => b1 * b2);
      }
      return barrierPFD;
    };

    const calculateTotalPFD = (initPFD, initBarrierPFD, resultBarrierPFD) => {
      let totalPFD = initPFD * initBarrierPFD * resultBarrierPFD;
      if (totalPFD > 1) totalPFD = 1;
      if (totalPFD < 0) totalPFD = 0;
      return totalPFD;
    };

    for (let si = 0; si < nodeInfo.streamList.length; si++) {
      const stream = nodeInfo.streamList[si];
      for (let ei = 0; ei < stream.startEventList.length; ei++) {
        const startEvent = stream.startEventList[ei];
        const initPFD = startEvent.touch_state === 1 ? 1 : startEvent.current_pfd;
        const initBarrierPFD = calculatePFD(startEvent.barrierList, type);
        for (let ri = 0; ri < stream.resultList.length; ri++) {
          const result = stream.resultList[ri];
          const resultBarrierPFD = calculatePFD(result.barrierList, type);
          const totalPFD = calculateTotalPFD(initPFD, initBarrierPFD, resultBarrierPFD);
          const pfd_value = props.publicData?.optionList.pfdList?.find((grade) => totalPFD >= grade.min_pfd && totalPFD <= grade.max_pfd)?.value || 1;
          const severity_value = props.publicData?.optionList.severityList?.find((se) => se.severity_id === result.severity_id)?.value || 1;
          const risk_value =
            props.publicData?.optionList.riskRoleList?.find((role) => role.pfd_value === pfd_value && role.severity_value === severity_value)
              ?.risk_value || 1;
          const riskGrade = props.publicData?.optionList.riskList?.find((risk) => risk.value === risk_value);
          if (riskGrade) {
            dangerChainList.push({
              streamIndex: si,
              initiatotIndex: ei,
              initiatotBarrierCount: startEvent.barrierList?.length || 0,
              startEventCount: stream.startEventList?.length || 0,
              resultIndex: ri,
              resultCount: stream.resultList?.length || 0,
              resultBarrierCount: result.barrierList?.length || 0,
              riskGrade,
            });
          }
        }
      }
    }
    const dangerChainListEnd = dangerChainList.sort((line1, line2) => (line1.riskGrade.value || 1) - (line2.riskGrade.value || 1));
    setDangerChainList(dangerChainListEnd);
  }, [nodeInfo, props.publicData, type]);
  //  Popover
  const createPopoverItem = (element, content, placement = 'topLeft') => {
    return (
      <Popover
        key={content?.join()}
        trigger="hover"
        placement={placement}
        content={
          <div>
            {content?.map((text, i) => (
              <p key={i} dangerouslySetInnerHTML={{__html: text}}></p>
            ))}
          </div>
        }>
        {element}
      </Popover>
    );
  };
  // 高亮触发逻辑
  function highlightTags(tagStr, triggerTags) {
    let res = tagStr;
    for (const tag of triggerTags) {
      res = res.replace(tag, `<span style="color:red">${tag}</span>`);
    }
    return res;
  }
  // 高亮未知Tag(tag.quality !== 192)
  function highlightTagsState3(tagStr) {
    let res = tagStr;
    const rule = /【.*?】/g;
    const matches = tagStr.match(rule);
    const tags = matches ? matches.map((r) => r.replace(/[【】]/g, '').trim()) : [];
    const process = props.publicData?.optionList.processList || [];
    for (const tag of tags) {
      const p = process.find((r) => r.label === tag);
      if (p && p.quality !== 192) {
        res = res.replace(tag, `<span style="color:#f0ad4e">${tag}</span>`);
      }
    }
    return res;
  }
  // 保护措施
  const loadBarrierItem = (barrier) => {
    let _a, _b, _c;
    if (barrier.exist_type === 0 && type === 'exit') {
      return;
    }
    const workType = (_a = props.publicData) === null || _a === void 0 ? void 0 : _a.getBarrierWorkType(barrier.barrier_work_type_id);
    const barrierPopInfo = [];
    barrierPopInfo.push(`序列号:${barrier.barrier_id}`);
    barrierPopInfo.push(`名称:${barrier ? barrier.info : ''}`);
    barrierPopInfo.push(`当前PFD:${barrier.current_pfd || ''}`);
    barrierPopInfo.push(`PFD逻辑:${barrier.pfdTags}`);
    let linkImage =
      barrier.bypass_state === 0
        ? 'https://safety-chart.oss-cn-beijing.aliyuncs.com/image/project/barrierLink.png'
        : 'https://safety-chart.oss-cn-beijing.aliyuncs.com/image/project/barrierOff.png';
    let touchTags = barrier.touchTags;
    let bypassTags = barrier.bypassTags;
    if (barrier.touch_state === 1) {
      touchTags = highlightTags(touchTags, barrier.triggerTags || []);
    } else if (barrier.bypass_state === 2) {
      bypassTags = highlightTags(bypassTags, barrier.triggerTags || []);
    }
    const state = barrier.bypass_state || barrier.touch_state;
    if (state === 3) {
      touchTags = highlightTagsState3(touchTags);
      bypassTags = highlightTagsState3(bypassTags);
    }
    let workStateStr = barrierWorkStateStrList[state];
    let workStateBGColor = barrierWorkStateColorList[state];
    barrierPopInfo.push(`触发逻辑:${touchTags}`);
    barrierPopInfo.push(`失效逻辑:${bypassTags}`);
    barrierPopInfo.push(`保护时间:${barrier.reaction_time}秒`);
    barrierPopInfo.push(`短信通知:${barrier.flag === 0 ? '是' : '否'}`);
    const barrierComponent = (
      <div className="barrier-view" key={barrier.barrier_id}>
        <Image src={linkImage} width="12px" height="28px" style={{alignSelf: 'center'}} />
        <div className="barrier-item" style={{borderStyle: barrier.exist_type === 1 ? 'solid' : 'dashed'}}>
          <div className="barrier-info">
            <span>{workType?.label}</span>
          </div>
          <div className="barrier-state" style={{backgroundColor: workStateBGColor}}>
            <span>{workStateStr}</span>
          </div>
        </div>
      </div>
    );
    return createPopoverItem(barrierComponent, barrierPopInfo, 'top');
    // }
  };
  // 起始事件
  const loadInitiatotItem = (initiatot, index) => {
    const initPopInfo = [];
    let touchTags = initiatot.touchTags;
    if (initiatot.touch_state === 1) {
      touchTags = highlightTags(touchTags, initiatot.triggerTags || []);
    }
    initPopInfo.push(`名称:${initiatot.info}`);
    initPopInfo.push(`当前PFD:${initiatot.current_pfd}`);
    initPopInfo.push(`PFD逻辑:${initiatot.pfdTags}`);
    initPopInfo.push(`触发逻辑:${touchTags}`);
    if (initiatot.pfd_info) {
      initPopInfo.push(JSON.parse(initiatot.pfd_info || '').join(' '));
    }
    let initiatotBGImage =
      initiatot.touch_state === 0
        ? 'https://safety-chart.oss-cn-beijing.aliyuncs.com/image/project/working.png'
        : 'https://safety-chart.oss-cn-beijing.aliyuncs.com/image/project/noWorking.png';
    let workStateStr = barrierWorkStateStrList[initiatot.touch_state || 0];
    const initiatotItem = (
      <div className="initiatot-item" style={{backgroundImage: `url(${initiatotBGImage})`}}>
        <div className="initiatot-info">
          <span>{props.publicData?.getStartEventType(initiatot.start_event_type_id)?.label}</span>
          <span>{props.publicData?.getDeviationType(initiatot.deviation_type_id)?.label}</span>
        </div>
        <span className="initiatot-type">{workStateStr}</span>
      </div>
    );
    return (
      <div className="initiatot-view" key={initPopInfo.join()}>
        {/* 起始事件 */}
        {createPopoverItem(initiatotItem, initPopInfo, 'topLeft')}
        {/* 保护层 */}
        {initiatot.barrierList?.map(loadBarrierItem)}
      </div>
    );
  };
  /**
   *  后果那一行
   */
  const loadResultItem = (result, index) => {
    const resultPopInfo = [];
    if (result.info) {
      resultPopInfo.push(`名称:${result.info}`);
    }
    const severityLabel = props.publicData?.getSeverity(result.severity_id)?.label;
    if (severityLabel) {
      resultPopInfo.push(`等级:${severityLabel}`);
    }
    const resultItem = (
      <div className="result-item" key={result.result_id}>
        <div className="result-info">
          <span>{result.info}</span>
        </div>
        <div className="risk-view">
          {props.publicData?.optionList.severityList?.map((ser) => (
            <div className={`risk-item ${ser.severity_id === result.severity_id ? 'risk-select' : 'risk-normal'}`} key={ser.severity_id}>
              <span>{ser.label}</span>
            </div>
          ))}
        </div>
      </div>
    );
    return (
      <div className="result-view" key={resultPopInfo.join()}>
        {/* 保护层 */}
        {result.barrierList?.map(loadBarrierItem)}
        {/* 后果 */}
        {createPopoverItem(resultItem, resultPopInfo, 'topRight')}
      </div>
    );
  };
  const loadSVGView = (stream, sIndex) => {
    const initiatot_group_doc = document.getElementById(`initiatot_group_${sIndex}`);
    const top_event_doc = document.getElementById(`top_event_${sIndex}`);
    const result_group_doc = document.getElementById(`result_group_${sIndex}`);
    if (!initiatot_group_doc || !top_event_doc || !result_group_doc) return;
    const scale = props.scale;
    const _initRect = initiatot_group_doc.getBoundingClientRect();
    const initRect = {
      x: _initRect.x / scale,
      y: _initRect.y / scale,
      width: _initRect.width / scale,
      height: _initRect.height / scale,
    };
    const _topEventRect = top_event_doc.getBoundingClientRect();
    const topEventRect = {
      x: _topEventRect.x / scale,
      y: _topEventRect.y / scale,
      width: _topEventRect.width / scale,
      height: _topEventRect.height / scale,
    };
    const _resulrRect = result_group_doc.getBoundingClientRect();
    const resulrRect = {
      x: _resulrRect.x / scale,
      y: _resulrRect.y / scale,
      width: _resulrRect.width / scale,
      height: _resulrRect.height / scale,
    };
    if (initRect && topEventRect && resulrRect) {
      const headerHeight = 125;
      const svgLineData = [];
      dangerChainList.forEach((line) => {
        if (sIndex === line.streamIndex) {
          const startEventHeight = (initRect.height - headerHeight) / line.startEventCount;
          const startEventSpace = (startEventHeight - 120) / 2;
          const resultHeight = (resulrRect.height - headerHeight) / line.resultCount;
          const resultSpace = (resultHeight - 120) / 2;
          // 线条距离顶部的高度
          const lineTop = 23;
          const point1 = `${initRect.width - (line.initiatotBarrierCount * 128 + 120)},${
            startEventHeight * line.initiatotIndex + startEventSpace + lineTop + headerHeight
          }`;
          const point2 = `${initRect.width},${startEventHeight * line.initiatotIndex + startEventSpace + lineTop + headerHeight}`;
          const point3 = `${topEventRect.width / 2 + initRect.width},${(topEventRect.height - headerHeight) / 2 + headerHeight}`;
          const point4 = `${initRect.width + topEventRect.width},${resultHeight * line.resultIndex + resultSpace + lineTop + headerHeight}`;
          const point5 = `${initRect.width + topEventRect.width + (line.resultBarrierCount * 128 + 120)},${
            resultHeight * line.resultIndex + resultSpace + lineTop + headerHeight
          }`;
          svgLineData.push({
            points: `${point1} ${point2} ${point3} ${point4} ${point5}`,
            style: {
              fill: '#00000000',
              stroke: line.riskGrade.color || 'black',
              strokeWidth: 2,
              borderStyle: 'solid',
              borderWidth: 1,
              borderColor: '#eee',
            },
          });
        }
      });
      return (
        <svg width={initRect.width + topEventRect.width + resulrRect.width} height={topEventRect.height} className="svg_view">
          {svgLineData.map((lineData, lIndex) => (
            <polyline key={lIndex} points={lineData.points} style={lineData.style} />
          ))}
        </svg>
      );
    }
  };
  // 加载事件流
  const loadCharContent = (stream, index) => {
    return (
      <div className="chart_content" key={index}>
        {loadSVGView(stream, index)}
        <div className="initiatot_group" id={`initiatot_group_${index}`}>
          <div className="chart_header">
            <span className="chart_header_text">预防LOPC发生</span>
          </div>
          <div className="item_view">{stream.startEventList?.map(loadInitiatotItem)}</div>
        </div>

        <div className="top_event" id={`top_event_${index}`}>
          <div className="chart_header">
            <span className="chart_header_text">顶级事件</span>
          </div>
          <div className="top_event_view">
            <div className="top_event_bg">
              <span>{props.publicData?.getTopEventType(stream.top_event_type_id)?.label || '顶级事件'}</span>
            </div>
          </div>
        </div>

        <div className="result_group" id={`result_group_${index}`}>
          <div className="chart_header">
            <span className="chart_header_text">降低LOPC的后果</span>
          </div>
          <div className="item_view">{stream.resultList?.map(loadResultItem)}</div>
        </div>
      </div>
    );
  };

  return (
    <div
      className="com-white-block chart-body p-base element"
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}>
      <div className="chart-button">
        {/* 状态图切换按钮 */}
        {nodeRisk && (
          <div className="chart-risk">
            <span className={nodeRisk.value >= riskAlertValue ? 'blink-name' : 'chart-name'} style={{color: nodeRisk.color}}>
              {nodeRisk.label}
            </span>
            <span
              className={nodeRisk.value >= riskAlertValue ? 'blink-line' : 'chart-line'}
              style={{
                backgroundColor: nodeRisk.color,
              }}
            />
          </div>
        )}
      </div>
      {/* 图主体 */}
      <div className="fullscreen">
        <div className="chart">{nodeInfo?.streamList?.map(loadCharContent)}</div>
        {/* 图示 */}
        <div className="legend mt-base">
          <div className="legend-content">
            <div className="line-example">
              <span className="label">线条图示:</span>
              {props.publicData?.optionList.riskList?.map((risk) => {
                return (
                  <div key={risk.risk_id}>
                    <span className="vname">{risk.label}</span>
                    <span
                      className="vl"
                      style={{
                        backgroundColor: risk.color,
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PubChart;
