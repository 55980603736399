/* eslint-disable react-hooks/exhaustive-deps */
// noinspection JSUnresolvedVariable,JSUnresolvedFunction,JSValidateTypes,NpmUsedModulesInstalled

import {Button, DatePicker, Select, Table} from 'antd';
import React, {useEffect, useState} from 'react';
import dayjs from 'dayjs';
import {request} from '../../util';
import {inject, observer} from 'mobx-react';
import {formatAlertEvent} from '../../util/publicFunc';
import './nodeAlert.scss';
import config from '../../config';
import log from 'loglevel';
import {Column} from '@ant-design/plots';
log.setLevel(config.debug ? log.levels.DEBUG : log.levels.ERROR);
// const moment = require('moment');
const ExportJsonExcel = require('js-export-excel');
const DesignAlert = (props) => {
  const [nodeList, setNodeList] = useState([]);
  const [selectNode, setSelectNode] = useState('');
  const [selectRiskValue, setSelectRiskValue] = useState('');
  const [selectDepartment, setSelectDepartment] = useState('');
  const [alertList, setAlertList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNum, setPageNum] = useState(0);
  const [timeRange, setTimeRange] = useState([dayjs().subtract(1, 'months'), dayjs()]);
  const loadNodeList = () => {
    request.post('/company/queryNodeList', {}, true).then((res) => {
      setNodeList(res.nodeList);
    });
  };
  const loadAlertBarrierList = (pageNum, pageSize) => {
    const param = {
      owner_node_id: selectNode,
      department_id: selectDepartment,
      risk_value: selectRiskValue,
      startTime: dayjs(timeRange[0]).format('YYYY-MM-DD 00:00:00'),
      endTime: dayjs(timeRange[1]).format('YYYY-MM-DD 23:59:59'),
      pageNum,
      pageSize,
    };
    request.post('/company/getAlertNode', param, true).then((res) => {
      const alertList = res.alertList.map(formatAlertEvent);
      alertList.map((alert) => (alert.key = alert.alert_event_id));
      setAlertList(alertList);
      setTotalCount(res.count);
      setPageNum(res.pageNum);
    });
  };
  useEffect(() => {
    loadNodeList();
  }, []);
  useEffect(() => {
    loadAlertBarrierList(0, 10);
  }, [selectNode, selectDepartment, selectRiskValue, timeRange]);
  const columns = [
    {
      title: '序号',
      align: 'center',
      key: 'alert_event_id',
      render: (text, alert, index) => `${index + 1 + pageNum * 10}`,
      width: 70,
    },
    {
      title: '节点',
      align: 'center',
      key: 'owner_node_info',
      dataIndex: 'owner_node_info',
      width: 150,
    },
    {
      title: '风险等级',
      align: 'center',
      key: 'risk_value',
      dataIndex: 'risk_value',
      width: 100,
    },
    {
      title: '开始时间',
      align: 'center',
      key: 'start_time',
      dataIndex: 'start_time',
      width: 200,
    },
    {
      title: '结束时间',
      align: 'center',
      key: 'end_time',
      dataIndex: 'end_time',
      width: 200,
    },
    {
      title: '持续时间（分钟）',
      align: 'center',
      key: 'useTime',
      dataIndex: 'useTime',
      width: 150,
    },
    {
      title: '描述',
      align: 'center',
      key: 'describe_info',
      dataIndex: 'describe_info',
      width: 250,
    },
    {
      title: '事件流',
      align: 'center',
      key: 'owner_stream_info',
      dataIndex: 'owner_stream_info',
      width: 150,
    },
    {
      title: '起始事件',
      align: 'center',
      key: 'owner_start_event_info',
      dataIndex: 'owner_start_event_info',
      width: 150,
    },
    {
      title: '相关事件',
      align: 'center',
      key: 'cause_info',
      dataIndex: 'cause_info',
      width: 150,
    },
    {
      title: '后果',
      align: 'center',
      key: 'owner_result_info',
      dataIndex: 'owner_result_info',
      width: 150,
    },
    {
      title: '所属部门',
      align: 'center',
      key: 'department_name',
      dataIndex: 'department_name',
      width: 150,
    },
    {
      title: '短信接收人',
      align: 'center',
      key: 'leader_name',
      dataIndex: 'leader_name',
      width: 150,
    },
    {
      title: '短信接收号码',
      align: 'center',
      key: 'leader_phone',
      dataIndex: 'leader_phone',
      width: 150,
    },
    {
      title: '发送状态',
      align: 'center',
      key: 'sms_state',
      dataIndex: 'sms_state',
      width: 100,
    },
    {
      title: '短信内容',
      align: 'center',
      key: 'message_content',
      dataIndex: 'message_content',
      width: 250,
    },
  ];
  // 下载excel文件
  const downloadExcel = () => {
    let option = {};
    option.fileName = '高风险事件';
    const param = {
      owner_node_id: selectNode,
      department_id: selectDepartment,
      risk_value: selectRiskValue,
      startTime: dayjs(timeRange[0]).format('YYYY-MM-DD 00:00:00'),
      endTime: dayjs(timeRange[1]).format('YYYY-MM-DD 23:59:59'),
      pageNum: 0,
      pageSize: totalCount,
    };
    request.post('/company/getAlertNode', param, true).then((res) => {
      const alertList = res.alertList.map(formatAlertEvent);
      const data = alertList.map((alert, index) => [
        index,
        alert.owner_node_info,
        alert.risk_value,
        alert.start_time,
        alert.end_time,
        alert.useTime,
        alert.describe_info,
        alert.owner_stream_info,
        alert.owner_start_event_info,
        alert.cause_info,
        alert.owner_result_info,
        alert.department_name,
        alert.leader_name,
        alert.leader_phone,
        alert.sms_state,
        alert.message_content,
      ]);
      option.datas = [
        {
          sheetData: data,
          sheetName: 'sheet',
          sheetHeader: [
            '序号',
            '节点',
            '风险等级',
            '开始时间',
            '结束时间',
            '持续时间（分钟）',
            '描述',
            '事件流',
            '起始事件',
            '相关事件',
            '后果',
            '所属部门',
            '短信接收人',
            '短信接收号码',
            '发送状态',
            '短信内容',
          ],
          columnWidths: [5, 5, 5, 10, 10, 10, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20],
        },
      ];
      const toExcel = new ExportJsonExcel(option); //new
      toExcel.saveExcel(); //保存
    });
  };
  const pageChange = (page, pageSize = 10) => {
    loadAlertBarrierList(page - 1, pageSize);
  };
  // Chart 1: 风险事件统计-发生次数
  const [data1, setData1] = useState([]);
  const [total1, setTotal1] = useState('0');
  useEffect(() => {
    getAlertNodeCount();
  }, [selectNode, selectDepartment, selectRiskValue, timeRange]);
  const getAlertNodeCount = () => {
    const param = {
      owner_node_id: selectNode,
      department_id: selectDepartment,
      risk_value: selectRiskValue,
      startTime: dayjs(timeRange[0]).format('YYYY-MM-DD 00:00:00'),
      endTime: dayjs(timeRange[1]).format('YYYY-MM-DD 23:59:59'),
    };
    request.post('/company/getAlertNodeCount', param, true).then((res) => {
      const data1 = res.data;
      setData1(data1);
      const total1 = data1.reduce((pre, cur) => pre + cur.total, 0);
      setTotal1(total1.toLocaleString());
    });
  };
  const config1 = {
    data: data1,
    isStack: true,
    xField: 'date',
    yField: 'total',
    seriesField: 'node_name',
    dodgePadding: 2,
    padding: 80,
    label: {
      // position: 'middle', // 'top', 'bottom', 'middle'
    },
    interactions: [
      {
        type: 'active-region',
        enable: false,
      },
    ],
  };
  // Chart 2: 风险事件统计-持续时间
  const [data2, setData2] = useState([]);
  const [duration2, setDuration2] = useState('0');
  useEffect(() => {
    getAlertNodeDuration();
  }, [selectNode, selectDepartment, selectRiskValue, timeRange]);
  const getAlertNodeDuration = () => {
    const param = {
      owner_node_id: selectNode,
      department_id: selectDepartment,
      risk_value: selectRiskValue,
      startTime: dayjs(timeRange[0]).format('YYYY-MM-DD 00:00:00'),
      endTime: dayjs(timeRange[1]).format('YYYY-MM-DD 23:59:59'),
    };
    request.post('/company/getAlertNodeDuration', param, true).then((res) => {
      const data2 = res.data;
      setData2(data2);
      const total2 = data2.reduce((pre, cur) => pre + cur.total, 0);
      setDuration2(total2.toLocaleString());
    });
  };
  const config2 = {
    data: data2,
    isStack: true,
    xField: 'date',
    yField: 'total',
    seriesField: 'node_name',
    dodgePadding: 2,
    padding: 80,
    label: {
      // position: 'middle', // 'top', 'bottom', 'middle'
    },
    interactions: [
      {
        type: 'active-region',
        enable: false,
      },
    ],
  };
  return (
    <div className="barrier-alert">
      <div className="search-view">
        <div className="filter">
          <div className="label">节点：</div>
          <Select className="select" defaultValue={selectNode} style={{width: 150}} onChange={(v) => setSelectNode(v)}>
            <Select.Option value={''} key={'all'}>
              全部
            </Select.Option>
            {nodeList.map((node) => (
              <Select.Option value={node.node_id || ''} key={node.node_id}>
                {node.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="filter">
          <div className="label">风险等级：</div>
          <Select className="select" style={{width: 150}} defaultValue={selectRiskValue} onChange={(v) => setSelectRiskValue(v)}>
            <Select.Option value={''}>全部</Select.Option>
            {props.publicData?.optionList.riskList
              ?.filter((risk) => risk.value >= props.publicData?.getRiskAlertValue())
              .map((risk) => (
                <Select.Option value={`${risk.value}`} key={`${risk.value}`}>{`等级${risk.value}`}</Select.Option>
              ))}
          </Select>
        </div>
        <div className="filter">
          <div className="label">部门：</div>
          <Select className="select" style={{width: 150}} defaultValue={selectDepartment} onChange={(v) => setSelectDepartment(v)}>
            <Select.Option value={''} key={'all'}>
              全部
            </Select.Option>
            {props.publicData?.optionList.departmentList?.map((dep) => (
              <Select.Option value={dep.department_id} key={dep.department_id}>
                {dep.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="filter">
          <div className="label">发生时间：</div>
          <DatePicker.RangePicker
            className="time-picker"
            clearIcon={false}
            value={timeRange}
            onChange={(v) => setTimeRange(v)}></DatePicker.RangePicker>
        </div>
        <div className="report">
          <div className="label"></div>
          <Button type="primary" className="getExcel" onClick={downloadExcel} key="downloadExcel">
            生成报告
          </Button>
        </div>
      </div>

      <div style={{height: '400px', backgroundColor: 'white', marginTop: 20}}>
        <h4 style={{paddingLeft: 50, marginTop: 20, color: 'gray'}}>事件发生次数（{total1}次）</h4>
        <Column {...config1} />
      </div>

      <div style={{height: '400px', backgroundColor: 'white', marginTop: 20}}>
        <h4 style={{paddingLeft: 50, marginTop: 20, color: 'gray'}}>事件发生持续时间（{duration2}分钟）</h4>
        <Column {...config2} />
      </div>
      <div style={{marginTop: 20}}>
        <Table
          pagination={{position: ['bottomRight'], total: totalCount, onChange: pageChange}}
          dataSource={alertList}
          columns={columns}
          scroll={{x: 2500}}
        />
      </div>
    </div>
  );
};
export default inject('publicData')(observer(DesignAlert));
