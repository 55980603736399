// import { barrierWorkTypeList, departmentList, deviationTypeList, severityList, startEventTypeList, topEventTypeList } from './publicFunc';
import publicData from './publicData';
import config from '../config';
import log from 'loglevel';

log.setLevel(config.debug ? log.levels.DEBUG : log.levels.ERROR);
export const loadRecordItems = (oldObj, newObj, items) => {
    const recordItems = [];
    items.forEach((item) => {
        const oldValue = oldObj[item.key] || '--';
        const newValue = newObj[item.key] || '--';
        if (oldValue !== newValue) {
            recordItems.push({ label: item.label, oldValue, newValue, key: item.key });
        }
    });
    return recordItems;
};
const getDiffObj = (objOld = {}, objNew = {}, keyLabelList = []) => {
    let _a, _b;
    let diffText = [];
    for (const objKey in objNew) {
        for (const keyLabel of keyLabelList) {
            if (keyLabel.key === objKey) {
                const newValue = objNew[objKey];
                const oldValue = objOld[objKey];
                if (keyLabel.list && keyLabel.idKey) {
                    const diffList = getDiffList(oldValue || [], newValue || [], keyLabel.idKey, keyLabel.label, keyLabel.list);
                    diffText = diffText.concat(diffList);
                }
                else if (oldValue !== newValue) {
                    if (keyLabel.dict) {
                        log.debug(keyLabel.dict, keyLabel.label);
                        diffText.push(`${keyLabel.label}:${(_a = keyLabel.dict.find((v) => v[objKey] === oldValue)) === null || _a === void 0 ? void 0 : _a.label} 修改为 ${(_b = keyLabel.dict.find((v) => v[objKey] === newValue)) === null || _b === void 0 ? void 0 : _b.label}`);
                    }
                    else {
                        diffText.push(`${keyLabel.label}:${oldValue} 修改为 ${newValue}`);
                    }
                }
            }
        }
    }
    return diffText;
};
const getObjDesc = (obj, keyLabelList) => {
    const desc = [];
    for (const key in obj) {
        for (const keyLabel of keyLabelList) {
            if (key === keyLabel.key) {
                desc.push(`${keyLabel.label}:${obj[key]}`);
            }
        }
    }
    return desc;
};
const getDiffList = (list_old, list_new, id_key, label, keyLabelList) => {
    let diffTextList = [];
    const newIds = list_new.map((o) => o[id_key]);
    // 1. 找到已经删除的数据
    list_old.forEach((obj) => {
        if (!newIds.includes(obj[id_key])) {
            diffTextList.push(`删除${label},编号:${obj[id_key]}`);
        }
    });
    // 2.遍历新数据 做出插入或更新操作
    list_new.forEach((newObj) => {
        if (newObj[id_key]) {
            //  有id是更新
            // 此处使用update便利方法,主键不是id 而是自定义的字段,需要在where 后面加上where 配置
            const oldObj = list_old.find((oldObj) => oldObj[id_key] === newObj[id_key]);
            const diffObj = getDiffObj(oldObj, newObj, keyLabelList);
            diffTextList = diffTextList.concat(diffObj);
        }
        else {
            // 没id 是插入
            const descList = getObjDesc(newObj, keyLabelList);
            diffTextList.push(`新增${label},内容:${descList.join('，')}`);
        }
    });
    return diffTextList;
};
export const loadNodeInfoDiff = (oldObj, newObj) => {
    return getDiffObj(oldObj, newObj, [
        {
            key: 'name',
            label: '节点名称',
        },
        {
            key: 'number',
            label: '节点编号',
        },
        {
            key: 'pid',
            label: '图号',
        },
        {
            key: 'intent',
            label: '节点意图',
        },
        {
            key: 'harm',
            label: '危害',
        },
        {
            key: 'streamList',
            label: '事件流',
            list: [
                {
                    key: 'top_event_type_id',
                    label: '顶级事件',
                    dict: publicData.optionList.topEventTypeList,
                },
                {
                    key: 'startEventList',
                    label: '起始事件',
                    list: [
                        {
                            key: 'start_event_type_id',
                            label: '起始事件类型',
                            dict: publicData.optionList.startEventTypeList,
                        },
                        {
                            key: 'info',
                            label: '描述',
                        },
                        {
                            key: 'touchTags',
                            label: '触发逻辑',
                        },
                        {
                            key: 'pfdTags',
                            label: 'pfd逻辑',
                        },
                        {
                            key: 'deviation_type_id',
                            label: '偏差类型',
                            dict: publicData.optionList.deviationTypeList,
                        },
                        {
                            key: 'department_id',
                            label: '所属部门',
                            dict: publicData.optionList.departmentList,
                        },
                        {
                            key: 'barrierList',
                            label: '保护层',
                            list: [
                                {
                                    key: 'info',
                                    label: '描述',
                                },
                                {
                                    key: 'barrier_work_type_id',
                                    label: '工作类型',
                                    dict: publicData.optionList.barrierWorkTypeList,
                                },
                                {
                                    key: 'pfdTags',
                                    label: '保护措施PFD',
                                },
                                {
                                    key: 'bypassTags',
                                    label: '失效逻辑符',
                                },
                                {
                                    key: 'touchTags',
                                    label: '触发逻辑符',
                                },
                                {
                                    key: 'department_id',
                                    label: '所属部门',
                                    dict: publicData.optionList.departmentList,
                                },
                            ],
                            idKey: 'barrier_id',
                        },
                    ],
                    idKey: 'start_event_id',
                },
                {
                    key: 'resultList',
                    label: '后果',
                    list: [
                        {
                            key: 'severity_id',
                            label: '后果等级',
                            dict: publicData.optionList.severityList,
                        },
                        {
                            key: 'info',
                            label: '描述',
                        },
                        {
                            key: 'barrierList',
                            label: '保护层',
                            list: [
                                {
                                    key: 'info',
                                    label: '描述',
                                },
                                {
                                    key: 'barrier_work_type_id',
                                    label: '工作类型',
                                    dict: publicData.optionList.barrierWorkTypeList,
                                },
                                {
                                    key: 'pfdTags',
                                    label: '保护措施PFD',
                                },
                                {
                                    key: 'bypassTags',
                                    label: '失效逻辑符',
                                },
                                {
                                    key: 'touchTags',
                                    label: '触发逻辑符',
                                },
                                {
                                    key: 'department_id',
                                    label: '所属部门',
                                    dict: publicData.optionList.departmentList,
                                },
                            ],
                            idKey: 'barrier_id',
                        },
                    ],
                    idKey: 'result_id',
                },
            ],
            idKey: 'stream_id',
        },
    ]);
};
