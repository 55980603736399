/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { request } from '../../util';
import { Table } from 'antd';
import dayjs from 'dayjs';
import config from '../../config';
import log from 'loglevel';
log.setLevel(config.debug ? log.levels.DEBUG : log.levels.ERROR);

// const utc = require('dayjs/plugin/utc');
// dayjs.extend(utc);

const LoginLogs = () => {
    const [logs, setLogs] = useState([]);
    const loadLoginLogs = () => {
        request.post('/company/login_logs', null, true).then((res) => {
            setLogs(res.login_logs.reverse());
        });
    };
    useEffect(() => {
        loadLoginLogs();
    }, []);
    const columns = [
        {
            title: '序号',
            align: 'center',
            key: 'index',
            render: (_, alert, index) => `${index + 1 }`,
        },
        {
            title: '账号',
            align: 'center',
            key: 'account',
            dataIndex: 'account',
        },
        {
            title: '姓名',
            align: 'center',
            key: 'name',
            dataIndex: 'name',
        },
        {
            title: '登录日期',
            align: 'center',
            key: 'login_at',
            render: (_, log, index) => dayjs(log.login_at).format('YYYY-MM-DD HH:mm:ss'),
        },
    ];

    return (
        <div className="">
            <Table
                pagination={{hideOnSinglePage: true}}
                dataSource={logs}
                columns={columns}
                scroll={{x: 1080}}
            />
        </div>
    );
};
export default LoginLogs;
