import { Breadcrumb } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { request } from '../../util';
import './index.scss';
import config from '../../config';
import log from 'loglevel';
log.setLevel(config.debug ? log.levels.DEBUG : log.levels.ERROR);
// page：消息
const NoticeDetail = () => {
    const navigate = useNavigate();
    const notice_id = useParams().notice_id;
    log.debug('公告id', notice_id);
    const [noticeInfo, setNoticeInfo] = useState();
    const loadNoticeInfo = useCallback(() => {
        request.post('/company/queryNoticeInfo', { notice_id }, true).then((res) => {
            setNoticeInfo(res);
            if (res.state === 0) {
                request.post('/company/readNotice', { notice_id }, true);
            }
        });
    }, [notice_id]);
    useEffect(() => {
        loadNoticeInfo();
    }, [loadNoticeInfo]);
    return (
        <div className="p-lg">
            {/* part:导航栏 */}
            <Breadcrumb>
                <Breadcrumb.Item>平台管理</Breadcrumb.Item>
                <Breadcrumb.Item>
                    <a href="/" onClick={() => navigate(-1)}>
                        消息
                    </a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>消息详情</Breadcrumb.Item>
            </Breadcrumb>
            {/* part:内容 */}
            <div className="com-white-block">
                <div className="com-align_center font-size-xl font-weight-bold">标题：{noticeInfo?.title}</div>
                <div className="text-color-light com-align_center font-size-sm mt-10">
                    时间：<span>{dayjs(noticeInfo?.create_time).format('YYYY-MM-DD HH:mm')}</span>
                    {!!noticeInfo?.reader_name && <span style={{marginLeft: '20px'}}>阅读人:{noticeInfo?.reader_name}</span>}
                    {!!noticeInfo?.read_time && (
                        <span style={{marginLeft: '20px'}}>阅读时间:{dayjs(noticeInfo?.read_time).format('YYYY-MM-DD HH:mm')}</span>
                    )}
                </div>
                <div className="mt-base text-indent">{noticeInfo?.content}</div>
            </div>
        </div>
    );
};
export default NoticeDetail;
