/* eslint-disable react-hooks/exhaustive-deps */
// noinspection JSIgnoredPromiseFromCall

import { Breadcrumb, Button, Col, Form, Input, message, Modal, Row, Select, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { request } from '../../util';
import ChartEditView from './compoment/chartEditView';
import './index.scss';
import config from '../../config';
import log from 'loglevel';
log.setLevel(config.debug ? log.levels.DEBUG : log.levels.ERROR);
const EditChart = (props) => {
    const node_id = useParams().node_id;
    const title = node_id ? '编辑节点' : '新增节点';
    const navigate = useNavigate();
    const [nodeForm] = useForm();
    useEffect(() => {
        if (node_id) {
            request.post('/company/queryNodeInfo', { node_id }, true).then((res) => {
                props.editNode.setNodeInfo(res);
                nodeForm.setFieldsValue(res);
            });
        }
        else {
            props.editNode.setNodeInfo({
                streamList: [],
            });
        }
    }, [node_id]);
    // ==========    交互   ==============
    // 提交node
    const submitNode = (values) => {
        if (node_id) {
            setReason('');
            setShowSubmitModal(true);
        }
        else {
            const param = Object.assign(Object.assign({}, props.editNode.nodeInfo), values);
            log.debug('node数据', JSON.parse(JSON.stringify(props.editNode.nodeInfo)));
            request.post('/company/createNode', param, true).then(() => {
                message.success('创建成功');
                navigate(-1);
            });
        }
    };
    const onDragStart = (e) => {
        log.debug('拖动的item', e.target.id);
        global.dragItem = e.target.id;
    };
    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
    };
    //  ===========   修改    ===========
    const [reason, setReason] = useState('');
    const [showSubmitModal, setShowSubmitModal] = useState(false);
    const editAction = () => {
        setShowSubmitModal(false);
        const param = {
            nodeData: Object.assign(Object.assign({}, props.editNode.nodeInfo), nodeForm.getFieldsValue()),
            reason,
        };
        log.debug('node数据', JSON.parse(JSON.stringify(props.editNode.nodeInfo)));
        request.post('/company/editNode', param, true).then(() => {
            message.success('更新成功');
        });
    };
    return (
        <div className="p-lg char-edit-page">
            {/* part:导航栏 */}
            <Breadcrumb>
                <Breadcrumb.Item>节点管理</Breadcrumb.Item>
                <Breadcrumb.Item>
                    <a href="#/home/charts">节点列表</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{title}</Breadcrumb.Item>
            </Breadcrumb>

            <Form {...layout} colon={true} className="form-wrapper" form={nodeForm} layout="horizontal" autoComplete="off" onFinish={submitNode}>
                <Form.Item label="节点名称" name="name" rules={[{required: true, message: '请输入节点名称'}]}>
                    <Input placeholder="请输入节点名称" maxLength={200} />
                </Form.Item>
                <Form.Item label="所属部门" name="department_id" rules={[{required: true, message: '请选择所属部门'}]}>
                    <Select placeholder="请选择节点负责部门">
                        {props.publicData?.optionList.departmentList?.map((dep) => (
                            <Select.Option value={dep.department_id} key={dep.department_id}>
                                {dep.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="节点编号" name="number" rules={[{required: true, message: '请输入节点编号'}]}>
                    <Input placeholder="请输入节点编号" maxLength={100} />
                </Form.Item>
                <Form.Item label="P&ID图号" name="pid">
                    <Input placeholder="请输入P&ID图号" maxLength={100} />
                </Form.Item>
                <Form.Item label="设计意图" name="intent">
                    <Input.TextArea placeholder="请输入设计意图" maxLength={600} autoSize={{maxRows: 3}} />
                </Form.Item>
                <Form.Item label="危害" name="harm">
                    <Input.TextArea placeholder="请输入危害" maxLength={600} autoSize={{maxRows: 3}} />
                </Form.Item>

                <Row justify="end">
                    <Space size="middle">
                        <Button type="primary" htmlType="submit">
                            保存
                        </Button>
                    </Space>
                </Row>
            </Form>
            {/* 添加事件 */}
            <Row justify="end" className="move-view">
                <Space direction="vertical">
                    <div className="add-btn" draggable id="add_stream" onDragStart={onDragStart}>
                        + 事件流
                    </div>
                    <div className="add-btn" draggable id="add_startEvent" onDragStart={onDragStart}>
                        + 起始事件
                    </div>
                    <div className="add-btn" draggable id="add_result" onDragStart={onDragStart}>
                        + 后果
                    </div>
                    <div className="add-btn" draggable id="add_barrier" onDragStart={onDragStart}>
                        + 保护层
                    </div>
                </Space>
            </Row>
            <ChartEditView key={'char-edit-view'} />

            <Modal
                title="修改节点"
                visible={showSubmitModal}
                onOk={editAction}
                onCancel={() => setShowSubmitModal(false)}>
                <Col span={24}>
                    <Input placeholder="请输入修改原因" value={reason} onChange={(e) => setReason(e.target.value)} />
                </Col>
            </Modal>
        </div>
    );
};
export default inject('editNode', 'publicData')(observer(EditChart));
