import {Button, DatePicker, Select, Table} from 'antd';
import React, {useEffect, useState, useRef} from 'react';
import dayjs from 'dayjs';
import './index.scss';
import {request} from '../../util';
import {inject, observer} from 'mobx-react';
import {formatAlertEvent} from '../../util/publicFunc';
import {useNavigate} from 'react-router-dom';
import {Column} from '@ant-design/plots';
const ExportJsonExcel = require('js-export-excel');
/* eslint-disable react-hooks/exhaustive-deps */
// const moment = require('moment');
const DesignAlert = (props) => {
  const [nodeList, setNodeList] = useState([]);
  const [selectNode, setSelectNode] = useState('');
  const [alertType, setAlertType] = useState(0);
  const [selectDepartment, setSelectDepartment] = useState('');
  const [alertList, setAlertList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNum, setPageNum] = useState(0);
  const [timeRange, setTimeRange] = useState([dayjs().subtract(1, 'months'), dayjs()]);
  // const [timeRangeCache, setTimeRangeCache] = useState([dayjs().subtract(1, 'months'), dayjs()]);
  const [selectBarrierWorkType, setSelectBarrierWorkType] = useState('');
  // const [filterBarriers, setFilterBarriers] = useState([]);
  const [selectBarrier, setSelectBarrier] = useState([]);
  const [barrierOptions, setBarrierOptions] = useState([]);
  const loadNodeList = () => {
    request.post('/company/queryNodeList', {}, true).then((res) => {
      setNodeList(res.nodeList);
    });
  };
  const loadAlertBarrierListSeq = useRef(0);
  const loadAlertBarrierList = (pageNum, pageSize) => {
    const param = {
      owner_node_id: selectNode,
      department_id: selectDepartment,
      event_type: alertType === 0 ? [8, 9] : [alertType],
      barrierWorkType: selectBarrierWorkType,
      barrier_id: selectBarrier,
      startTime: dayjs(timeRange[0]).format('YYYY-MM-DD 00:00:00'),
      endTime: dayjs(timeRange[1]).format('YYYY-MM-DD 23:59:59'),
      pageNum,
      pageSize,
    };
    const currentRequest = Date.now();
    loadAlertBarrierListSeq.current = currentRequest;
    request.post('/company/getAlertDesign', param, true).then((res) => {
      if (currentRequest !== loadAlertBarrierListSeq.current) {
        return;
      }
      setAlertList(res.alertList.map(formatAlertEvent));
      setTotalCount(res.count);
      setPageNum(res.pageNum);
    });
  };
  useEffect(() => {
    loadNodeList();
  }, []);
  useEffect(() => {
    loadAlertBarrierList(0, 10);
  }, [selectNode, alertType, selectBarrierWorkType, selectDepartment, timeRange, selectBarrier]);
  const loadFilterBarrierList = () => {
    const param = {
      owner_node_id: selectNode,
      department_id: selectDepartment,
      barrierWorkType: selectBarrierWorkType,
    };
    request.post('/company/getFilterBarriers', param, true).then((res) => {
      // setFilterBarriers(res.barriers);
      const __barrierOptions = res.barriers.map((barrier) => {
        return {
          label: barrier.info,
          value: barrier.barrier_id,
        };
      });
      setBarrierOptions(__barrierOptions);
    });
  };
  useEffect(() => {
    loadFilterBarrierList();
  }, [selectNode, selectDepartment, selectBarrierWorkType]);
  const columns = [
    {
      title: '序号',
      align: 'center',
      render: (text, alert, index) => `${index + 1 + pageNum * 10}`,
      width: 70,
    },
    {
      title: '保护层',
      align: 'center',
      key: 'owner_barrier_info',
      dataIndex: 'owner_barrier_info',
      width: 150,
    },
    {
      title: '开始时间',
      align: 'center',
      key: 'start_time',
      dataIndex: 'start_time',
      width: 200,
    },
    {
      title: '结束时间',
      align: 'center',
      key: 'end_time',
      dataIndex: 'end_time',
      width: 200,
    },
    {
      title: '持续时间（分钟）',
      align: 'center',
      key: 'useTime',
      dataIndex: 'useTime',
      width: 150,
    },
    {
      title: '报警类型',
      align: 'center',
      key: 'event_type',
      dataIndex: 'event_type',
      width: 100,
    },
    {
      title: '工作类型',
      align: 'center',
      key: 'barrier_work_type_id',
      dataIndex: 'barrier_work_type_id',
      width: 100,
      render: (text, record) => {
        const workTypeId = record.barrier_work_type_id;
        const workType = props.publicData?.optionList.barrierWorkTypeList.find((v) => v.barrier_work_type_id === workTypeId);
        return workType?.label || '';
      },
    },
    {
      title: '描述',
      align: 'center',
      key: 'describe_info',
      dataIndex: 'describe_info',
      width: 200,
    },
    {
      title: '所属节点',
      align: 'center',
      key: 'owner_node_info',
      dataIndex: 'owner_node_info',
      width: 150,
    },
    {
      title: '所属事件流',
      align: 'center',
      key: 'owner_stream_info',
      dataIndex: 'owner_stream_info',
      width: 150,
    },
    {
      title: '所属起始事件',
      align: 'center',
      key: 'owner_start_event_info',
      dataIndex: 'owner_start_event_info',
      width: 150,
    },
    {
      title: '所属后果',
      align: 'center',
      key: 'owner_result_info',
      dataIndex: 'owner_result_info',
      width: 150,
    },
    {
      title: '所属部门',
      align: 'center',
      key: 'department_name',
      dataIndex: 'department_name',
      width: 150,
    },
    {
      title: '短信接收人',
      align: 'center',
      key: 'leader_name',
      dataIndex: 'leader_name',
      width: 150,
    },
    {
      title: '短信接收号码',
      align: 'center',
      key: 'leader_phone',
      dataIndex: 'leader_phone',
      width: 150,
    },
    {
      title: '短信发送',
      align: 'center',
      key: 'sms_state',
      dataIndex: 'sms_state',
      width: 100,
    },
    {
      title: '触发Tags',
      align: 'center',
      key: 'touchTags',
      dataIndex: 'touchTags',
      width: 1000,
    },
    {
      title: '触发数值',
      align: 'center',
      key: 'alert_touch_vals',
      width: 1000,
      render: (_, record) => (record.alert_touch_vals ? record.alert_touch_vals : '--'),
    },
    {
      title: '操作',
      key: 'action',
      align: 'center',
      fixed: 'right',
      width: 100,
      render: (text, alert) => (
        <Button type="link" onClick={() => navChartInfo(alert)}>
          查看节点
        </Button>
      ),
    },
  ];
  const navigate = useNavigate();
  const navChartInfo = (alert) => {
    navigate(`/home/chart-info/${alert.owner_node_id}`);
  };
  // 下载excel文件
  const downloadExcel = () => {
    const option = {};
    option.fileName = '设计错误事件';
    const param = {
      owner_node_id: selectNode,
      department_id: selectDepartment,
      event_type: alertType === 0 ? [8, 9] : [alertType],
      barrierWorkType: selectBarrierWorkType,
      barrier_id: selectBarrier,
      startTime: dayjs(timeRange[0]).format('YYYY-MM-DD 00:00:00'),
      endTime: dayjs(timeRange[1]).format('YYYY-MM-DD 23:59:59'),
      pageNum: 0,
      pageSize: totalCount,
    };
    request.post('/company/getAlertDesign', param, true).then((res) => {
      const alertList = res.alertList.map(formatAlertEvent);
      const data = alertList.map((alert, index) => {
        const workTypeId = alert.barrier_work_type_id;
        const workType = props.publicData?.optionList.barrierWorkTypeList.find((v) => v.barrier_work_type_id === workTypeId);
        return [
          index,
          alert.start_time,
          alert.event_type,
          workType?.label || '',
          alert.describe_info,
          alert.owner_node_info,
          alert.owner_stream_info,
          alert.owner_start_event_info,
          alert.owner_result_info,
          alert.owner_barrier_info,
          alert.department_name,
          alert.leader_name,
          alert.leader_phone,
          alert.sms_state,
          alert.message_content,
          alert.alert_touch_tags,
          alert.alert_touch_vals,
        ];
      });
      option.datas = [
        {
          sheetData: data,
          sheetName: 'sheet',
          sheetHeader: [
            '序号',
            '事件时间',
            '事件类型',
            '工作类型',
            '相关描述',
            '相关节点',
            '相关事件流',
            '相关起始事件',
            '相关后果',
            '相关保护层',
            '相关部门',
            '短信接收人',
            '短信接收号码',
            '发送状态',
            '短信内容',
            '触发原因',
            '触发数值',
          ],
          columnWidths: [5, 5, 5, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 100, 100, 100],
        },
      ];
      const toExcel = new ExportJsonExcel(option); //new
      toExcel.saveExcel(); //保存
    });
  };
  const pageChange = (page, pageSize = 10) => {
    loadAlertBarrierList(page - 1, pageSize);
  };
  // Chart 1: 起始事件事件统计-发生次数
  const [data1, setData1] = useState([]);
  const [total1, setTotal1] = useState('0');
  useEffect(() => {
    getAlertDesignCount();
  }, [selectNode, alertType, selectBarrierWorkType, selectDepartment, timeRange, selectBarrier]);
  const getAlertBarrierCountSeq = useRef(0);
  const getAlertDesignCount = () => {
    const param = {
      owner_node_id: selectNode,
      department_id: selectDepartment,
      event_type: alertType === 0 ? [8, 9] : [alertType],
      barrierWorkType: selectBarrierWorkType,
      barrier_id: selectBarrier,
      startTime: dayjs(timeRange[0]).format('YYYY-MM-DD 00:00:00'),
      endTime: dayjs(timeRange[1]).format('YYYY-MM-DD 23:59:59'),
    };
    const currentRequest = Date.now();
    getAlertBarrierCountSeq.current = currentRequest;
    request.post('/company/getAlertDesignCount', param, true).then((res) => {
      if (currentRequest !== getAlertBarrierCountSeq.current) {
        return;
      }
      const data1 = res.data;
      setData1(data1);
      const total1 = data1.reduce((pre, cur) => pre + cur.total, 0);
      setTotal1(total1.toLocaleString());
    });
  };
  const config1 = {
    data: data1,
    isStack: true,
    xField: 'date',
    yField: 'total',
    seriesField: 'node_name',
    dodgePadding: 2,
    padding: 80,
    label: {
      // position: 'middle', // 'top', 'bottom', 'middle'
    },
    interactions: [
      {
        type: 'active-region',
        enable: false,
      },
    ],
  };
  // Chart 2: 起始事件统计-持续时间
  const [data2, setData2] = useState([]);
  const [duration2, setDuration2] = useState('0');
  useEffect(() => {
    getAlertDesignDuration();
  }, [selectNode, alertType, selectBarrierWorkType, selectDepartment, timeRange, selectBarrier]);
  const getAlertBarrierDurationSeq = useRef(0);
  const getAlertDesignDuration = () => {
    const param = {
      owner_node_id: selectNode,
      department_id: selectDepartment,
      event_type: alertType === 0 ? [8, 9] : [alertType],
      barrierWorkType: selectBarrierWorkType,
      barrier_id: selectBarrier,
      startTime: dayjs(timeRange[0]).format('YYYY-MM-DD 00:00:00'),
      endTime: dayjs(timeRange[1]).format('YYYY-MM-DD 23:59:59'),
    };
    const currentRequest = Date.now();
    getAlertBarrierDurationSeq.current = currentRequest;
    request.post('/company/getAlertDesignDuration', param, true).then((res) => {
      if (currentRequest !== getAlertBarrierDurationSeq.current) {
        return;
      }
      const data2 = res.data;
      setData2(data2);
      const total2 = data2.reduce((pre, cur) => pre + cur.total, 0);
      setDuration2(total2.toLocaleString());
    });
  };
  const config2 = {
    data: data2,
    isStack: true,
    xField: 'date',
    yField: 'total',
    seriesField: 'node_name',
    dodgePadding: 2,
    padding: 80,
    label: {
      // position: 'middle', // 'top', 'bottom', 'middle'
    },
    interactions: [
      {
        type: 'active-region',
        enable: false,
      },
    ],
  };
  return (
    <div className="barrier-alert">
      <div className="search-view">
        <div className="filter">
          <div className="label">节点：</div>
          <Select className="select" defaultValue={selectNode} onChange={(v) => setSelectNode(v)}>
            <Select.Option value={''}>全部</Select.Option>
            {nodeList.map((node) => (
              <Select.Option value={node.node_id || ''}>{node.name}</Select.Option>
            ))}
          </Select>
        </div>
        <div className="filter">
          <div className="label">类型：</div>
          <Select className="select" defaultValue={alertType} onChange={(v) => setAlertType(v)}>
            <Select.Option value={0}>全部</Select.Option>
            <Select.Option value={8}>未正确触发</Select.Option>
            <Select.Option value={9}>安全时间不足</Select.Option>
          </Select>
        </div>
        <div className="filter">
          <div className="label">保护层类型：</div>
          <Select className="select" defaultValue={selectBarrierWorkType} onChange={(v) => setSelectBarrierWorkType(v)}>
            <Select.Option value={''}>全部</Select.Option>
            {props.publicData?.optionList.barrierWorkTypeList?.map((dep) => (
              <Select.Option value={dep.barrier_work_type_id || ''}>{dep.label}</Select.Option>
            ))}
          </Select>
        </div>
        <div className="filter">
          <div className="label">部门：</div>
          <Select className="select" defaultValue={selectDepartment} onChange={(v) => setSelectDepartment(v)}>
            <Select.Option value={''}>全部</Select.Option>
            {props.publicData?.optionList.departmentList?.map((dep) => (
              <Select.Option value={dep.department_id}>{dep.name}</Select.Option>
            ))}
          </Select>
        </div>
        <div className="filter">
          <div className="label">具体保护层事件：</div>
          <Select
            className="select"
            defaultValue={selectBarrier}
            onChange={(v) => setSelectBarrier(v)}
            options={barrierOptions}
            mode="multiple"
            allowClear
            showSearch
            filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {/* <Select.Option value={''}>全部</Select.Option>
            {filterBarriers?.map((barrier) => (
              <Select.Option key={barrier.barrier_id} value={barrier.barrier_id}>
                {barrier.info}
              </Select.Option>
            ))} */}
          </Select>
        </div>
        <div className="filter">
          <div className="label">发生时间：</div>
          <DatePicker.RangePicker
            className="time-picker"
            clearIcon={false}
            value={timeRange}
            onChange={(v) => setTimeRange(v)}
            // onChange={(v) => setTimeRangeCache(v)}
            // onOpenChange={(status) => {
            //   if (!status) {
            //     setTimeRange(timeRangeCache);
            //   }
            // }}
          ></DatePicker.RangePicker>
        </div>
        <div className="report">
          <Button type="primary" className="getExcel" onClick={downloadExcel}>
            生成报告
          </Button>
        </div>
      </div>
      <div style={{height: '400px', backgroundColor: 'white', marginTop: 20}}>
        <h4 style={{paddingLeft: 50, marginTop: 20, color: 'gray'}}>事件发生次数（{total1}次）</h4>
        <Column {...config1} />
      </div>
      <div style={{height: '400px', backgroundColor: 'white', marginTop: 20}}>
        <h4 style={{paddingLeft: 50, marginTop: 20, color: 'gray'}}>事件发生持续时间（{duration2}分钟）</h4>
        <Column {...config2} />
      </div>
      <div style={{marginTop: 20}}>
        <Table
          pagination={{position: ['bottomRight'], total: totalCount, onChange: pageChange}}
          dataSource={alertList}
          columns={columns}
          scroll={{x: 2500}}
        />
      </div>
    </div>
  );
};
export default inject('publicData')(observer(DesignAlert));
