// noinspection JSUnusedLocalSymbols,JSUnusedGlobalSymbols,JSValidateTypes,JSCheckFunctionSignatures,JSUnresolvedVariable,ES6CheckImport

import { Avatar, Breadcrumb, Button, Col, Row, Space } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { request } from '../../util';
import { getRecordDiffList } from './recordDiff';
// page:申请审批列表
const MyApply = () => {
    const navigate = useNavigate();
    useEffect(() => {
        queryMyApplyList();
    }, []);
    const [recordList, setRecordList] = useState([]);
    const queryMyApplyList = () => {
        request.post('/company/queryMyAskRecordList', {}, true).then((res) => {
            setRecordList(res.recordList);
        });
    };
    const withdrawMyAskRecord = (record) => {
        request.post('/company/withdrawMyAskRecord', { grade_record_id: record.grade_record_id }, true).then((res) => {
            const newRecordList = recordList.map((record) => (record.grade_record_id === res.grade_record_id ? res : record));
            setRecordList(newRecordList);
        });
    };
    const typeStr = ['未知', '等级修改申请', '参数修改申请', '选项修改申请'];
    const recordStateStr = ['等待审核', '审核通过', '审核驳回', '已撤回'];
    const recordStateClass = ['text-color-primary', 'text-color-green', 'text-color-danger', 'text-color-primary'];
    return (
        <div className="p-lg">
            {/* part:导航栏 */}
            <Breadcrumb>
                <Breadcrumb.Item>平台管理</Breadcrumb.Item>
                <Breadcrumb.Item>
                    <a href="/" onClick={() => navigate(-1)}>设置</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>我的申请</Breadcrumb.Item>
            </Breadcrumb>
            {recordList.map((record) => {
                const diffList = getRecordDiffList(record);
                return (
                    <div className="com-white-block">
                        <div className="font-weight-bold font-size-xl">{typeStr[record.type]}</div>
                        <Row className="mt-lg">
                            <Col span={12}>
                                审批状态：<span className={`${recordStateClass[record.state]} font-weight-bold`}>{recordStateStr[record.state]}</span>
                            </Col>
                            <Col span={12} className="com-align_right text-color-light">
                                申请时间:{dayjs(record.create_time).format('YYYY-MM-DD HH:mm')} {record.adopt_time && `审核时间:${dayjs(record.adopt_time).format('YYYY-MM-DD HH:mm')}`}
                            </Col>
                        </Row>
                        <Row className="mt-base">
                            申请人：
                            <Avatar className="mr-10" size={25} src={record.apply_user?.face} /> <span className="text-color-second">{record.apply_user?.name}</span>
                        </Row>
                        <Row className="mt-base">
                            修改原因：<span className="text-color-second">{record.apply_reason}</span>
                        </Row>
                        <Row className="mt-base">
                            <Col>修改内容：</Col>
                            {diffList?.map((dif) => (
                                <>
                                    <Col style={{ fontSize: 14, fontWeight: 'bold' }}>{dif.title}</Col>
                                    {dif.diffList.map((content) => (
                                        <Col span={24} className="mt-10 text-color-second">
                                            {content}
                                        </Col>
                                    ))}
                                </>
                            ))}
                        </Row>
                        <Row className="mt-base com-border-dashed-top pt-base" justify={'center'} align={'middle'}>
                            <Col span={24} className="com-align_right">
                                <Space size={'middle'}>
                                    <Button onClick={() => navigate( `/home/setting-record-detail/${record.grade_record_id}`)} type="primary" size={'small'} shape="default">
                                        查看详情
                                    </Button>
                                    {record.state === 0 && (
                                        <Button type="primary" danger className="com-btn" size={'small'} shape="default" onClick={() => withdrawMyAskRecord(record)}>
                                            撤回
                                        </Button>
                                    )}
                                </Space>
                            </Col>
                        </Row>
                    </div>
                );
            })}
        </div>
    );
};
export default MyApply;
