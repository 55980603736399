import {inject, observer} from 'mobx-react';
import React from 'react';
import OpcDA from './opcda';
import OpcUA from './opcua';

const TelegrafSetting = () => {
    return (
        <>
            <OpcDA />
            <div className="setting-item border-top">
                <OpcUA />
            </div>
        </>
    );
};
export default inject('publicData')(observer(TelegrafSetting));
