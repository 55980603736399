import {Button, Col, Input, Modal, Row, Space, message} from 'antd';
import dayjs from 'dayjs';
import React, {useState} from 'react';
import {useNavigate} from 'react-router';
import {request} from '../../../util';
import {inject, observer} from 'mobx-react';
import './chartItem.scss';

const RenderChartItem = (props) => {
  var _a, _b;
  const {item} = props;
  const navigate = useNavigate();
  const [reason, setReason] = useState();
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showCopyModal, setShowCopyModal] = useState(false);
  // 删除节点
  const deleteAction = () => {
    setShowSubmitModal(false);
    request.post('/company/deleteNode', {node_id: item.node_id, reason}, true).then((res) => {
      message.success('节点已删除');
    });
  };
  // 删除节点
  const copyAction = () => {
    setShowCopyModal(false);
    request.post('/company/copyNode', {node_id: item.node_id}, true).then((res) => {
      message.success('节点复制成功');
    });
  };
  // 跳转节点详情
  const gotoChartDetail = (item) => {
    navigate(`/home/chart-info/${item.node_id}`);
  };
  // 编辑节点
  const editAction = (item) => {
    navigate(`/home/edit-chart/${item.node_id}`);
  };
  // 修改记录
  const gotoNodeRecord = (item) => {
    navigate(`/home/chart-record/${item === null || item === void 0 ? void 0 : item.node_id}`);
  };
  const riskData = (_a = props.publicData) === null || _a === void 0 ? void 0 : _a.getRiskForValue(item.risk_value);
  // 获取当前警告等级   为后面的闪烁动画做准备
  const riskAlertValue = (_b = props.publicData) === null || _b === void 0 ? void 0 : _b.getRiskAlertValue();
  return (
    <div className="list-item com-white-block">
      <Row justify={'space-between'} align={'middle'}>
        <Col md={20} xs={16}>
          <span className="font-size-xl font-weight-bold">{item.name}</span>
          {riskData && (
            <span
              className={`risk-tags ${riskAlertValue && riskAlertValue <= riskData.value ? 'risk-tags-animation' : ''}`}
              style={{backgroundColor: riskData?.color}}>
              {riskData?.label}
            </span>
          )}
        </Col>
        <Col md={4} xs={8} className="com-align_right text-color-second">
          编号:{item.number}
        </Col>
      </Row>
      <Row className="mt-base mb-10" justify={'space-between'} align={'top'}>
        <Col md={18} xs={17}>
          意图：{item.intent}
        </Col>
        <Col md={6} xs={7} className="com-align_right text-color-second">
          创建时间:{dayjs(item.create_time).format('YYYY-MM-DD HH:mm')}
        </Col>
      </Row>
      <Row justify={'space-between'} align={'middle'}>
        <Col md={12} xs={24} className="text-color-second mt-10">
          <Space size={'large'} align={'start'}>
            <span className="deviation">P&ID:{item.pid}</span>
            <span className="deviation">危害:{item.harm || '无'}</span>
          </Space>
        </Col>
        <Col md={12} xs={24} className="com-align_right">
          <Space className="wrap" size={'small'} align={'start'}>
            <Button className="mt-10" size="small" type="primary" shape="default" onClick={() => gotoChartDetail(item)}>
              查看详情
            </Button>
            <Button className="com-btn com-btn-block yellow mt-10 hide-on-mobile" size="small" shape="default" onClick={() => gotoNodeRecord(item)}>
              修改记录
            </Button>
            <Button
              shape="default"
              size="small"
              className="com-btn com-btn-block green mt-10 hide-on-mobile"
              onClick={(e) => {
                e.stopPropagation();
                editAction(item);
              }}>
              编辑节点
            </Button>

            <Button
              shape="default"
              size="small"
              type="primary"
              danger
              className="mt-10 hide-on-mobile"
              onClick={(e) => {
                e.stopPropagation();
                setShowSubmitModal(true);
              }}>
              删除节点
            </Button>
            <Button
              shape="default"
              size="small"
              type="primary"
              className="mt-10 hide-on-mobile"
              onClick={(e) => {
                e.stopPropagation();
                setShowCopyModal(true);
              }}>
              复制节点
            </Button>
          </Space>
        </Col>
      </Row>
      <Modal
        title="删除节点"
        okButtonProps={{shape: 'round'}}
        cancelButtonProps={{shape: 'round'}}
        visible={showSubmitModal}
        onOk={deleteAction}
        onCancel={() => setShowSubmitModal(false)}>
        <Col span={24}>
          <Input placeholder="请输入删除原因" value={reason} onChange={(e) => setReason(e.target.value)} />
        </Col>
      </Modal>
      <Modal
        title={`复制节点: ${item.name}`}
        okButtonProps={{shape: 'round'}}
        cancelButtonProps={{shape: 'round'}}
        visible={showCopyModal}
        onOk={copyAction}
        onCancel={() => setShowCopyModal(false)}>
        <Col span={24}>
          <span>确定复制节点吗？</span>
        </Col>
      </Modal>
    </div>
  );
};
export default inject('publicData')(observer(RenderChartItem));
