import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, Col, Input, message, Modal, Row, Space} from 'antd';
import React, {useEffect, useState} from 'react';
import {request} from '../../util';
import './index.scss';
const Option = () => {
  useEffect(() => {
    getAllOptionList();
  }, []);
  const [topEventTypeList, setTopEventTypeList] = useState([]);
  const [startEventTypeList, setStartEventTypeList] = useState([]);
  const [deviationTypeList, setDeviationTypeList] = useState([]);
  const [barrierWorkTypeList, setBarrierWorkTypeList] = useState([]);
  const getAllOptionList = () => {
    request.post('/company/getAllOptionList', {}, true).then((res) => {
      setTopEventTypeList(res.topEventTypeList);
      setStartEventTypeList(res.startEventTypeList);
      setDeviationTypeList(res.deviationTypeList);
      setBarrierWorkTypeList(res.barrierWorkTypeList);
    });
  };
  const onTopEventTypeHandle = (action, type, index = 0) => {
    let nowItems = [...topEventTypeList];
    if (action === 'add') {
      nowItems.push({value: topEventTypeList.length - 1});
      setTopEventTypeList(nowItems);
    } else if (action === 'change' && type) {
      nowItems.splice(index, 1, type);
      setTopEventTypeList(nowItems);
    } else if (action === 'delete') {
      nowItems.splice(index, 1);
      setTopEventTypeList(nowItems);
    } else {
      message.error('操作不合法');
    }
  };
  const onStartEventTypeHandle = (action, type, index = 0) => {
    let nowItems = [...startEventTypeList];
    if (action === 'add') {
      nowItems.push({value: startEventTypeList.length - 1});
      setStartEventTypeList(nowItems);
    } else if (action === 'change' && type) {
      nowItems.splice(index, 1, type);
      setStartEventTypeList(nowItems);
    } else if (action === 'delete') {
      nowItems.splice(index, 1);
      setStartEventTypeList(nowItems);
    } else {
      message.error('操作不合法');
    }
  };
  const onDeviationTypeHandle = (action, type, index = 0) => {
    let nowItems = [...deviationTypeList];
    if (action === 'add') {
      nowItems.push({value: deviationTypeList.length - 1});
      setDeviationTypeList(nowItems);
    } else if (action === 'change' && type) {
      nowItems.splice(index, 1, type);
      setDeviationTypeList(nowItems);
    } else if (action === 'delete') {
      nowItems.splice(index, 1);
      setDeviationTypeList(nowItems);
    } else {
      message.error('操作不合法');
    }
  };
  const onBarrierWorkTypeHandle = (action, type, index = 0) => {
    let nowItems = [...barrierWorkTypeList];
    if (action === 'add') {
      nowItems.push({value: barrierWorkTypeList.length - 1});
      setBarrierWorkTypeList(nowItems);
    } else if (action === 'change' && type) {
      nowItems.splice(index, 1, type);
      setBarrierWorkTypeList(nowItems);
    } else if (action === 'delete') {
      nowItems.splice(index, 1);
      setBarrierWorkTypeList(nowItems);
    } else {
      message.error('操作不合法');
    }
  };
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [apply_reason, setApply_reason] = useState();
  // 保存事件
  const saveAction = () => {
    setShowSubmitModal(false);
    if (!apply_reason) {
      message.error('请输入修改原因');
    } else if (topEventTypeList.length === 0) {
      message.error('请输入至少一个顶级事件类型');
    } else if (startEventTypeList.length === 0) {
      message.error('请输入至少一个起始事件类型');
    } else if (deviationTypeList.length === 0) {
      message.error('请输入至少一个偏差类型');
    } else if (barrierWorkTypeList.length === 0) {
      message.error('请输入至少一个保护层工作类型');
    } else {
      const askContent = {
        topEventTypeList: topEventTypeList.filter((type) => !!type.label),
        startEventTypeList: startEventTypeList.filter((type) => !!type.label),
        deviationTypeList: deviationTypeList.filter((type) => !!type.label),
        barrierWorkTypeList: barrierWorkTypeList.filter((type) => !!type.label),
      };
      request.post('/company/askForSetting', {askContent, apply_reason, type: 3}, true).then((res) => {
        message.success('已经提交申请,等待审核中...');
      });
    }
  };
  return (
    <>
      {/* part：设置顶级事件 */}
      <div className="setting-item">
        <div className="setting-title">设置顶级事件</div>
        {topEventTypeList.map((top, index) => (
          <Row className="mt-base option-row" align={'middle'} justify={'center'}>
            <span className="option-label">顶级事件类型:</span>
            <Input
              className="option-input"
              value={top.label}
              placeholder="请输入类型"
              onChange={(e) => onTopEventTypeHandle('change', {...top, label: e.target.value}, index)}
            />
            <MinusCircleOutlined className="delete-btn" onClick={() => onTopEventTypeHandle('delete', top, index)} />
          </Row>
        ))}
        <Button block size={'large'} className="add-btn-dashed mt-lg" type="dashed" onClick={() => onTopEventTypeHandle('add')}>
          <PlusOutlined /> 添加选项
        </Button>
      </div>

      {/* part：设置起始事件 */}
      <div className="setting-item">
        <div className="setting-title">设置起始事件类型</div>
        {startEventTypeList.map((top, index) => (
          <Row className="mt-base option-row" align={'middle'} justify={'center'}>
            <span className="option-label">起始事件类型:</span>
            <Input
              className="option-input"
              value={top.label}
              placeholder="请输入类型"
              onChange={(e) => onStartEventTypeHandle('change', {...top, label: e.target.value}, index)}
            />
            <MinusCircleOutlined className="delete-btn" onClick={() => onStartEventTypeHandle('delete', top, index)} />
          </Row>
        ))}
        <Button block size={'large'} className="add-btn-dashed mt-lg" type="dashed" onClick={() => onStartEventTypeHandle('add')}>
          <PlusOutlined /> 添加选项
        </Button>
      </div>

      {/* part：设置偏差类型 */}
      <div className="setting-item">
        <div className="setting-title">设置偏差类型</div>
        {deviationTypeList.map((top, index) => (
          <Row className="mt-base option-row" align={'middle'} justify={'center'}>
            <span className="option-label">偏差类型:</span>
            <Input
              className="option-input"
              value={top.label}
              placeholder="请输入类型"
              onChange={(e) => onDeviationTypeHandle('change', {...top, label: e.target.value}, index)}
            />
            <MinusCircleOutlined className="delete-btn" onClick={() => onDeviationTypeHandle('delete', top, index)} />
          </Row>
        ))}
        <Button block size={'large'} className="add-btn-dashed mt-lg" type="dashed" onClick={() => onDeviationTypeHandle('add')}>
          <PlusOutlined /> 添加选项
        </Button>
      </div>

      {/* 保护层工作类型 */}
      <div className="setting-item">
        <div className="setting-title">保护层类型</div>
        {barrierWorkTypeList.map((top, index) => (
          <Row className="mt-base option-row" align={'middle'} justify={'center'}>
            <span className="option-label">保护层类型:</span>
            <Input
              className="option-input"
              value={top.label}
              placeholder="请输入类型"
              onChange={(e) => onBarrierWorkTypeHandle('change', {...top, label: e.target.value}, index)}
            />
            <MinusCircleOutlined className="delete-btn" onClick={() => onBarrierWorkTypeHandle('delete', top, index)} />
          </Row>
        ))}
        <Button block size={'large'} className="add-btn-dashed mt-lg" type="dashed" onClick={() => onBarrierWorkTypeHandle('add')}>
          <PlusOutlined /> 添加选项
        </Button>
      </div>

      <Row justify={'center'} align={'middle'} className="mt-lg">
        <Space size={'middle'}>
          {/* <Button size={'middle'} onClick={() => navigate(-1)} shape="default" className="com-w-btn">
            返回
          </Button> */}
          <Button size="middle" shape="default" className="com-btn com-btn-block green" onClick={() => setShowSubmitModal(true)}>
            提交申请
          </Button>
        </Space>
      </Row>

      <Modal
        title="修改原因"
        okButtonProps={{shape: 'round'}}
        cancelButtonProps={{shape: 'round'}}
        visible={showSubmitModal}
        onOk={saveAction}
        onCancel={() => setShowSubmitModal(false)}>
        <Col span={24}>
          <Input placeholder="请输入修改原因" value={apply_reason} onChange={(e) => setApply_reason(e.target.value)} />
        </Col>
      </Modal>
    </>
  );
};
export default Option;
