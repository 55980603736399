import React from 'react';
import './pubChart.scss';

const ScaleWrapper = ({scale, children}) => {
  const style = {
    transform: `scale(${scale})`,
    transformOrigin: 'top left', // 或者其它你希望的原点
    width: `${100 / scale}%`, // 保持容器在缩放后覆盖原有的宽度
    height: `${100 / scale}%`, // 保持容器在缩放后覆盖原有的高度
    overflow: 'scroll',
    scrollbarWidth: 'none' /* 对于Firefox */,
    msOverflowStyle: 'none',
  };

  return (
    <div style={style} className="element">
      {children}
    </div>
  );
};

export default ScaleWrapper;
