/* eslint-disable react-hooks/exhaustive-deps */
import {Select, DatePicker} from 'antd';
import React, {useEffect, useMemo, useState} from 'react';
import {request} from '../../util';
import './index.scss';
import dayjs from 'dayjs';
import Echarts from 'echarts-for-react';
// var moment = require('moment');
const NodeRiskChart = (props) => {
  const [selectNode, setSelectNode] = useState('');
  const [nodeList, setNodeList] = useState([]);
  const [timeRange, setTimeRange] = useState([dayjs().subtract(1, 'months'), dayjs()]);
  const loadNodeList = () => {
    request.post('/company/queryNodeList', {}, true).then((res) => {
      setNodeList(res.nodeList);
      if (res.nodeList[0]) {
        setSelectNode(res.nodeList[0].node_id);
      }
    });
  };
  const [riskHistoryList, setRiskHistoryList] = useState([]);
  const loadNodeRiskValueList = () => {
    const param = {
      node_id: selectNode,
      startTime: dayjs(timeRange[0]).format('YYYY-MM-DD HH:mm:00'),
      endTime: dayjs(timeRange[1]).format('YYYY-MM-DD HH:mm:59'),
    };
    request.post('/company/queryNodeRiskHistoryList', param, true).then((res) => {
      setRiskHistoryList(res.riskHistoryList);
    });
  };
  useEffect(() => {
    loadNodeList();
  }, []);
  useEffect(() => {
    loadNodeRiskValueList();
  }, [selectNode, timeRange]);
  const his_option = useMemo(() => {
    const selectedNode = nodeList.find((node) => node.node_id === selectNode);
    const nodeName = selectedNode?.name || '--';
    const data = riskHistoryList?.map((his) => [dayjs(his.create_time).valueOf(), his.risk_value]);
    let xMin = dayjs(timeRange[0]).valueOf();
    let xMax = dayjs(timeRange[1]).valueOf();
    if (data.length) {
      if (xMin !== data[0][0]) {
        data.unshift([xMin, data[0][1]]);
      }
      if (xMax !== data[data.length - 1][0]) {
        data.push([xMax, data[data.length - 1][1]]);
      }
    }
    const loadText = (param) => {
      const targetData = param[0];
      return `${targetData.seriesName} <br/> 等级${targetData.data[1]} <br/> ${dayjs(targetData.data[0]).format('YYYY-MM-DD HH:mm:ss')} `;
    };
    return {
      grid: {
        top: '3%',
      },
      tooltip: {
        trigger: 'axis',
        formatter: loadText,
      },
      xAxis: {
        min: xMin,
        max: xMax,
        type: 'value',
        splitLine: {
          show: false,
        },
        axisLabel: {
          formatter: (v) => dayjs(v).format('YYYY-MM-DD'),
          rotate: -20,
        },
      },
      yAxis: {
        min: 0,
        max: 5,
        type: 'value',
        minInterval: 1,
        splitLine: {
          show: true,
          lineStyle: {
            color: '#D4D4D822',
          },
        },
      },
      legend: {
        bottom: 10,
        icon: 'rect',
        textStyle: {
          color: '#ffffff99',
        },
      },
      series: [
        {
          name: nodeName,
          type: 'line',
          step: 'start',
          data: data,
        },
      ],
    };
  }, [riskHistoryList]);
  return (
    <div className="risk-chart-container">
      <div className="search-view">
        <div className="filter">
          <div className="label">节点：</div>
          <Select className="select" value={selectNode} onChange={(v) => setSelectNode(v)}>
            {nodeList.map((node) => (
              <Select.Option value={node.node_id || ''} key={node.node_id}>
                {node.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="filter">
          <div className="label">时间范围：</div>
          <DatePicker.RangePicker
            className="time-picker"
            showTime
            format="YYYY-MM-DD HH:mm"
            clearIcon={false}
            value={timeRange}
            onChange={(v) => setTimeRange(v)}></DatePicker.RangePicker>
        </div>
      </div>

      <div className="alert-echarts-view">
        <Echarts
          className="echarts-class"
          option={his_option} // option：图表配置项
          style={{height: '100%'}}
        />
      </div>
    </div>
  );
};
export default NodeRiskChart;
