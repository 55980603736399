/* eslint-disable react-hooks/exhaustive-deps */
// noinspection JSUnresolvedVariable,JSValidateTypes

import {LogoutOutlined} from '@ant-design/icons';
import {Select, Table, Button, Space} from 'antd';
import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {request} from '../../util';
import './index.scss';
import dayjs from 'dayjs';
import Echarts from 'echarts-for-react';
import {inject, observer} from 'mobx-react';
import config from '../../config';
import log from 'loglevel';
log.setLevel(config.debug ? log.levels.DEBUG : log.levels.ERROR);
const Dashboard = (props) => {
  let _a;
  const navigate = useNavigate();
  const [riskNodeList, setRiskNodeList] = useState([]);
  const [nodeDistribution, setNodeDistribution] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [companySeries, setCompanySeries] = useState([]);
  const [selectDepartment, setSelectDepartment] = useState(0);
  const [departmentSeries, setDepartmentSeries] = useState([]);
  const [risks, setRisks] = useState([]);
  /**
   *  高风险节点统计
   */
  const queryHighRiskNode = () => {
    request.post('/company/queryHighRiskNode').then((res) => {
      let _a;
      const nodeList = (_a = res.nodeList) === null || _a === void 0 ? void 0 : _a.map((v) => Object.assign(Object.assign({}, v), {key: v.node_id}));
      setRiskNodeList(nodeList);
    });
  };
  /**
   *  节点分步
   *  计算风险等级统计
   */
  const queryNodeDistribution = () => {
    request.post('/company/queryNodeDistribution').then((res) => {
      const nds = res === null || res === void 0 ? void 0 : res.map((v) => Object.assign(Object.assign({}, v), {key: v.value}));
      nds === null || nds === void 0
        ? void 0
        : nds.map((nd) => {
            let _a;
            return (_a = nd.nodeList) === null || _a === void 0 ? void 0 : _a;
          });
      setNodeDistribution(nds);
      const _risks = res ? res.map((v) => Object.assign(Object.assign({}, v), {key: v.value})) : [];
      setRisks(_risks);
    });
  };
  const timeRange = useMemo(() => {
    const config = props.publicData?.optionList.configData?.find((c) => c.keyword === 'homeStatisticsTime');
    const dayCount = parseInt(config?.value || '14');
    // const days = 14;
    console.log(dayCount);
    const startTime = dayjs().subtract(dayCount, 'day').format('YYYY-MM-DD');
    const endTime = dayjs().format('YYYY-MM-DD');
    const dateRange = [];
    for (let i = dayCount; i >= 0; i--) {
      dateRange.push(dayjs().subtract(i, 'day').format('YYYY-MM-DD'));
    }
    setDateRange(dateRange);
    return {startTime, endTime};
  }, [(_a = props.publicData) === null || _a === void 0 ? void 0 : _a.optionList.configData]);
  /**
   *  获取风险次数统计
   */
  const getCompanyRiskCompany = () => {
    request.post('/company/getRiskCount', Object.assign({}, timeRange)).then((res) => {
      let series = [];
      for (const lv of res.levels) {
        series.push({
          name: `等级${lv.value}`,
          type: 'line',
          data: res.riskCount.filter((r) => r.lv === lv.value).map((t) => t.total),
        });
      }
      setCompanySeries(series);
    });
  };
  useEffect(() => {
    if (timeRange) {
      getCompanyRiskCompany();
    }
  }, [timeRange]);
  /**
   *  按部门获取风险次数统计
   */
  const getDepartmentRiskCount = () => {
    const param = Object.assign(Object.assign({}, timeRange), {department_id: selectDepartment || ''});
    request.post('/company/getRiskCount', param).then((res) => {
      let series = [];
      for (const lv of res.levels) {
        series.push({
          name: `等级${lv.value}`,
          type: 'line',
          data: res.riskCount.filter((r) => r.lv === lv.value).map((t) => t.total),
        });
      }
      setDepartmentSeries(series);
    });
  };
  useEffect(() => {
    if (timeRange) {
      getDepartmentRiskCount();
    }
  }, [timeRange, selectDepartment]);
  useEffect(() => {
    queryHighRiskNode();
    queryNodeDistribution();
  }, []);
  const getChartOption = (series) => {
    let _a, _b;
    return {
      tooltip: {
        trigger: 'axis',
      },
      textStyle: {
        color: '#ffffff99',
      },
      color:
        (_b = (_a = props.publicData) === null || _a === void 0 ? void 0 : _a.optionList.riskList) === null || _b === void 0
          ? void 0
          : _b.map((v) => v.color),
      legend: {
        bottom: 10,
        icon: 'rect',
        textStyle: {
          color: '#ffffff99',
        },
      },
      grid: {
        top: '13%',
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: dateRange,
        name: '时间',
      },
      yAxis: {
        type: 'value',
        name: '次数',
        nameLocation: 'end',
        minInterval: 1,
        splitLine: {
          show: true,
          lineStyle: {
            color: '#D4D4D822',
          },
        },
      },
      series,
    };
  };
  // 总数量
  const totalNodes = useMemo(() => {
    return nodeDistribution.reduce((pre, cur) => pre + cur.count, 0);
  }, [nodeDistribution]);
  const getRiskColor = (riskValue) => {
    let _a, _b, _c;
    return (
      ((_c =
        (_b = (_a = props.publicData) === null || _a === void 0 ? void 0 : _a.optionList.riskList) === null || _b === void 0
          ? void 0
          : _b.find((r) => r.value === riskValue)) === null || _c === void 0
        ? void 0
        : _c.color) || '#ffffffcc'
    );
  };
  const getRiskText = (text, riskValue) => {
    return <div style={{color: getRiskColor(riskValue)}}>{text}</div>;
  };
  const nodeColumns = [
    {
      title: '节点名称',
      dataIndex: 'name',
      key: 'name',
      width: 100,
      render: (value, data) => getRiskText(value, data.risk_value),
    },
    {
      title: '风险等级',
      dataIndex: 'risk_value',
      key: 'risk_value',
      render: (value, data) => getRiskText(`等级${value}`, data.risk_value),
      width: 100,
    },
    {
      title: '所属部门',
      dataIndex: 'departmentName',
      key: 'departmentName',
      width: 100,
      render: (value, data) => getRiskText(value, data.risk_value),
    },
    {
      title: '负责人',
      dataIndex: 'leaderName',
      key: 'leaderName',
      width: 50,
      render: (value, data) => getRiskText(value, data.risk_value),
    },
    {
      title: '负责人手机号码',
      dataIndex: 'leaderPhone',
      key: 'leaderPhone',
      width: 100,
      render: (value, data) => getRiskText(value, data.risk_value),
    },
  ];
  const distributionColumns = [
    {
      title: '风险等级',
      dataIndex: 'value',
      key: 'value',
      render: (value, data) => getRiskText(`等级${value}`, data.value),
      width: 50,
    },
    {
      title: '数量',
      dataIndex: 'count',
      key: 'count',
      render: (value, data) => getRiskText(value, data.risk_value),
      width: 50,
    },
    {
      title: '节点占比',
      dataIndex: 'score',
      key: 'score',
      render: (value, data) => getRiskText(`${value}%`, data.risk_value),
      width: 100,
    },
  ];
  const expandedRowRender = (record) => {
    return <Table columns={nodeColumns} dataSource={record.nodeList} pagination={false} onRow={hiNodeRow} />;
  };
  // 高风险节点事件
  const hiNodeRow = (record) => {
    return {
      onMouseEnter: () => {
        setWatchNodeInfo(record);
      },
      onMouseLeave: () => {
        setWatchNodeInfo(undefined);
      },
      onDoubleClick: () => {
        navigate(`/home/chart-info/${record.node_id}`);
      },
    };
  };

  const rowClassNameFunc = (record, index) => {
    if (index % 2 === 1) {
      return 'rowClass-double';
    } else {
      return 'rowClass-single';
    }
  };
  const [nodeList, setNodeList] = useState([]);
  const [selectNode, setSelectNode] = useState('');
  const [riskHistoryList, setRiskHistoryList] = useState([]);
  const loadNodeRiskValueList = () => {
    const param = Object.assign({node_id: selectNode}, timeRange);
    request.post('/company/queryNodeRiskHistoryList', param, true).then((res) => {
      setRiskHistoryList(res.riskHistoryList);
    });
  };
  const loadNodeList = () => {
    request.post('/company/queryNodeList', {}, true).then((res) => {
      setNodeList(res.nodeList);
      if (res.nodeList[0]) {
        setSelectNode(res.nodeList[0].node_id);
      }
    });
  };
  useEffect(() => {
    if (selectNode && timeRange) {
      loadNodeRiskValueList();
    }
  }, [selectNode, timeRange]);
  useEffect(() => {
    loadNodeList();
  }, []);
  // const getSolutionStats =() => {
  //     request.post('/company/getSolutionStats', {}, true).then((res) => {
  //         console.log('getSolutionStats', res);
  //     });
  // }
  // useEffect(() => {
  //     getSolutionStats();
  // }, []);
  const his_option = useMemo(() => {
    if (Object.keys(timeRange).length === 0) return {};
    const selectedNode = nodeList.find((node) => node.node_id === selectNode);
    const nodeName = selectedNode?.name || '--';
    const data = riskHistoryList?.map((his) => [dayjs(his.create_time).valueOf(), his.risk_value]);
    let xMin = dayjs(timeRange.startTime).valueOf();
    let xMax = dayjs(timeRange.endTime).valueOf();
    if (data.length) {
      if (xMin != data[0][0]) {
        data.unshift([xMin, data[0][1]]);
      }
      if (xMax != data[data.length - 1][0]) {
        data.push([xMax, data[data.length - 1][1]]);
      }
    }
    const loadText = (param) => {
      const targetData = param[0];
      return `${targetData.seriesName} <br/> 等级${targetData.data[1]} <br/> ${dayjs(targetData.data[0]).format('YYYY-MM-DD HH:mm:ss')} `;
    };
    return {
      grid: {
        top: '3%',
      },
      tooltip: {
        trigger: 'axis',
        formatter: loadText,
      },
      textStyle: {
        color: '#ffffff99',
      },
      xAxis: {
        min: xMin,
        max: xMax,
        type: 'value',
        name: '时间',
        splitLine: {
          show: false,
        },
        axisLabel: {
          formatter: (v) => dayjs(v).format('YYYY-MM-DD'),
          rotate: -20,
        },
      },
      yAxis: {
        name: '风险',
        nameLocation: 'end',
        nameTextStyle: {
          color: '#f00',
          backgroundColor: '#0f0',
        },
        type: 'value',
        minInterval: 1,
        splitLine: {
          show: true,
          lineStyle: {
            color: '#D4D4D822',
          },
        },
      },
      legend: {
        bottom: 10,
        icon: 'rect',
        textStyle: {
          color: '#ffffff99',
        },
      },
      series: [
        {
          name: nodeName,
          type: 'line',
          step: 'start',
          data: data,
        },
      ],
    };
  }, [riskHistoryList]);
  const backAction = () => {
    navigate('/home/risk-node');
  };
  const [watchNodeInfo, setWatchNodeInfo] = useState();
  return (
    <div className="risk-dynamic-page">
      <div className="header">
        <div className="left" style={{width: '160px'}}></div>
        <div className="title-name">风险动态</div>
        <div className="right">
          <Space>
            <Button type="link" size="large" target="_blank" href="http://47.95.209.220:3000">
              安全阀
            </Button>
            <Button type="link" size="large" target="_blank" href="http://47.95.209.220:3001">
              隔离手阀
            </Button>
            <LogoutOutlined className="out" onClick={backAction} />
          </Space>
        </div>
      </div>
      <div className="content-view">
        <div className="hm-table-view flex-order-1">
          <div className="title">高风险节点</div>
          <Table
            className="table"
            pagination={false}
            dataSource={riskNodeList}
            columns={nodeColumns}
            rowClassName={rowClassNameFunc}
            scroll={{x: 600, y: 'calc((100vh - 62px - 90px ) / 2 - 150px)'}}
            size="small"
            onRow={hiNodeRow}
          />
        </div>
        <div className="num-view flex-order-0">
          <div className="num-view-line1">
            <div className="num-item">
              <div className="num-text color1">1</div>
              <div className="label-text">企业</div>
            </div>
            <div className="num-item">
              <div className="num-text color2">{riskNodeList.length}</div>
              <div className="label-text">企业重大危险源</div>
            </div>
            <div className="num-item">
              <div className="num-text color1">{totalNodes}</div>
              <div className="label-text">节点</div>
            </div>
          </div>
          {risks.length > 1 && (
            <div className="num-view-line2">
              <div className="num-item">
                <div className="num-text color3" style={{color: getRiskColor(risks[0].value)}}>
                  {risks[0].count}
                </div>
                <div className="label-text">{risks[0].label} 风险</div>
              </div>
              <div className="num-item">
                <div className="num-text color3" style={{color: getRiskColor(risks[1].value)}}>
                  {risks[1].count}
                </div>
                <div className="label-text">{risks[1].label} 风险</div>
              </div>
            </div>
          )}
          {watchNodeInfo && (
            <div className="tip-view">
              <div className="text">节点名称：{watchNodeInfo.name}</div>
              <div className="text">所属部门：{watchNodeInfo.departmentName}</div>
              <div className="text">负责人姓名：{watchNodeInfo.leaderName}</div>
              <div className="text">负责人手机号：{watchNodeInfo.leaderPhone}</div>
              <div className="text">节点编号：{watchNodeInfo.number}</div>
              <div className="text">P&ID图号：{watchNodeInfo.pid}</div>
              <div className="text">设计意图：{watchNodeInfo.intent}</div>
              <div className="text">危害：{watchNodeInfo.harm}</div>
              <div className="text">当前风险：等级{watchNodeInfo.risk_value}</div>
              {/* <div className='text'>风险变更时间：{dayjs(watchNodeInfo.risk_value_update_time).format('YYYY-MM-DD HH:mm:ss')}</div>
                                    <div className='text'>最近修改时间：{dayjs(watchNodeInfo.update_date).format('YYYY-MM-DD HH:mm:ss')}</div> */}
            </div>
          )}
        </div>
        <div className="hm-table-view flex-order-2">
          <div className="title">节点风险分布</div>
          <Table
            className="table"
            pagination={false}
            dataSource={nodeDistribution}
            columns={distributionColumns}
            rowClassName={rowClassNameFunc}
            expandable={{expandedRowRender}}
            scroll={{x: 600, y: 'calc((100vh - 62px - 90px ) / 2 - 150px)'}}
            size="small"
          />
        </div>
        <div className="hm-table-view flex-order-3">
          <div className="title">企业级风险趋势</div>
          <Echarts
            className="echarts-class"
            option={getChartOption(companySeries)} // option：图表配置项
            notMerge={true}
            lazyUpdate={true}
            style={{height: 'calc((100vh - 62px - 90px ) / 2 - 90px)'}}
          />
        </div>
        <div className="center-table-view flex-order-4">
          <div className="title-view">
            <div className="center-title">部门级风险趋势</div>
            <Select className="dep-select" placeholder="请选择部门" value={selectDepartment} onSelect={(v) => setSelectDepartment(v)}>
              <Select.Option value={0}>全部</Select.Option>
              {props.publicData?.optionList.departmentList?.map((dep) => (
                <Select.Option value={dep.department_id} key={dep.department_id}>
                  {dep.name}
                </Select.Option>
              ))}
            </Select>
          </div>

          <Echarts
            className="echarts-class"
            option={getChartOption(departmentSeries)} // option：图表配置项
            notMerge={true}
            lazyUpdate={true}
            style={{height: 'calc((100vh - 62px - 90px ) / 2 - 90px)'}}
          />
        </div>
        <div className="hm-table-view flex-order-5">
          <div className="title-view">
            <div className="center-title">节点级风险趋势</div>
            <Select className="dep-select" placeholder="请选节点" value={selectNode} onSelect={(v) => setSelectNode(v)}>
              {nodeList?.map((node) => (
                <Select.Option value={node.node_id || ''} key={node.node_id || ''}>
                  {node.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <Echarts
            className="echarts-class"
            option={his_option}
            notMerge={true}
            lazyUpdate={true}
            style={{height: 'calc((100vh - 62px - 90px ) / 2 - 90px)'}}
          />
        </div>
      </div>
    </div>
  );
};
export default inject('publicData')(observer(Dashboard));
