import { action, observable } from 'mobx';
import config from '../../config';
import log from 'loglevel';
log.setLevel(config.debug ? log.levels.DEBUG : log.levels.ERROR);
const nodeInfo = observable({
    node_id: '',
    name: '',
    number: '',
    intent: '',
    pid: '',
    harm: '',
    department_id: '',
    streamList: [],
});
const setNodeInfo = action(function (node) {
    nodeInfo.node_id = node.node_id;
    nodeInfo.name = node.name;
    nodeInfo.number = node.number;
    nodeInfo.intent = node.intent;
    nodeInfo.pid = node.pid;
    nodeInfo.harm = node.harm;
    nodeInfo.department_id = node.department_id;
    nodeInfo.streamList = node.streamList;
});
// 添加事件流
const addStream = action(function (index, stream) {
    const newList = [...nodeInfo.streamList];
    newList.splice(index, 0, stream);
    nodeInfo.streamList = newList;
});
// 更新事件流
const updateStream = action(function (index, stream) {
    const newList = [...nodeInfo.streamList];
    newList[index] = stream;
    nodeInfo.streamList = newList;
});
// 删除事件流
const deleteStream = action(function (index) {
    const newList = [...nodeInfo.streamList];
    newList.splice(index, 1);
    nodeInfo.streamList = newList;
});
// 复制事件流
const copyStream = action(function (index) {
    const newList = [...nodeInfo.streamList];
    const targetSream = newList[index];
    const copyStream = Object.assign(Object.assign({}, targetSream), { stream_id: undefined, startEventList: targetSream.startEventList.map((s) => {
            var _a;
            s.barrierList = (_a = s.barrierList) === null || _a === void 0 ? void 0 : _a.map((b) => (Object.assign(Object.assign({}, b), { barrier_id: undefined })));
            return Object.assign(Object.assign({}, s), { start_event_id: undefined });
        }), resultList: targetSream.resultList.map((r) => {
            var _a;
            r.barrierList = (_a = r.barrierList) === null || _a === void 0 ? void 0 : _a.map((b) => (Object.assign(Object.assign({}, b), { barrier_id: undefined })));
            return Object.assign(Object.assign({}, r), { result_id: undefined });
        }) });
    newList.push(copyStream);
    nodeInfo.streamList = newList;
});
// 添加起始事件
const addStartEvent = action(function (streamIndex, startIndex, startEvent) {
    nodeInfo.streamList = nodeInfo.streamList.map((stream, si) => {
        if (si === streamIndex) {
            const newList = [...stream.startEventList];
            newList.splice(startIndex, 0, startEvent);
            stream.startEventList = newList;
        }
        return stream;
    });
});
// 编辑起始事件
const updateStartEvent = action(function (streamIndex, startIndex, startEvent) {
    nodeInfo.streamList = nodeInfo.streamList.map((stream, si) => {
        if (si === streamIndex) {
            const newList = [...stream.startEventList];
            newList[startIndex] = startEvent;
            stream.startEventList = newList;
        }
        return stream;
    });
});
// 删除起始事件
const deleteStartEvent = action(function (streamIndex, startIndex) {
    let startEvent;
    nodeInfo.streamList = nodeInfo.streamList.map((stream, si) => {
        if (si === streamIndex) {
            const newList = [...stream.startEventList];
            startEvent = Object.assign({}, stream.startEventList[startIndex]);
            newList.splice(startIndex, 1);
            stream.startEventList = newList;
        }
        return stream;
    });
    return startEvent;
});
// 复制起始事件
const copyStartEvent = action(function (streamIndex, startIndex) {
    nodeInfo.streamList = nodeInfo.streamList.map((stream, si) => {
        var _a;
        if (si === streamIndex) {
            const newList = [...stream.startEventList];
            newList.push(Object.assign(Object.assign({}, newList[startIndex]), { start_event_id: undefined, barrierList: (_a = newList[startIndex].barrierList) === null || _a === void 0 ? void 0 : _a.map((b) => (Object.assign(Object.assign({}, b), { barrier_id: undefined }))) }));
            stream.startEventList = newList;
        }
        return stream;
    });
});
// 添加后果
const addResult = action(function (streamIndex, resultIndex, result) {
    nodeInfo.streamList = nodeInfo.streamList.map((stream, si) => {
        if (si === streamIndex) {
            const newList = [...stream.resultList];
            newList.splice(resultIndex, 0, result);
            stream.resultList = newList;
        }
        return stream;
    });
});
// 添加后果
const updateResult = action(function (streamIndex, resultIndex, result) {
    nodeInfo.streamList = nodeInfo.streamList.map((stream, si) => {
        if (si === streamIndex) {
            const newList = [...stream.resultList];
            newList.splice(resultIndex, 1, result);
            stream.resultList = newList;
        }
        return stream;
    });
});
// 删除后果
const deleteResult = action(function (streamIndex, resultIndex) {
    let deleteResult;
    nodeInfo.streamList = nodeInfo.streamList.map((stream, si) => {
        if (si === streamIndex) {
            deleteResult = Object.assign({}, stream.resultList[resultIndex]);
            const newList = [...stream.resultList];
            newList.splice(resultIndex, 1);
            stream.resultList = newList;
        }
        return stream;
    });
    return deleteResult;
});
// 复制后果
const copyResult = action(function (streamIndex, resultIndex) {
    nodeInfo.streamList = nodeInfo.streamList.map((stream, si) => {
        var _a;
        if (si === streamIndex) {
            const newList = [...stream.resultList];
            newList.push(Object.assign(Object.assign({}, newList[resultIndex]), { result_id: undefined, barrierList: (_a = newList[resultIndex].barrierList) === null || _a === void 0 ? void 0 : _a.map((b) => (Object.assign(Object.assign({}, b), { barrier_id: undefined }))) }));
            stream.resultList = newList;
        }
        return stream;
    });
});
// 添加起始事件中的保护层
const addBarrier = action(function (streamIndex, from, fromIndex, barrierIndex, barrier) {
    nodeInfo.streamList = nodeInfo.streamList.map((stream, si) => {
        if (si === streamIndex) {
            const listKey = from === 'start' ? 'startEventList' : 'resultList';
            stream[listKey] = stream[listKey].map((value, ai) => {
                if (ai === fromIndex) {
                    const newList = [...value.barrierList];
                    newList.splice(barrierIndex, 0, barrier);
                    value.barrierList = newList;
                }
                return value;
            });
        }
        return stream;
    });
});
// 删除保护层
const deleteBarrier = action(function (streamIndex, from, fromIndex, barrierIndex) {
    let deleteBarrier;
    nodeInfo.streamList = nodeInfo.streamList.map((stream, si) => {
        if (si === streamIndex) {
            const listKey = from === 'start' ? 'startEventList' : 'resultList';
            stream[listKey] = stream[listKey].map((value, ai) => {
                if (ai === fromIndex) {
                    const newList = [...value.barrierList];
                    log.debug(JSON.parse(JSON.stringify(newList)));
                    deleteBarrier = Object.assign({}, newList[barrierIndex]);
                    newList.splice(barrierIndex, 1);
                    value.barrierList = newList;
                    log.debug(JSON.parse(JSON.stringify(newList)));
                }
                return value;
            });
        }
        return stream;
    });
    return deleteBarrier;
});
// 编辑保护层
const updateBarrier = action(function (streamIndex, from, fromIndex, barrierIndex, barrier) {
    nodeInfo.streamList = nodeInfo.streamList.map((stream, si) => {
        if (si === streamIndex) {
            const listKey = from === 'start' ? 'startEventList' : 'resultList';
            stream[listKey] = stream[listKey].map((value, ai) => {
                if (ai === fromIndex) {
                    const newList = [...value.barrierList];
                    newList[barrierIndex] = barrier;
                    value.barrierList = newList;
                }
                return value;
            });
        }
        return stream;
    });
});
// 移动保护层位置
const moveBarrier = action(function (from, to) {
    const fromBarrier = deleteBarrier(from.streamIndex, from.from, from.fromIndex, from.barrierIndex);
    if (fromBarrier)
        addBarrier(to.streamIndex, to.from, to.fromIndex, to.barrierIndex, fromBarrier);
});
// 复制保护层
const copyBarrier = action(function (streamIndex, from, fromIndex, barrierIndex) {
    nodeInfo.streamList = nodeInfo.streamList.map((stream, si) => {
        if (si === streamIndex) {
            const listKey = from === 'start' ? 'startEventList' : 'resultList';
            stream[listKey] = stream[listKey].map((value, ai) => {
                if (ai === fromIndex) {
                    const newList = [...value.barrierList];
                    newList.push(Object.assign(Object.assign({}, newList[barrierIndex]), { barrier_id: undefined }));
                    value.barrierList = newList;
                }
                return value;
            });
        }
        return stream;
    });
});
//  移动起始事件
const moveStart = action(function (from, to) {
    const fromStart = deleteStartEvent(from.streamIndex, from.startIndex);
    if (fromStart)
        addStartEvent(to.streamIndex, to.startIndex, fromStart);
});
// 移动后果
const moveResult = action(function (from, to) {
    const dResult = deleteResult(from.streamIndex, from.resultIndex);
    if (dResult)
        addResult(to.streamIndex, to.resultIndex, dResult);
});
export default {
    nodeInfo,
    setNodeInfo,
    addStream,
    copyStream,
    addStartEvent,
    updateStartEvent,
    deleteStartEvent,
    copyStartEvent,
    addResult,
    updateResult,
    deleteResult,
    copyResult,
    addBarrier,
    deleteBarrier,
    updateBarrier,
    updateStream,
    deleteStream,
    copyBarrier,
    moveBarrier,
    moveStart,
    moveResult,
};
