import { inject, observer } from 'mobx-react';
import React, { useMemo, useRef, useState } from 'react';
import BarrierModal from './barrierModal';
import BarrierView from './barrierView';
import { createPopoverItem } from './pubComponent';
import config from '../../../config';
import log from 'loglevel';
log.setLevel(config.debug ? log.levels.DEBUG : log.levels.ERROR);
const StartEventView = (props) => {
    let _e, _f, _g, _h;
    const [dragEnter, setDragEnter] = useState(false);
    const startEventViewRef = useRef();
    const [newBarrierIndex, setNewBarrierIndex] = useState(0);
    // 拖拽进入
    const onDragEnter = (e) => {
        // e.preventDefault();
        const source = global.dragItem;
        if (source === 'add_barrier' || source.startsWith('barrier_')) {
            setDragEnter(true);
            log.debug('StartEventView拖拽进入', source);
        }
    };
    const onDragOver = (e) => {
        e.preventDefault();
        const source = global.dragItem;
        if (source === 'add_barrier' || source.startsWith('barrier_')) {
            const barrierList = props.start.barrierList || [];
            let newBarrierIndex_temp = barrierList.length;
            for (let si = 0; si < barrierList.length; si++) {
                const barrier_doc = document.getElementById(`barrier_${props.streamIndex}_start_${props.startIndex}_${si}`);
                if (barrier_doc) {
                    const react = barrier_doc.getBoundingClientRect();
                    if (react.x + react.width > e.clientX) {
                        newBarrierIndex_temp = si;
                        break;
                    }
                }
            }
            log.debug('拖拽位置', newBarrierIndex_temp);
            setNewBarrierIndex(newBarrierIndex_temp);
        }
    };
    // 拖拽离开
    const onDragLeave = (e) => {
        let relatedTarget = e.nativeEvent.relatedTarget;
        if (startEventViewRef.current === relatedTarget || startEventViewRef.current.contains(relatedTarget)) {
            return;
        }
        const source = global.dragItem;
        if (source === 'add_barrier' || source.startsWith('barrier_')) {
            setDragEnter(false);
            log.debug('StartEventView 拖拽离开', source);
        }
    };
    // 拖拽放下
    const onDrop = (e) => {
        var _a;
        const source = global.dragItem;
        setDragEnter(false);
        if (source === 'add_barrier') {
            (_a = barrierModalRef.current) === null || _a === void 0 ? void 0 : _a.open(newBarrierIndex);
        }
        if (source.startsWith('barrier_')) {
            const barrierPosition = source.split('_');
            const fromPosition = {
                streamIndex: parseInt(barrierPosition[1]),
                from: barrierPosition[2],
                fromIndex: parseInt(barrierPosition[3]),
                barrierIndex: parseInt(barrierPosition[4]),
            };
            const toPositoon = {
                streamIndex: props.streamIndex,
                from: 'start',
                fromIndex: props.startIndex,
                barrierIndex: newBarrierIndex,
            };
            props.editNode.moveBarrier(fromPosition, toPositoon);
        }
    };
    const barrierList = useMemo(() => {
        if (dragEnter) {
            const tempBarrierList = [...(props.start.barrierList || [])];
            tempBarrierList.splice(newBarrierIndex, 0, { temp: true });
            return tempBarrierList;
        }
        else {
            return props.start.barrierList;
        }
    }, [dragEnter, newBarrierIndex, props.start.barrierList]);
    // 保护层弹窗
    const barrierModalRef = useRef();
    // 编辑保护层事件
    const onEditBarrier = (data) => {
        var _a;
        (_a = barrierModalRef.current) === null || _a === void 0 ? void 0 : _a.open(data.barrierIndex, data);
    };
    const onSelectBarrier = (data) => {
        props.onSelectBarrier(data);
    };
    // 双击事件
    const onDoubleClick = () => {
        props.onEditStartEvent(props);
    };
    // 开始拖动
    const onDragStart = (e) => {
        log.debug('拖动的item', e.target.id);
        global.dragItem = e.target.id;
    };
    // 起始事件
    const initiatotItem = (
        <div className="initiatot-item" onDoubleClick={onDoubleClick}>
            <div className="initiatot-info">
                <span className="barrier-circle-number">{props.start.number || '1'}</span>
                <span>{props.publicData?.getStartEventType(props.start.start_event_type_id)?.label || '--'}</span>
                <span>{props.publicData?.getDeviationType(props.start.deviation_type_id)?.label || '---'}</span>
            </div>
            <span className="initiatot-type"></span>
        </div>
    );
    const initPopInfo = [];
    const startEventType = (_e = props.publicData) === null || _e === void 0 ? void 0 : _e.getStartEventType(props.start.start_event_type_id);
    initPopInfo.push(`编号:${props.start.number}`);
    initPopInfo.push(`名称:${props.start.info}`);
    initPopInfo.push(`类型:${startEventType === null || startEventType === void 0 ? void 0 : startEventType.label}`);
    initPopInfo.push(`部门:${(_h = (_f = props.publicData) === null || _f === void 0 ? void 0 : _f.getDepartment((_g = props.start) === null || _g === void 0 ? void 0 : _g.department_id)) === null || _h === void 0 ? void 0 : _h.name}`);
    initPopInfo.push(`PFD逻辑:${props.start.pfdTags}`);
    initPopInfo.push(`触发逻辑:${props.start.touchTags}`);
    return (
        <div
            className={`initiatot-view ${dragEnter ? 'drag-enter-border' : ''}`}
            id={`start_event_${props.streamIndex}_${props.startIndex}`}
            ref={startEventViewRef}
            onDragEnter={onDragEnter}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            draggable
            onDragStart={onDragStart}>
            {createPopoverItem(initiatotItem, initPopInfo, 'topLeft')}
            {barrierList?.map((bar, bi) => (
                <BarrierView
                    key={bar.barrier_id}
                    barrier={bar}
                    streamIndex={props.streamIndex}
                    from="start"
                    fromIndex={props.startIndex}
                    barrierIndex={bi}
                    onEditBarrier={onEditBarrier}
                    onSelectBarrier={() => onSelectBarrier(bar)}
                    selectedBarrier={props.selectedBarrier}
                />
            ))}
            <BarrierModal
                ref={barrierModalRef}
                streamIndex={props.streamIndex}
                from="start"
                fromIndex={props.startIndex}
            />
        </div>
    );
};
export default inject('editNode', 'publicData')(observer(StartEventView));
