// noinspection JSUnusedLocalSymbols,JSUnusedGlobalSymbols,JSValidateTypes,JSCheckFunctionSignatures,JSUnresolvedVariable,ES6CheckImport

import {Avatar, Breadcrumb, Button, Col, Input, Modal, Row, Space, Tabs} from 'antd';
import dayjs from 'dayjs';
import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import {request} from '../../util';
import {getRecordDiffList} from './recordDiff';
import './style.scss';
import {inject, observer} from 'mobx-react';
// page:申请审批列表
const ApplyLists = (props) => {
    const navigate = useNavigate();
    const [adopt_reason, setAdopt_reason] = useState();
    const [showSubmitModal, setShowSubmitModal] = useState(false);
    const [actionType, setActionType] = useState();
    const [handleRecord, setHandleRecord] = useState();
    useEffect(() => {
        loadRecordList();
    }, []);
    //   待我处理
    const [myPendingRecordList, setMyPendingRecordList] = useState([]);
    //   审核过的申请
    const [myAdoptRecordList, setMyAdoptRecordList] = useState([]);
    const typeStr = ['未知', '等级修改申请', '参数修改申请', '选项修改申请'];
    const recordStateStr = ['等待审核', '审核通过', '审核驳回', '已撤回'];
    const recordStateClass = ['text-color-primary', 'text-color-green', 'text-color-danger', 'text-color-primary'];
    const loadRecordList = () => {
        request.post('/company/queryMyPendingRecordList', {}, true).then((res) => {
            setMyPendingRecordList(res.recordList);
        });
        request.post('/company/queryMyAdoptRecordList', {}, true).then((res) => {
            setMyAdoptRecordList(res.recordList);
        });
    };
    /**
     *  通过
     */
    const adoptAction = (record) => {
        setActionType('adopt');
        setHandleRecord(record);
        setShowSubmitModal(true);
    };
    /**
     *  驳回
     */
    const rejectAction = (record) => {
        setActionType('reject');
        setHandleRecord(record);
        setShowSubmitModal(true);
    };
    /**
     *  撤回
     */
    const withdrawAction = (record) => {
        request.post('/company/withdrawMyAskRecord', {grade_record_id: record.grade_record_id}, true).then((res) => {
            loadRecordList();
        });
    };
    /**
     *  保存
     */
    const saveAction = () => {
        setShowSubmitModal(false);
        request
            .post('/company/adoptGradeRecord', {
                grade_record_id: handleRecord === null || handleRecord === void 0 ? void 0 : handleRecord.grade_record_id,
                adopt_reason,
                state: actionType === 'adopt' ? 1 : 2
            }, true)
            .then((res) => {
                let _a;
                loadRecordList();
                (_a = props.publicData) === null || _a === void 0 ? void 0 : _a.getAllOptionList();
            });
    };
    const renderPendingItem = (record) => {
        const diffList = getRecordDiffList(record);
        return (
            <div className="com-white-block wait-item mb-10">
                <div className="font-weight-bold font-size-xl">{typeStr[record.type]}</div>
                <Row className="mt-lg">
                    <Col span={12}>
                        申请人： <Avatar size={25} src={record.apply_user?.face}/>{' '}
                        <span className="text-color-second">{record.apply_user?.name}</span>
                    </Col>
                    <Col span={12} className="com-align_right text-color-light">
                        申请时间:{dayjs(record.create_time).format('YYYY-MM-DD HH:mm')}
                    </Col>
                </Row>
                <Row className="mt-base">
                    修改原因：<span className="text-color-second">{record.apply_reason}</span>
                </Row>
                <Row className="mt-base">
                    <Col>修改内容：</Col>
                    {diffList?.map((dif) => (
                        <>
                            <Col style={{fontSize: 14, fontWeight: 'bold'}}>{dif.title}</Col>
                            {dif.diffList.map((content) => (
                                <Col span={24} className="mt-10 text-color-second">
                                    {content}
                                </Col>
                            ))}
                        </>
                    ))}
                </Row>
                <Row className="mt-base com-border-dashed-top pt-base" justify={'end'} align={'middle'}>
                    <Space size={'middle'} className="com-align_right wrap">
                        {record.apply_user_id === config.userInfo?.user_id && (
                            <Button type="primary" danger className="com-btn" size={'small'} shape="default"
                                    onClick={() => withdrawAction(record)}>
                                撤回
                            </Button>
                        )}
                        <Button
                            onClick={() => navigate(`/home/setting-record-detail/${record.grade_record_id}`)}
                            type="primary"
                            size={'small'}
                            shape="default">
                            查看详情
                        </Button>
                        <Button className="com-btn com-btn-block green" size={'small'} shape="default"
                                onClick={() => adoptAction(record)}>
                            通过
                        </Button>
                        <Button className="com-btn" danger type="primary" size={'small'} shape="default"
                                onClick={() => rejectAction(record)}>
                            驳回
                        </Button>
                    </Space>
                </Row>
            </div>
        );
    };
    const renderAdoptItem = (record) => {
        const diffList = getRecordDiffList(record);
        return (
            <div className="com-white-block wait-item mb-10">
                <div className="font-weight-bold font-size-xl">{typeStr[record.type]}</div>
                <Row className="mt-lg">
                    <Col span={12}>
                        申请人：
                        <Avatar size={25} src={record.apply_user?.face} /> <span className="text-color-second">{record.apply_user?.name}</span>
                    </Col>
                    <Col span={12} className="com-align_right text-color-light">
                        申请时间:{dayjs(record.create_time).format('YYYY-MM-DD HH:mm')}
                    </Col>
                </Row>
                <Row className="mt-base">
                    修改原因：<span className="text-color-second">{record.apply_reason}</span>
                </Row>
                <Row className="mt-base">
                    <Col>修改内容：</Col>
                    {diffList?.map((dif) => (
                        <>
                            <Col style={{fontSize: 14, fontWeight: 'bold'}}>{dif.title}</Col>
                            {dif.diffList.map((content) => (
                                <Col span={24} className="mt-10 text-color-second">
                                    {content}
                                </Col>
                            ))}
                        </>
                    ))}
                </Row>
                <Row className="mt-base com-border-dashed-top pt-base" justify={'space-between'} align={'middle'}>
                    <Col span={20}>
                        <Space className="text-color-light" size={'large'}>
                            <div>
                                审批结果：<span className={`${recordStateClass[record.state]} font-weight-bold`}>{recordStateStr[record.state]}</span>
                            </div>
                            <div>
                                审批人：<span>{record.adopt_user?.name}</span>
                            </div>
                            <div>
                                审批时间：<span>{dayjs(record.adopt_time).format('YYYY-MM-DD HH:mm')}</span>
                            </div>
                        </Space>
                    </Col>
                    <Col span={4} className="com-align_right">
                        <Button
                            onClick={() => navigate( `/home/setting-record-detail/${record.grade_record_id}`)}
                            type="primary"
                            size={'small'}
                            shape="default">
                            查看详情
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    };
    return (
        <div className="p-lg">
            {/* part:导航栏 */}
            <Breadcrumb>
                <Breadcrumb.Item>平台管理</Breadcrumb.Item>
                <Breadcrumb.Item>
                    <a href="/" onClick={() => navigate(-1)}>
                        设置
                    </a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>申请列表</Breadcrumb.Item>
            </Breadcrumb>
            {/* part:内容 */}
            <Tabs className="com-white-block pt-10 " defaultActiveKey="1">
                <Tabs.TabPane tab="待处理" key="1">
                    {myPendingRecordList.map(renderPendingItem)}
                </Tabs.TabPane>
                <Tabs.TabPane tab="已处理" key="2">
                    {myAdoptRecordList.map(renderAdoptItem)}
                </Tabs.TabPane>
            </Tabs>

            <Modal
                title="修改原因"
                okButtonProps={{shape: 'round'}}
                cancelButtonProps={{shape: 'round'}}
                visible={showSubmitModal}
                onOk={saveAction}
                onCancel={() => setShowSubmitModal(false)}>
                <Col span={24}>
                    <Input placeholder="请输入修改原因" value={adopt_reason} onChange={(e) => setAdopt_reason(e.target.value)} />
                </Col>
            </Modal>
        </div>
    );
};
export default inject('publicData')(observer(ApplyLists));
