import { Avatar, Breadcrumb, Col, Row } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { request } from '../../util';
const RoleRecordPage = () => {
    const navigate = useNavigate();
    useEffect(() => {
        queryUserRecordList();
        queryRoleList();
    }, []);
    const [recordList, setRecordList] = useState([]);
    const queryUserRecordList = () => {
        request.post('/company/queryUserRecordList', {}, true).then((res) => {
            setRecordList(res.recordList);
        });
    };
    /**
     *  查询职位列表
     */
    const [roleList, serRoleList] = useState([]);
    const queryRoleList = () => {
        request.post('/company/queryRoles', {}, true).then((res) => {
            serRoleList(res.roleList);
        });
    };
    return (
        <div className="p-lg">
            {/* part:导航栏 */}
            <Breadcrumb className="mb-base">
                <Breadcrumb.Item>平台管理</Breadcrumb.Item>
                <Breadcrumb.Item>
                    <span onClick={() => navigate(-1)}>角色配置</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>修改记录</Breadcrumb.Item>
            </Breadcrumb>
            {/* part:内容 */}
            {recordList.map((record) => {
                const depart_old = record.user_old ? JSON.parse(record.user_old) : undefined;
                const depart_new = record.user_new ? JSON.parse(record.user_new) : undefined;
                let texts = [];
                switch (record.action) {
                    case 1:
                        texts.push(`新增人员: ${depart_new?.name}`);
                        break;

                    case 2:
                        texts.push(`删除人员: ${depart_old?.name}`);
                        break;

                    case 3:
                        if (depart_old?.name !== depart_new?.name) {
                            texts.push(`更新人员姓名:${depart_old?.name} 为 ${depart_new?.name}`);
                        }
                        if (depart_old?.role_id !== depart_new?.role_id) {
                            texts.push(`更新人员职位:${roleList.find((d) => d.role_id === depart_old?.role_id)?.title || ''} 为 ${roleList.find((d) => d.role_id === depart_new?.role_id)?.title || ''}`);
                        }
                        if (depart_old?.face !== depart_new?.face) {
                            texts.push(
                                <div>
                                    更新人员头像:
                                    <Avatar size={25} src={depart_old?.face} />
                                    为
                                    <Avatar size={25} src={depart_new?.face} />
                                </div>
                            );
                        }

                        if (depart_old?.number !== depart_new?.number) {
                            texts.push(`更新人员工号:${depart_old?.number} 为 ${depart_new?.number}`);
                        }
                        if (depart_old?.account !== depart_new?.account) {
                            texts.push(`更新人员账号:${depart_old?.account} 为 ${depart_new?.account}`);
                        }
                        break;
                    default:
                        break;
                }
                return (
                    <div className="mb-5 mt-0 com-white-block">
                        <Row justify={'space-between'} className='mb-base'>
                            <Col span={12}>
                                操作人：
                                <Avatar className='mr-5' size={25} src={record.operator_face} />
                                {record.operator_name}
                            </Col>
                            <Col span={12} className='text-color-light com-align_right'>
                                时间: {dayjs(record.create_time).format('YYYY-MM-DD HH:mm')}
                            </Col>

                        </Row>
                        <Row className='com-border-dashed-top pt-base'>
                            <Col span={24}>
                                {texts.map((text) => (
                                    <p>{text}</p>
                                ))}
                            </Col>
                        </Row>
                    </div>
                );
            })}
        </div>
    );
};
export default RoleRecordPage;
