import { Avatar, Breadcrumb, Col, Row } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { request } from '../../util';
const RoleRecord = () => {
    const navigate = useNavigate();
    useEffect(() => {
        queryDepartmentRecordList();
    }, []);
    const [recordList, setRecordList] = useState([]);
    const queryDepartmentRecordList = () => {
        request.post('/company/queryDepartmentRecordList', {}, true).then((res) => {
            setRecordList(res.recordList);
        });
    };
    return (
        <div className="p-lg">
            {/* part:导航栏 */}
            <Breadcrumb className="mb-base">
                <Breadcrumb.Item>平台管理</Breadcrumb.Item>
                <Breadcrumb.Item>
                    <span onClick={() => navigate(-1)}>角色配置</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>修改记录</Breadcrumb.Item>
            </Breadcrumb>
            {/* part:内容 */}
            {recordList.map((record) => {
                const depart_old = record?.department_old ? JSON.parse(record.department_old) : undefined;
                const depart_new = record?.department_new ? JSON.parse(record.department_new) : undefined;
                let text = '';
                switch (record.action) {
                    case 1:
                        text = `新增部门: ${depart_new?.name}`;
                        break;

                    case 2:
                        text = `删除部门: ${depart_old?.name}`;
                        break;

                    case 3:
                        text = `更新部门: ${depart_old?.name} 为 ${depart_new?.name}`;
                        break;
                    default:
                        break;
                }
                return (
                    <div className="mb-5 mt-0 com-white-block">
                        <Row justify={'space-between'}>
                            <Col md={8} sm={24} xs={24}>
                                时间: {dayjs(record.create_time).format('YYYY-MM-DD HH:mm')}
                            </Col>
                            <Col span={8}>{text}</Col>
                            <Col span={8}>
                                操作人：
                                <Avatar size={25} src={record.operator_face} />
                                {record.operator_name}
                            </Col>
                        </Row>
                    </div>
                );
            })}
        </div>
    );
};
export default RoleRecord;
