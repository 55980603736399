import {PlusOutlined} from '@ant-design/icons';
import {Button, Checkbox, Col, Form, Input, message, Modal, Row, Space, Table} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {request} from '../../util';
import './index.scss';
// page:编辑部门
const Position = () => {
    const tableColumns = [
        {
            title: '序号',
            align: 'center',
            render: (text, role, index) => `${index + 1}`,
        },
        {
            title: '职位名称',
            dataIndex: 'title',
            key: 'title',
            align: 'center',
        },
        {
            title: '所属部门',
            dataIndex: 'departmentName',
            key: 'departmentName',
            align: 'center',
        },
        {
            title: '节点读',
            key: 'node_read',
            align: 'center',
            render: (text, role, index) => (role.node_read ? '有' : '无'),
        },
        {
            title: '节点改',
            key: 'node_write',
            align: 'center',
            render: (text, role, index) => (role.node_write ? '有' : '无'),
        },
        {
            title: '申请等级修改',
            key: 'grade_set_apply',
            align: 'center',
            render: (text, role, index) => (role.grade_set_apply ? '有' : '无'),
        },
        {
            title: '审核等级修改',
            key: 'grade_set_adopt',
            align: 'center',
            render: (text, role, index) => (role.grade_set_adopt ? '有' : '无'),
        },
        {
            title: '申请参数修改',
            key: 'parameter_set_apply',
            align: 'center',
            render: (text, role, index) => (role.parameter_set_apply ? '有' : '无'),
        },
        {
            title: '审核参数修改',
            key: 'parameter_set_adopt',
            align: 'center',
            render: (text, role, index) => (role.parameter_set_adopt ? '有' : '无'),
        },
        {
            title: '申请选项修改',
            key: 'option_set_apply',
            align: 'center',
            render: (text, role, index) => (role.option_set_apply ? '有' : '无'),
        },
        {
            title: '审核选项修改',
            key: 'option_set_adopt',
            align: 'center',
            render: (text, role, index) => (role.option_set_adopt ? '有' : '无'),
        },
        {
            title: '用户修改',
            key: 'user_set',
            align: 'center',
            render: (text, role, index) => (role.user_set ? '有' : '无'),
        },
        {
            title: '操作',
            key: 'action',
            align: 'center',
            className: 'com-table-btn-group',
            render: (text, role, index) => (
                <>
                    <Button type="link" onClick={() => editRole(role)}>
                        编辑
                    </Button>
                    <Button type="link" danger onClick={() => deleteRoleAction(role)}>
                        删除
                    </Button>
                </>
            ),
        },
    ];
    // checkbox 多选
    const checkboxOptions = [
        {label: '节点读权限', value: 'node_read'},
        {label: '节点写权限', value: 'node_write'},
        {label: '等级修改申请', value: 'grade_set_apply'},
        {label: '等级修改审批', value: 'grade_set_adopt'},
        {label: '参数设置申请', value: 'parameter_set_apply'},
        {label: '参数设置审批', value: 'parameter_set_adopt'},
        {label: '选项设置申请', value: 'option_set_apply'},
        {label: '选项设置审批', value: 'option_set_adopt'},
    ];
    const navigate = useNavigate();
    const [roleList, serRoleList] = useState([]);
    const queryRoleList = () => {
        request.post('/company/queryRoles', {}, true).then((res) => {
            serRoleList(res.roleList);
        });
    };
    useEffect(() => {
        queryRoleList();
    }, []);
    const roleFormInstance = useRef(null);
    const [currentRole, serCurrentRole] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const addRole = () => {
        setIsModalVisible(true);
        serCurrentRole(undefined);
        setTimeout(() => {
            var _a;
            (_a = roleFormInstance.current) === null || _a === void 0 ? void 0 : _a.resetFields();
        }, 200);
    };
    const editRole = (role) => {
        setIsModalVisible(true);
        serCurrentRole(role);
        setTimeout(() => {
            var _a;
            const role_list = [];
            for (const key in role) {
                if (role[key] === 1) {
                    role_list.push(key);
                }
            }
            (_a = roleFormInstance.current) === null || _a === void 0 ? void 0 : _a.setFieldsValue(Object.assign(Object.assign({}, role), {role_list}));
        }, 200);
    };
    const roleFinish = (param) => {
        setIsModalVisible(false);
        checkboxOptions.forEach((op) => {
            param[op.value] = param.role_list.includes(op.value) ? 1 : 0;
        });
        if (currentRole) {
            // 编辑
            request.post('/company/editRole', Object.assign({role_id: currentRole.role_id}, param), true).then((res) => {
                message.success('操作成功', 1.5, queryRoleList);
            });
        } else {
            // 新增
            request.post('/company/createRole', param, true).then((res) => {
                message.success('操作成功', 1.5, queryRoleList);
            });
        }
    };
    const [showDelete, setShowDelete] = useState(false);
    const deleteRoleAction = (depart) => {
        setShowDelete(true);
        serCurrentRole(depart);
    };
    const deleteRole = (role) => {
        setShowDelete(false);
        request.post('/company/deleteRole', {role_id: role.role_id}, true).then((res) => {
            message.success('操作成功', 1.5, queryRoleList);
        });
    };
    return (
        <>
            <Row className="mt-base" justify={'space-between'} align={'middle'}>
                <Col span={24} className="com-align_right">
                    <Space size={'middle'}>
                        <Button size={'middle'} shape="default" className="com-btn" type="primary"
                                onClick={() => navigate('/home/role-record')}>
                            编辑记录
                        </Button>
                        <Button size="middle" shape="default" icon={<PlusOutlined/>} onClick={addRole}
                                className="com-btn com-btn-block green">
                            新增职位
                        </Button>
                    </Space>
                </Col>
            </Row>
            <Table pagination={{position: ['bottomCenter'], defaultPageSize: 10}} bordered className="mt-lg"
                   columns={tableColumns} dataSource={roleList}/>
            {/* part:新增职位 */}
            <Modal className="com-add-modal" title="新增职位" width={'40%'} okButtonProps={{shape: 'round'}}
                   cancelButtonProps={{shape: 'round'}} visible={isModalVisible}
                   onOk={() => roleFormInstance.current?.submit()} onCancel={() => setIsModalVisible(false)}>
                <Form className="" colon={true} labelCol={{span: 5}} ref={roleFormInstance} onFinish={roleFinish}>
                    <Row gutter={[20, 0]}>
                        <Col span={24}>
                            <Form.Item label="职位名称" name="title" rules={[{required: true, message: '请输入职位名称'}]}>
                                <Input placeholder="请输入职位名称"/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="职位权限" name="role_list">
                                <Checkbox.Group options={checkboxOptions}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Modal title="提示" visible={showDelete} onOk={() => deleteRole(currentRole)}
                   onCancel={() => setShowDelete(false)}>
                <p>{`您确定删除 ${currentRole?.title} 吗?`}</p>
            </Modal>
        </>
    );
};
export default Position;
