/* eslint-disable react-hooks/exhaustive-deps */
import {Breadcrumb, Button, List, Row, Space} from 'antd';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {request} from '../../util';
import ChartItem from './chartItem/chartItem';
import {inject, observer} from 'mobx-react';
import './index.scss';

const ChartList = (props) => {
    var _a, _b, _c;
    const navigate = useNavigate();
    const [dataSource, setDataSource] = useState([]);
    const refreshTimer = parseInt(((_c = (_b = (_a = props.publicData) === null || _a === void 0 ? void 0 : _a.optionList.configData) === null || _b === void 0 ? void 0 : _b.find(c => c.keyword === "refreshInterval")) === null || _c === void 0 ? void 0 : _c.value) || '5000');
    const loadNodeList = () => {
        request.post('/company/queryNodeList', {}).then((res) => {
            setDataSource(res.nodeList);
        });
    };
    useEffect(() => {
        var _a;
        (_a = props.publicData) === null || _a === void 0 ? void 0 : _a.getAllOptionList();
        loadNodeList();
        const interval = setInterval(loadNodeList, refreshTimer);
        return () => {
            clearInterval(interval);
        };
    }, [refreshTimer]);
    // 修改记录
    const gotoNodeRecord = (item) => {
        navigate(`/home/chart-record/${(item === null || item === void 0 ? void 0 : item.node_id) || ''}`);
    };
    const renderItem = (item) => {
        return (<ChartItem item={item} />);
    };
    return (
        <div className="p-lg">
            {/* part:导航栏 */}
            <Breadcrumb>
                <Breadcrumb.Item>节点列表</Breadcrumb.Item>
            </Breadcrumb>

            <Row justify={'end'}>
                <Space align={'end'} size={'small'} className="mt-lg">
                    <Button size="middle" shape="default" type="primary" className="com-btn com-btn-block"
                            onClick={() => gotoNodeRecord()}>
                        查看全部记录
                    </Button>
                    <Button size="middle" shape="default" className="com-btn com-btn-block green hide-on-mobile"
                            onClick={() => navigate('/home/edit-chart')}>
                        去新增
                    </Button>
                </Space>
            </Row>
            <List className="lists-wrapper" dataSource={dataSource} renderItem={renderItem}/>
        </div>
    );
};
export default inject('publicData')(observer(ChartList));
