import { Breadcrumb, Button, Col, Row, Space } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { request } from '../../util';
import { getRecordDiffList } from '../apply/recordDiff';
// page:设置记录
const SettingRecord = () => {
    const navigate = useNavigate();
    useEffect(() => {
        loadRecordList();
    }, []);
    const typeStr = ['未知', '等级修改申请', '参数修改申请', '选项修改申请'];
    const recordStateStr = ['等待审核', '审核通过', '审核驳回', '已撤回'];
    const recordStateClass = ['text-color-primary', 'text-color-green', 'text-color-danger', 'text-color-primary'];
    const [recordList, setRecordList] = useState([]);
    const loadRecordList = () => {
        request.post('/company/queryAgreeSettingList', {}, true).then((res) => {
            setRecordList(res.recordList);
        });
    };
    const renderAdoptItem = (record) => {
        const diffList = getRecordDiffList(record);
        return (
            <div className="com-white-block mb-10">
                <div className="font-weight-bold font-size-xl">{typeStr[record.type]}</div>
                <Row className="mt-lg">
                    <Col span={12}>
                        申请人：
                        <Avatar className="mr-5" size={25} src={record.apply_user?.face} /> <span className="text-color-second">{record.apply_user?.name}</span>
                    </Col>
                    <Col span={12} className="com-align_right text-color-light">
                        申请时间:{dayjs(record.create_time).format('YYYY-MM-DD HH:mm')}
                    </Col>
                </Row>
                <Row className="mt-base">
                    修改原因：<span className="text-color-second">{record.apply_reason}</span>
                </Row>
                <Row className="mt-base">
                    <Col>修改内容：</Col>
                    {diffList?.map((dif) => (
                        <>
                            <Col style={{ fontSize: 14, fontWeight: 'bold' }}>{dif.title}</Col>
                            {dif.diffList.map((content) => (
                                <Col span={24} className="mt-10 text-color-second">
                                    {content}
                                </Col>
                            ))}
                        </>
                    ))}
                </Row>
                <Row className="mt-base com-border-dashed-top pt-base" justify={'space-between'} align={'middle'}>
                    <Col span={20}>
                        <Space className="text-color-light" size={'large'}>
                            <div>
                                审批结果：<span className={`${recordStateClass[record.state]} font-weight-bold`}>{recordStateStr[record.state]}</span>
                            </div>
                            <div>
                                审批人：<span>{record.adopt_user?.name}</span>
                            </div>
                            <div>
                                审批时间：<span>{dayjs(record.adopt_time).format('YYYY-MM-DD HH:mm')}</span>
                            </div>
                        </Space>
                    </Col>
                    <Col span={4} className="com-align_right">
                        <Button onClick={() => navigate( `/home/setting-record-detail/${record.grade_record_id}`)} type="primary" size={'small'} shape="default">
                            查看详情
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    };

    return (
        <div className="p-lg">
            {/* part:导航栏 */}
            <Breadcrumb>
                <Breadcrumb.Item>平台管理</Breadcrumb.Item>
                <Breadcrumb.Item>
                    <a href="/" onClick={() => navigate(-1)}>设置</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>设置记录</Breadcrumb.Item>
            </Breadcrumb>
            {/* part:列表 */}
            {recordList.map(renderAdoptItem)}
        </div>
    );
};
export default SettingRecord;
