import {Button, Form, Input, message, Modal, Row, Select, Space} from 'antd';
import {useForm} from 'antd/lib/form/Form';
import {inject, observer} from 'mobx-react';
import React, {forwardRef, useImperativeHandle, useState, useEffect} from 'react';
import FormulaInput from '../../../component/formulaInput/formulaInput';
import PressureInput from '../../../component/formulaInput/pressureInput';
import {request} from '../../../util';
import config from '../../../config';
import log from 'loglevel';
log.setLevel(config.debug ? log.levels.DEBUG : log.levels.ERROR);
const BarrierModal = (props, ref) => {
  const [streamForm] = useForm();
  const [showStreamModal, setShowStreamModal] = useState(false);
  const [itemIndex, setItemIndex] = useState(0);
  const [type, setType] = useState('add');
  const [editData, setEditData] = useState();
  const [workType, setWorkType] = useState();
  // 执行公式
  const evaldata = async function (estr) {
    // eslint-disable-next-line no-eval
    return eval(estr);
  };
  const getBarrierNumber = (nodeInfo, workTypeId) => {
    let number = 0;
    for (const stream of nodeInfo.streamList) {
      for (const startEvent of (stream === null || stream === void 0 ? void 0 : stream.startEventList) || []) {
        for (const barrier of (startEvent === null || startEvent === void 0 ? void 0 : startEvent.barrierList) || []) {
          const barrierNumber = barrier.number || 0;
          if (barrier.barrier_work_type_id === workTypeId && barrierNumber > number) {
            number = barrierNumber;
          }
        }
      }
    }
    return number + 1;
  };
  // 点击确定
  const onFinish = (data) => {
    var _a, _b, _c;
    const rule = /【.*?】/g;
    // pfd公式
    const pfdE = ((_a = data.pfdTags) === null || _a === void 0 ? void 0 : _a.replace(rule, '1')) || '';
    // 失效公式
    const bypassE = ((_b = data.bypassTags) === null || _b === void 0 ? void 0 : _b.replace(rule, '1')) || '';
    // 触发公式
    const touchE = ((_c = data.touchTags) === null || _c === void 0 ? void 0 : _c.replace(rule, '1')) || '';
    Promise.all([evaldata(pfdE), evaldata(bypassE), evaldata(touchE)])
      .then((res) => {
        if (type === 'add') {
          const number = getBarrierNumber(props.editNode.nodeInfo, data.barrier_work_type_id);
          data.number = number;
          props.editNode.addBarrier(props.streamIndex, props.from, props.fromIndex, itemIndex, data);
        } else {
          props.editNode.updateBarrier(
            props.streamIndex,
            props.from,
            props.fromIndex,
            itemIndex,
            Object.assign(Object.assign({}, editData === null || editData === void 0 ? void 0 : editData.barrier), data),
          );
        }
        streamForm.resetFields();
        setShowStreamModal(false);
      })
      .catch((err) => {
        message.error('公式不能运行，请检查');
      });
  };
  // 点击取消
  const onReset = () => {
    streamForm.resetFields();
    setShowStreamModal(false);
  };
  // 点击删除
  const onDelete = () => {
    streamForm.resetFields();
    setShowStreamModal(false);
    props.editNode.deleteBarrier(props.streamIndex, props.from, props.fromIndex, itemIndex);
  };
  // 重置barrier的pfd
  const resetPfd = () => {
    const current_pfd = streamForm.getFieldValue('pfdTags');
    const param = {
      barrier_id: editData === null || editData === void 0 ? void 0 : editData.barrier.barrier_id,
      current_pfd,
    };
    request.post('/company/resetBarrierPfd', param, true).then((res) => {
      message.success('重置成功');
    });
  };
  // 复制操作
  const copyItem = () => {
    streamForm.resetFields();
    setShowStreamModal(false);
    props.editNode.copyBarrier(props.streamIndex, props.from, props.fromIndex, itemIndex);
  };

  useImperativeHandle(ref, () => ({
    open: (index, data) => {
      log.debug('保护层弹窗', data, index);
      setShowStreamModal(true);
      setItemIndex(index);
      if (data) {
        setType('edit');
        const _workType = props?.publicData.getBarrierWorkType(data.barrier.barrier_work_type_id)?.label;
        setWorkType(_workType);
        streamForm.setFieldsValue(data.barrier);
        setEditData(data);
      } else {
        setType('add');
        setWorkType(undefined);
      }
    },
    close: () => {
      setShowStreamModal(false);
    },
  }));
  const [parameterList, setParameterList] = useState();
  const [processList, setProcessList] = useState();
  const loadTags = () => {
    var _a, _b;
    const parameterList = ((_a = props.publicData) === null || _a === void 0 ? void 0 : _a.optionList.parameterList) || [];
    setParameterList(parameterList);
    const processList = ((_b = props.publicData) === null || _b === void 0 ? void 0 : _b.optionList.processList) || [];
    setProcessList(processList);
  };
  const reloadTags = () => {
    request.post('/company/queryAllTagList', {}, true).then((res) => {
      setParameterList(res.parameterList);
      setProcessList(res.processList);
    });
  };
  const onSelect = (value) => {
    const typ = props.publicData?.optionList.barrierWorkTypeList?.find((v) => v.barrier_work_type_id === value);
    setWorkType(typ?.label);
  };
  useEffect(() => {
    loadTags();
    loadMotes();
    // eslint-disable-next-line
  }, []);

  const [motes, setMotes] = useState([]);
  const loadMotes = () => {
    request.post('/company/queryMotes', {}, true).then((res) => {
      setMotes(res.mote);
    });
  };

  const layout = {
    labelCol: {span: 2},
    wrapperCol: {span: 22},
  };
  useEffect(() => {
    getTriggerLogic();
  }, []);
  const [logic, setLogic] = useState({bypass: 0, touch: 0});
  const getTriggerLogic = () => {
    request.post('/company/getTriggerLogic', {}, true).then((res) => {
      setLogic(res);
    });
  };
  return (
    <Modal title="保护层" visible={showStreamModal} closable={true} footer={null} onCancel={onReset} workType={workType} width={1200}>
      <Form
        {...layout}
        form={streamForm}
        onFinish={onFinish}
        onReset={onReset}
        labelWrap
        wrapperCol={{
          flex: 1,
        }}>
        <Form.Item label="编号：" name="number">
          <Input type={'number'} step={1} min={1} />
        </Form.Item>
        <Form.Item label="名称：" name="info" rules={[{required: true, message: '请输入保护层名称'}]}>
          <Input placeholder="请输入保护层名称" maxLength={200} />
        </Form.Item>
        <Form.Item label="保护层类型：" name="barrier_work_type_id" rules={[{required: true, message: '请选择保护层类型'}]}>
          <Select placeholder="请选择保护层类型" onSelect={onSelect}>
            {props.publicData?.optionList.barrierWorkTypeList?.map((v) => (
              <Select.Option value={v.barrier_work_type_id || ''} key={v.barrier_work_type_id}>
                {v.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="存续状态：" name="exist_type" rules={[{required: true, message: '请选择存续状态'}]}>
          <Select placeholder="请选择存续状态">
            <Select.Option value={1}>{'已存在'}</Select.Option>
            <Select.Option value={0}>{'建议添加'}</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="所属部门：" name="department_id" rules={[{required: true, message: '请选择部门'}]}>
          <Select placeholder="请选择部门">
            {props.publicData?.optionList.departmentList?.map((v) => (
              <Select.Option value={v.department_id || ''} key={v.department_id}>
                {v.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {/* {workType && workType === config.PSVBarrierWorkType ?  */}
        {false ? (
          <>
            <Form.Item label="仪表位号" name="tag" rules={[{required: false, message: '请输入仪表位号'}]}>
              <Select placeholder="请选择仪表位号" allowClear>
                {motes?.map((v) => (
                  <Select.Option value={v.label || ''} key={v.label}>
                    {v.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="整定压力" name="pressure_value" rules={[{required: false, message: '请输入整定压力'}]}>
              <Input type={'number'} />
            </Form.Item>
            <Form.Item label="管道压力" name="pressure_tag" rules={[{required: false, message: '请输入管道压力'}]}>
              <PressureInput processList={processList || []} />
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item label="短信通知：" name="flag" rules={[{required: true, message: '请选择是否发送短信通知'}]}>
              <Select placeholder="请选择是否发送短信通知">
                <Select.Option value={0}>{'是'}</Select.Option>
                <Select.Option value={1}>{'否'}</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={`失效逻辑\n(${logic.bypass === 1 ? '0是失效，\n1是正常' : '0是正常，\n1是失效'})`}
              name="bypassTags"
              rules={[{required: true, message: '请输入失效逻辑'}]}>
              <FormulaInput parameterList={parameterList || []} processList={processList || []} />
            </Form.Item>

            <Form.Item
              label={`触发逻辑\n(${logic.touch === 1 ? '0是触发，\n1是正常' : '0是正常，\n1是触发'})`}
              name="touchTags"
              rules={[{required: true, message: '请输入触发逻辑'}]}>
              <FormulaInput parameterList={parameterList || []} processList={processList || []} />
            </Form.Item>
          </>
        )}
        <Form.Item label="PFD逻辑" name="pfdTags" rules={[{required: true, message: '请输入PFD逻辑'}]}>
          <FormulaInput parameterList={parameterList || []} processList={processList || []} />
        </Form.Item>
        <Form.Item label="保护时间（s）：" name="reaction_time" rules={[{required: true, message: '请输入保护层名称'}]}>
          <Input placeholder="请输入保护层名称" type={'number'} />
        </Form.Item>

        <Row justify="center">
          <Space size="middle">
            <Button type="link" htmlType="button" onClick={reloadTags}>
              加载TAG
            </Button>
            {type === 'edit' && (
              <Button type="link" htmlType="button" onClick={copyItem}>
                复制
              </Button>
            )}
            {type === 'edit' && (
              <Button type="link" danger htmlType="button" onClick={onDelete}>
                删除
              </Button>
            )}
            {editData?.barrier.barrier_id && (
              <Button type="link" htmlType="button" onClick={resetPfd}>
                重置PFD
              </Button>
            )}
            <Button type="default" htmlType="reset">
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              确定
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};
export default inject('editNode', 'publicData')(observer(forwardRef(BarrierModal)));
