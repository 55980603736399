// noinspection JSUnresolvedFunction

import { inject, observer } from 'mobx-react';
import React, { forwardRef, useRef, useState } from 'react';
import StreamModal from './streamModal';
import StreamView from './streamView';
import config from '../../../config';
import log from 'loglevel';
log.setLevel(config.debug ? log.levels.DEBUG : log.levels.ERROR);
const ChartEditView = (props) => {
    const chartViewRef = useRef();
    const [dragEnter, setDragEnter] = useState(false);
    // 拖拽进入
    const onDragEnter = () => {
        // e.preventDefault();
        const source = global.dragItem;
        if (source === 'add_stream') {
            setDragEnter(true);
            log.debug('chart-view 拖拽进入', source);
        }
    };
    const onDragOver = (e) => {
        e.preventDefault();
    };
    // 拖拽离开
    const onDragLeave = (e) => {
        let relatedTarget = e.nativeEvent.relatedTarget;
        if (chartViewRef.current === relatedTarget || chartViewRef.current.contains(relatedTarget)) {
            return;
        }
        const source = global.dragItem;
        if (source === 'add_stream') {
            setDragEnter(false);
            log.debug('chart-view 拖拽离开', source);
        }
    };
    // 拖拽放下
    const onDrop = (e) => {
        let _a;
        const source = global.dragItem;
        if (source === 'add_stream') {
            log.debug('元素拖拽放在chart-view 位置：', e.clientX, e.clientY, '来源：', source);
            setDragEnter(false);
            let newStreamIndex = props.editNode.nodeInfo.streamList.length;
            for (let si = 0; si < props.editNode.nodeInfo.streamList.length; si++) {
                const stream_doc = document.getElementById(`stream_${si}`);
                const react = stream_doc.getBoundingClientRect();
                log.debug('获取事件流位置信息', si, react);
                if (react.y + react.height > e.clientY) {
                    newStreamIndex = si;
                    break;
                }
            }
            (_a = streamModalRef.current) === null || _a === void 0 ? void 0 : _a.open(newStreamIndex);
        }
    };
    // 事件流弹窗
    const streamModalRef = useRef();
    const onEditStream = (data) => {
        let _a;
        (_a = streamModalRef.current) === null || _a === void 0 ? void 0 : _a.open(data.streamIndex, data);
    };
    return (
        <div
            className={`chart-view ${dragEnter ? 'drag-enter-border' : ''}`}
            ref={chartViewRef}
            id="chart-view"
            onDragEnter={onDragEnter}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}>
            {props.editNode.nodeInfo.streamList.map((s, si) => (
                <StreamView stream={s} streamIndex={si} onEditStream={onEditStream} key={s.stream_id} />
            ))}
            <StreamModal ref={streamModalRef} />
        </div>
    );
};
export default inject('editNode')(observer(forwardRef(ChartEditView)));
