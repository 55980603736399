import {Button, Col, Empty, InputNumber, message, Radio, Row, Space, Checkbox} from 'antd';
import React, {useEffect, useState} from 'react';
import {request} from '../../util';
// page:参数设置
const ParameterPage = () => {
  useEffect(() => {
    // getParameterList();
    getDiffParameterList();
    // eslint-disable-next-line
  }, []);
  // const [parameterList, setParameterList] = useState<Parameter[]>([]);
  // const getParameterList = () => {
  //     request.post('/company/getParameterList', {}, true).then((res) => {
  //         setParameterList(res.parameterList);
  //     });
  // };
  const [validParameterList, setValidParameterList] = useState([]);
  const [newParameterList, setNewParameterList] = useState([]);
  const [diffParameterList, setDiffParameterList] = useState([]);
  const getDiffParameterList = () => {
    request.get('/company/getDiffParameterList', {}, true).then((res) => {
      const syncParameterList = res.syncParameterList || [];
      const validParameterList = res.validParameterList || [];
      const newParameterList = getNewParameters(syncParameterList, validParameterList);
      const diffParameterList = getDiffParameters(syncParameterList, validParameterList);
      setValidParameterList(validParameterList);
      setNewParameterList(newParameterList);
      setDiffParameterList(diffParameterList);
    });
  };
  const getNewParameters = (paramters1, paramters2) => {
    return paramters1.filter((p1) => {
      return !paramters2.some((p2) => {
        return p1.label === p2.label;
      });
    });
  };
  const getDiffParameters = (paramters1, paramters2) => {
    return paramters1.filter((p1) => {
      return paramters2.some((p2) => {
        return p1.label === p2.label && p1.value !== p2.value;
      });
    });
  };
  /**
   *  处理参数改变
   */
  const handleParameterValueChange = (param, index) => {
    const nowParameterList = [...diffParameterList];
    nowParameterList.splice(index, 1, param);
    setDiffParameterList(nowParameterList);
  };
  // const [showSubmitModal, setShowSubmitModal] = useState(false);
  // const [apply_reason, setApply_reason] = useState<string>();
  // const saveAction = () => {
  //     setShowSubmitModal(false);
  //     if (!apply_reason) {
  //         message.error('请输入修改原因');
  //     } else {
  //         request.post('/company/askForSetting', {askContent: {parameterList}, apply_reason, type: 2}, true).then((res) => {
  //             message.success('已经提交申请,等待审核中...');
  //         });
  //     }
  // };
  const appendParameters = () => {
    if (newCheckedParameterList.length === 0) {
      message.warning('参数没有做任何变更！');
    } else {
      request
        .post(
          '/company/askForSetting',
          {askContent: {parameterList: [...validParameterList, ...newCheckedParameterList]}, apply_reason: '同步新增参数', type: 2},
          true,
        )
        .then((res) => {
          message.success('已经提交申请,等待审核中...');
        });
    }
  };
  const updateParameters = () => {
    console.log(diffCheckedParameterList);
    if (diffCheckedParameterList.length === 0) {
      message.warning('参数没有做任何变更！');
    } else {
      const changedParameterList = [];
      for (const p1 of validParameterList) {
        const p2 = diffCheckedParameterList.find((p) => p.label === p1.label);
        if (p2) {
          changedParameterList.push(p2);
        } else {
          changedParameterList.push(p1);
        }
      }
      request
        .post('/company/askForSetting', {askContent: {parameterList: changedParameterList}, apply_reason: '更新参数', type: 2}, true)
        .then((res) => {
          message.success('已经提交申请,等待审核中...');
        });
    }
  };
  /**
   *  拷贝和同步参数
   */
  // const copyAsyncParameter = () => {
  //     request.post('/company/copyParameter', {}, true).then((res) => {
  //         message.success(`本次同步,新增了${res.affectedRows}个参数`, 1.5, getParameterList);
  //     });
  // };
  const options = ['新增参数', '变更参数'];
  const [option, setOption] = useState(options[0]);
  const onRadioChange = ({target: {value}}) => {
    setOption(value);
  };
  const [newAllChecked, setNewAllChecked] = useState(false);
  const [newCheckedParameterList, setNewCheckedParameterList] = useState([]);
  const onNewCheckAllChange = (e) => {
    const checked = e.target.checked;
    if (checked) {
      setNewCheckedParameterList(newParameterList);
      setNewAllChecked(true);
    } else {
      setNewCheckedParameterList([]);
      setNewAllChecked(false);
    }
  };
  const onNewCheckChange = (checkedValues) => {
    if (checkedValues.length === newParameterList.length) {
      setNewAllChecked(true);
    } else {
      setNewAllChecked(false);
    }
    const tmpNewParameterList = [];
    for (const v of checkedValues) {
      const p = newParameterList.find((p) => p.label === v);
      if (p) {
        tmpNewParameterList.push(p);
      }
    }
    setNewCheckedParameterList(tmpNewParameterList || []);
  };
  const [diffAllChecked, setDiffAllChecked] = useState(false);
  const [diffCheckedParameterList, setDiffCheckedParameterList] = useState([]);
  const onDiffCheckAllChange = (e) => {
    const checked = e.target.checked;
    if (checked) {
      setDiffCheckedParameterList(diffParameterList);
      setDiffAllChecked(true);
    } else {
      setDiffCheckedParameterList([]);
      setDiffAllChecked(false);
    }
  };
  const onDiffCheckChange = (checkedValues) => {
    if (checkedValues.length === diffParameterList.length) {
      setDiffAllChecked(true);
    } else {
      setDiffAllChecked(false);
    }
    const tmpDiffParameterList = [];
    for (const v of checkedValues) {
      const p = diffParameterList.find((p) => p.label === v);
      if (p) {
        tmpDiffParameterList.push(p);
      }
    }
    setDiffCheckedParameterList(tmpDiffParameterList || []);
  };
  const NewParameterPage = (params, checkedParams) => {
    const labels = checkedParams.map((p) => p?.label);
    return (
      <>
        {params.length !== 0 && (
          <Row className="mt-lg" align={'middle'}>
            <Col span={2} offset={18}>
              <Checkbox onChange={onNewCheckAllChange} checked={newAllChecked}>
                全选
              </Checkbox>
            </Col>
          </Row>
        )}
        <Checkbox.Group
          style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}
          onChange={onNewCheckChange}
          value={labels}>
          {params.map((param, index) => (
            <Row className="mt-lg" align={'middle'} key={`new-params-${index}`}>
              <Col span={12} offset={1} style={{textAlign: 'right'}}>
                <span className="font-weight-bold">{param.label}</span>
              </Col>
              <Col span={4} offset={1}>
                <InputNumber style={{width: 100}} step={0.1} value={param.value} disabled={true} />
              </Col>
              <Col span={2}>
                <Checkbox value={param.label}> </Checkbox>
              </Col>
            </Row>
          ))}
        </Checkbox.Group>
        {params?.length === 0 ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
        ) : (
          <Row justify={'center'} align={'middle'} className="mt-lg">
            <Space size={'middle'}>
              <Button size="middle" shape="default" className="com-btn com-btn-block green" onClick={appendParameters}>
                提交申请
              </Button>
            </Space>
          </Row>
        )}
      </>
    );
  };

  const DiffParameterPage = (params, validParams, checkedParams) => {
    const labels = checkedParams.map((p) => p?.label);
    return (
      <>
        {params.length !== 0 && (
          <Row className="mt-lg" align={'middle'}>
            <Col span={2} offset={18}>
              <Checkbox onChange={onDiffCheckAllChange} checked={diffAllChecked}>
                全选
              </Checkbox>
            </Col>
          </Row>
        )}

        <Checkbox.Group
          style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}
          onChange={onDiffCheckChange}
          value={labels}>
          {params.map((param, index) => {
            const valid = validParams?.find((p) => p.label === param.label);
            return (
              <Row className="mt-lg" align={'middle'} key={`diff-params-${index}`} style={{width: '100%'}}>
                <Col span={12} offset={0} style={{textAlign: 'right'}}>
                  <span className="font-weight-bold">{param.label}</span>
                </Col>
                <Col span={2} offset={1}>
                  <InputNumber step={0.1} value={valid?.value} disabled={true} size="small" />{' '}
                </Col>
                <Col span={2} offset={1}>
                  <InputNumber
                    size="small"
                    style={{color: 'red'}}
                    step={0.1}
                    defaultValue={param.value}
                    placeholder="请输入参数值"
                    onChange={(v) => handleParameterValueChange({...param, value: v}, index)}
                  />
                </Col>
                <Col span={2}>
                  <Checkbox value={param.label}> </Checkbox>
                </Col>
              </Row>
            );
          })}
        </Checkbox.Group>
        {params?.length === 0 ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
        ) : (
          <Row justify={'center'} align={'middle'} className="mt-lg">
            <Space size={'middle'}>
              {/* {config.userInfo?.user_set === 1 && (
                        <Button size={'middle'} onClick={copyAsyncParameter} shape="default" className="com-w-btn">
                            同步参数
                        </Button>
                    )} */}
              <Button size="middle" shape="default" className="com-btn com-btn-block green" onClick={updateParameters}>
                提交申请
              </Button>
            </Space>
          </Row>
        )}
      </>
    );
  };

  return (
    <div className="setting-item">
      <Radio.Group optionType="button" buttonStyle="solid" options={options} onChange={onRadioChange} defaultValue={options[0]} />
      {option === options[0]
        ? NewParameterPage(newParameterList, newCheckedParameterList)
        : DiffParameterPage(diffParameterList, validParameterList, diffCheckedParameterList)}
      {/* <Modal
                title="修改原因"
                okButtonProps={{shape: 'round'}}
                cancelButtonProps={{shape: 'round'}}
                visible={showSubmitModal}
                onOk={saveAction}
                onCancel={() => setShowSubmitModal(false)}>
                <Col span={24}>
                    <Input placeholder="请输入修改原因" value={apply_reason} onChange={(e) => setApply_reason(e.target.value)} />
                </Col>
            </Modal> */}
    </div>
  );
};
export default ParameterPage;
