import md5 from 'md5';

/**
 *  加密字符串
 */
const encryption = (text) => {
    const one = md5(text);
    const two = md5(one + 'LTAI4GDQTAUkVxdkb9hK6zDS');
    return md5(two + 'dingle@1136995769442282.onaliyun.com');
};
export default { encryption };
