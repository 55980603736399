// noinspection JSUnresolvedFunction,JSUnresolvedVariable

import {inject, observer} from 'mobx-react';
import React, {useMemo, useRef, useState} from 'react';
import BarrierModal from './barrierModal';
import BarrierView from './barrierView';
import {createPopoverItem} from './pubComponent';
import config from '../../../config';
import log from 'loglevel';

log.setLevel(config.debug ? log.levels.DEBUG : log.levels.ERROR);
const ResultView = (props) => {
    let _a, _b;
    const [dragEnter, setDragEnter] = useState(false);
    const resultViewRef = useRef();
    const [newBarrierIndex, setNewBarrierIndex] = useState(0);
    // 拖拽进入
    const onDragEnter = (e) => {
        const source = global.dragItem;
        if (source === 'add_barrier' || source.startsWith('barrier_')) {
            setDragEnter(true);
            log.debug('resultView拖拽进入', source);
        }
    };
    const onDragOver = (e) => {
        e.preventDefault();
        const source = global.dragItem;
        if (source === 'add_barrier' || source.startsWith('barrier_')) {
            const barrierList = props.result.barrierList || [];
            let newBarrierIndex_temp = barrierList.length;
            for (let si = 0; si < barrierList.length; si++) {
                const barrier_doc = document.getElementById(`barrier_${props.streamIndex}_result_${props.resultIndex}_${si}`);
                if (barrier_doc) {
                    const react = barrier_doc.getBoundingClientRect();
                    if (react.x + react.width > e.clientX) {
                        newBarrierIndex_temp = si;
                        break;
                    }
                }
            }
            setNewBarrierIndex(newBarrierIndex_temp);
        }
    };
    // 拖拽离开
    const onDragLeave = (e) => {
        let relatedTarget = e.nativeEvent.relatedTarget;
        if (resultViewRef.current === relatedTarget || resultViewRef.current.contains(relatedTarget)) {
            return;
        }
        const source = global.dragItem;
        if (source === 'add_barrier' || source.startsWith('barrier_')) {
            setDragEnter(false);
            log.debug('resultView 拖拽离开', source);
        }
    };
    // 拖拽放下
    const onDrop = (e) => {
        let _a;
        const source = global.dragItem;
        setDragEnter(false);
        if (source === 'add_barrier') {
            (_a = barrierModalRef.current) === null || _a === void 0 ? void 0 : _a.open(newBarrierIndex);
        }
        if (source.startsWith('barrier_')) {
            const barrierPosition = source.split('_');
            const fromPosition = {
                streamIndex: parseInt(barrierPosition[1]),
                from: barrierPosition[2],
                fromIndex: parseInt(barrierPosition[3]),
                barrierIndex: parseInt(barrierPosition[4]),
            };
            const toPositoon = {
                streamIndex: props.streamIndex,
                from: 'result',
                fromIndex: props.resultIndex,
                barrierIndex: newBarrierIndex,
            };
            props.editNode.moveBarrier(fromPosition, toPositoon);
        }
    };
    const barrierList = useMemo(() => {
        if (dragEnter) {
            const tempBarrierList = [...(props.result.barrierList || [])];
            tempBarrierList.splice(newBarrierIndex, 0, {temp: true});
            return tempBarrierList;
        } else {
            return props.result.barrierList || [];
        }
    }, [dragEnter, newBarrierIndex, props.result.barrierList]);
    // 保护层弹窗
    const barrierModalRef = useRef();
    // 编辑保护层事件
    const onEditBarrier = (data) => {
        let _a;
        (_a = barrierModalRef.current) === null || _a === void 0 ? void 0 : _a.open(data.barrierIndex, data);
    };
    // 开始拖动
    const onDragStart = (e) => {
        global.dragItem = e.target.id;
    };
    // 双击事件
    const onDoubleClick = () => {
        props.onEditResult(props);
    };
    const onSelectBarrier = (data) => {
        props.onSelectBarrier(data);
    };
    const resultPopInfo = [];
    if (props.result.info) {
        resultPopInfo.push(`名称:${props.result.info}`);
    }
    const severityLabel = (_b = (_a = props.publicData) === null || _a === void 0 ? void 0 : _a.getSeverity(props.result.severity_id)) === null || _b === void 0 ? void 0 : _b.label;
    if (severityLabel) {
        resultPopInfo.push(`等级:${severityLabel}`);
    }
    const resultItem = (
        <div className="result-item" onDoubleClick={onDoubleClick}>
            <div className="result-info">
                <span>{props.result.info}</span>
            </div>
            <div className="risk-view">
                {props.publicData?.optionList.severityList?.map((ser) => (
                    <div
                        className={`risk-item ${ser.severity_id === props.result.severity_id ? 'risk-select' : 'risk-normal'}`}
                        key={ser.severity_id}>
                        <span>{ser.label}</span>
                    </div>
                ))}
            </div>
        </div>
    );
    return (
        <div
            id={`result_${props.streamIndex}_${props.resultIndex}`}
            className={`result_view ${dragEnter ? 'drag-enter-border' : ''}`}
            ref={resultViewRef}
            onDragEnter={onDragEnter}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            draggable
            onDragStart={onDragStart}>
            {barrierList.map((bar, bi) => (
                <BarrierView
                    key={bar.barrier_id}
                    barrier={bar}
                    streamIndex={props.streamIndex}
                    from="result"
                    fromIndex={props.resultIndex}
                    barrierIndex={bi}
                    onEditBarrier={onEditBarrier}
                    onSelectBarrier={() => onSelectBarrier(bar)}
                    selectedBarrier={props.selectedBarrier}
                />
            ))}
            {createPopoverItem(resultItem, resultPopInfo, 'topRight')}
            <BarrierModal ref={barrierModalRef} streamIndex={props.streamIndex} from="result"
                          fromIndex={props.resultIndex}/>
        </div>
    );
};
export default inject('editNode', 'publicData')(observer(ResultView));
