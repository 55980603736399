const devHost = 0 ? 'http://127.0.0.1:7001/api' : 'http://47.95.209.220/api';
/**
 *  配置信息
 */
const config = {
  // 是否调试模式
  debug: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
  apiHost: !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? devHost : `${window.location.origin}/api`,
  // 静态地址 本地部署
  staticPath: `/`,
  // token 令牌
  token: window.localStorage.getItem('company.token') || '',
  // 用户信息
  userInfo: undefined,
  companyInfo: undefined,
  // 参数设定值后缀
  paramSuffix: '_valid',
  // 行业
  industryList: [
    {
      title: '石油化工',
    },
    {
      title: '生物医药',
    },
  ],
  PSVBarrierWorkType: '机械释放',
};
export default config;
