// noinspection JSUnusedLocalSymbols,JSUnusedGlobalSymbols,JSValidateTypes,JSCheckFunctionSignatures,JSUnresolvedVariable,ES6CheckImport,SpellCheckingInspection

import {inject, observer} from 'mobx-react';
import React from 'react';
import './table.scss';

const Table = (props) => {
    const {nodeInfo} = props;
    const renderBarrier = (barrier, index, type) => {
        return (
            <div className="barrier" key={index}>
                <span className="desc">{barrier.info}</span>
                <span
                    className="category">{props.publicData?.getBarrierWorkType(barrier.barrier_work_type_id)?.label}</span>
                <span className="pfd">{barrier.current_pfd}</span>
                {!!type && <span className="pfd">{type === 'init' ? '预防型' : '补救型'}</span>}
            </div>
        );
    };
    const renderInitiatot = (startEvent, index, result) => {
        let _a, _b, _c, _d, _e, _f, _g, _h;
        // 已存在保护措施pfd
        let existInitPfd = startEvent.current_pfd || 1;
        // 所有保护措施pfd
        let allInitPfd = startEvent.current_pfd || 1;
        // 存在的保护措施对象
        const exitInitBarrier = [];
        // 建议添加的保护措施对象
        const unExitInitBarrier = [];
        (_a = startEvent.barrierList) === null || _a === void 0 ? void 0 : _a.forEach((br) => {
            if (br.exist_type === 1) {
                existInitPfd = (br.current_pfd || 1) * existInitPfd;
                exitInitBarrier.push(br);
            } else {
                unExitInitBarrier.push(br);
            }
            allInitPfd = (br.current_pfd || 1) * allInitPfd;
        });
        // 已存在预防措施pfd
        let existResultPfd = 1;
        // 所有预防措施pfd
        let allResultPfd = 1;
        // 已存在预防措施
        const exitResultBarrier = [];
        // 建议添加的预防措施对象
        const unExitResultBarrier = [];
        (_b = result.barrierList) === null || _b === void 0 ? void 0 : _b.forEach((br) => {
            if (br.exist_type === 1) {
                existResultPfd = (br.current_pfd || 1) * existResultPfd;
                exitResultBarrier.push(br);
            } else {
                unExitResultBarrier.push(br);
            }
            allResultPfd = (br.current_pfd || 1) * allResultPfd;
        });
        // 已存在的保护措施最终pfd
        const endPfdGrade = (_c = props.publicData) === null || _c === void 0 ? void 0 : _c.exchangePfdGrade(existInitPfd * existResultPfd);
        // 严重性
        const severity = ((_e = (_d = props.publicData) === null || _d === void 0 ? void 0 : _d.getSeverity(result.severity_id)) === null || _e === void 0 ? void 0 : _e.value) || 1;
        // 已存在的保护措施最终风险等级
        const riskGrade = (_f = props.publicData) === null || _f === void 0 ? void 0 : _f.exchangeRiskGrade(severity, (endPfdGrade === null || endPfdGrade === void 0 ? void 0 : endPfdGrade.value) || 1);
        // 所有的保护措施最终pfd
        const unPfdGrade = (_g = props.publicData) === null || _g === void 0 ? void 0 : _g.exchangePfdGrade(allResultPfd * allInitPfd);
        // 所有的保护措施最终风险等级
        const unExitRiskGrade = (_h = props.publicData) === null || _h === void 0 ? void 0 : _h.exchangeRiskGrade(severity, (unPfdGrade === null || unPfdGrade === void 0 ? void 0 : unPfdGrade.value) || 1);
        return (
            <div className="initiatot" key={index}>
                <span
                    className="desc">{`${startEvent.info}(${props.publicData?.getStartEventType(startEvent.start_event_type_id)?.label})`}</span>
                <div className="barrier_group">
                    {exitInitBarrier.map((v, i) => renderBarrier(v, i))}
                    {exitResultBarrier.map((v, i) => renderBarrier(v, i + exitInitBarrier.length))}
                </div>
                <span className="onse_item">{endPfdGrade?.label}</span>
                <span className="onse_item">{riskGrade?.label}</span>
                {unExitInitBarrier.length + unExitResultBarrier.length > 0 && (
                    <>
                        <div className="barrier_group">
                            {unExitInitBarrier.map((v, i) => renderBarrier(v, i, 'init'))}
                            {unExitResultBarrier.map((v, i) => renderBarrier(v, i, 'result'))}
                        </div>
                        <span className="onse_item">{unPfdGrade?.label}</span>
                        <span className="onse_item">{unExitRiskGrade?.label}</span>
                    </>
                )}
            </div>
        );
    };
    const renderResult = (result, index, stream) => {
        let _a;
        let deviationData = [];
        (_a = stream.startEventList) === null || _a === void 0 ? void 0 : _a.forEach((initia) => {
            let _a, _b;
            const deviationLabel = ((_b = (_a = props.publicData) === null || _a === void 0 ? void 0 : _a.getDeviationType(initia.deviation_type_id)) === null || _b === void 0 ? void 0 : _b.label) || '无偏差';
            if (deviationData.map((d) => d.deviationName).includes(deviationLabel)) {
                deviationData = deviationData.map((data) => {
                    if (data.deviationName === deviationLabel) {
                        data.initiatotList.push(initia);
                    }
                    return data;
                });
            } else {
                deviationData.push({deviationName: deviationLabel, initiatotList: [initia]});
            }
        });
        return (
            <div className="result" key={index}>
                {/* 后果描述 */}
                <span className="desc">{result.info}</span>
                {/* 后果等级 */}
                <span className="lv">{props.publicData?.getSeverity(result.severity_id)?.label}</span>
                {/* 起始事件系列 */}
                <div className="deviation_group">
                    {deviationData.map((dev) => {
                        return (
                            <div className="deviation_item">
                                <div className="onse_item">{dev.deviationName}</div>
                                <div
                                    className="initiatot_group">{dev.initiatotList.map((init, index) => renderInitiatot(init, index, result))}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };
    const renderStream = (stream, index) => {
        return (
            <div className="t_content" key={index}>
                <span className="onse_item">{index + 1}</span>

                {/* 顶级事件 */}
                <span className="onse_item">{props.publicData?.getTopEventType(stream.top_event_type_id)?.label}</span>

                {/* 后果部分 */}
                <div className="result_group">{stream.resultList?.map((r, i) => renderResult(r, i, stream))}</div>
            </div>
        );
    };
    return (
        <div className="table_body com-white-block">
            <div className="table">
                <div className="t_header">
                    <span className="onse_item">序号</span>

                    <span className="onse_item">顶级事件</span>
                    <div className="result">
                        <span className="title">后果</span>
                        <div className="sub_title">
                            <span className="desc">描述</span>
                            <span className="lv">等级</span>
                        </div>
                    </div>

                    <span className="onse_item">偏差</span>
                    <span className="initiatot">起始事件</span>
                    <div className="barrier">
                        <span className="title">预防措施</span>
                        <div className="sub_title">
                            <span className="desc">描述/Description</span>
                            <span className="category">种类{'\n'}Category</span>
                            <span className="pfd">降低级别/PFD</span>
                        </div>
                    </div>

                    <span className="onse_item">可能性</span>
                    <span className="onse_item">风险等级</span>

                    <div className="barrier">
                        <span className="title">建议额外措施</span>
                        <div className="sub_title">
                            <span className="desc">描述/Description</span>
                            <span className="category">种类{'\n'}Category</span>
                            <span className="pfd">降低级别/PFD</span>
                            <span className="pfd">类别</span>
                            <span className="onse_item">可能性</span>
                            <span className="onse_item">风险等级</span>
                        </div>
                    </div>
                </div>

                {nodeInfo?.streamList?.map(renderStream)}
            </div>
        </div>
    );
};
export default inject('publicData')(observer(Table));
