import {Breadcrumb, Tabs} from 'antd';
import React, {useEffect, useState} from 'react';
import {request} from '../../util';
import DepartmentM from './department';
import People from './people';
import Position from './position';
// page:角色配置
const RoleIndex = () => {
  const [type, setType] = useState('People');
  useEffect(() => {
    queryDepartmentList();
    queryRoleList();
  }, [type]);
  const [departmentList, setDepartmentList] = useState([]);
  const queryDepartmentList = () => {
    request.post('/company/queryDepartments', {}, true).then((res) => {
      setDepartmentList(res.departmentList);
    });
  };
  const [roleList, serRoleList] = useState([]);
  const queryRoleList = () => {
    request.post('/company/queryRoles', {}, true).then((res) => {
      serRoleList(res.roleList);
    });
  };
  return (
    <div className="p-lg">
      {/* part:导航栏 */}
      <Breadcrumb>
        <Breadcrumb.Item>平台管理</Breadcrumb.Item>
        <Breadcrumb.Item>角色配置</Breadcrumb.Item>
      </Breadcrumb>
      {/* part:内容 */}
      <Tabs className="com-white-block pt-10" activeKey={type} onTabClick={(key) => setType(key)}>
        <Tabs.TabPane tab="部门" key="Department">
          <DepartmentM />
        </Tabs.TabPane>
        <Tabs.TabPane tab="职位" key="Position">
          <Position />
        </Tabs.TabPane>
        <Tabs.TabPane tab="人员" key="People">
          <People roleList={roleList} departList={departmentList} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};
export default RoleIndex;
