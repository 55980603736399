import {PlusOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, message, Modal, Row, Space, Table} from 'antd';
import dayjs from 'dayjs';
import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {request} from '../../util';
// page:编辑部门
const DepartmentPage = () => {
    const tableColumns = [
        {
            title: '序号',
            align: 'center',
            render: (text, department, index) => `${index + 1}`,
        },
        {
            title: '部门名称',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
        },
        {
            title: '部门负责人',
            dataIndex: 'leader_name',
            key: 'leader_name',
            align: 'center',
        },
        {
            title: '负责人手机号',
            dataIndex: 'leader_phone',
            key: 'leader_phone',
            align: 'center',
        },
        {
            title: '最后修改时间',
            key: 'update_date',
            align: 'center',
            render: (text, department, index) => dayjs(department.update_date).format('YYYY-MM-DD'),
        },
        {
            title: '操作',
            key: 'action',
            align: 'center',
            className: 'com-table-btn-group',
            // eslint-disable-next-line react/display-name
            render: (text, department, index) => (
                <>
                    <Button type="link" onClick={() => editDepartment(department)}>
                        编辑
                    </Button>
                    <Button type="link" danger onClick={() => deleteDepartmentAction(department)}>
                        删除
                    </Button>
                </>
            ),
        },
    ];
    const navigate = useNavigate();
    useEffect(() => {
        queryDepartmentList();
    }, []);
    const [departmentList, setDepartmentList] = useState([]);
    const queryDepartmentList = () => {
        request.post('/company/queryDepartments', {}, true).then((res) => {
            setDepartmentList(res.departmentList);
        });
    };
    const [currentDepartment, serCurrentDepartment] = useState();
    const departmentFormInstance = useRef(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const addDepartment = () => {
        setIsModalVisible(true);
        serCurrentDepartment(undefined);
        setTimeout(() => {
            var _a;
            (_a = departmentFormInstance.current) === null || _a === void 0 ? void 0 : _a.resetFields();
        }, 200);
    };
    const editDepartment = (depart) => {
        setIsModalVisible(true);
        serCurrentDepartment(depart);
        setTimeout(() => {
            var _a;
            (_a = departmentFormInstance.current) === null || _a === void 0 ? void 0 : _a.setFieldsValue(depart);
        }, 200);
    };
    const departmentFinish = (param) => {
        setIsModalVisible(false);
        if (currentDepartment) {
            // 编辑
            request.post('/company/editDepartment', Object.assign(Object.assign({}, currentDepartment), param), true).then((res) => {
                message.success('操作成功', 1.5, queryDepartmentList);
                queryDepartmentList();
            });
        } else {
            // 新增
            request.post('/company/createDepartment', param, true).then((res) => {
                message.success('操作成功', 1.5, queryDepartmentList);
                queryDepartmentList();
            });
        }
    };
    const [showDelete, setShowDelete] = useState(false);
    const deleteDepartmentAction = (depart) => {
        setShowDelete(true);
        serCurrentDepartment(depart);
    };
    const deleteDepartment = (depart) => {
        setShowDelete(false);
        request.post('/company/deldeteDepartment', {department_id: depart.department_id}, true).then((res) => {
            message.success('操作成功', 1.5, queryDepartmentList);
        });
    };
    return (
        <>
            <Row justify={'end'} align={'middle'}>
                <Space size={'middle'}>
                    <Button size={'middle'} shape="default" className="com-btn" type="primary"
                            onClick={() => navigate('/home/department-record')}>
                        编辑记录
                    </Button>
                    <Button size="middle" shape="default" icon={<PlusOutlined/>} onClick={addDepartment}
                            className="com-btn com-btn-block green">
                        新增部门
                    </Button>
                </Space>
            </Row>
            <Table pagination={{position: ['bottomCenter'], defaultPageSize: 10}} bordered className="mt-lg"
                   columns={tableColumns} dataSource={departmentList}/>
            {/* part:新增部门 */}
            <Modal className="com-add-modal" title="新增部门" width={'30%'} okButtonProps={{shape: 'round'}}
                   cancelButtonProps={{shape: 'round'}} visible={isModalVisible}
                   onOk={() => departmentFormInstance.current?.submit()} onCancel={() => setIsModalVisible(false)}>
                <Form className="" colon={true} labelCol={{span: 5}} ref={departmentFormInstance}
                      onFinish={departmentFinish}>
                    <Row gutter={[20, 0]}>
                        <Col span={24}>
                            <Form.Item label="部门名称" name="name" rules={[{required: true, message: '请输入部门名称'}]}>
                                <Input placeholder="请输入部门名称"/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Modal title="提示" visible={showDelete} onOk={() => deleteDepartment(currentDepartment)}
                   onCancel={() => setShowDelete(false)}>
                <p>{`您确定删除 ${currentDepartment?.name} 吗?`}</p>
            </Modal>
        </>
    );
};
export default DepartmentPage;
