import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, message, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { request } from '../../util';
import './index.scss';
const HearBeat = () => {
    useEffect(() => {
        getAllHeartBeatList();
    }, []);
    const [heartBeatList, setHeartBeatList] = useState([]);
    const getAllHeartBeatList = () => {
        request.post('/company/getAllHeartBeatList', {}, true).then((res) => {
            setHeartBeatList(res.heartBeatList);
        });
    };
    const onHeartBeatHandle = (action, type, index = 0) => {
        let nowItems = [...heartBeatList];
        if (action === 'add') {
            nowItems.push({ value: heartBeatList.length - 1 });
            setHeartBeatList(nowItems);
        }
        else if (action === 'change' && type) {
            nowItems.splice(index, 1, type);
            setHeartBeatList(nowItems);
        }
        else if (action === 'delete') {
            nowItems.splice(index, 1);
            setHeartBeatList(nowItems);
        } else {
            message.error('操作不合法');
        }
    };
    // 保存事件
    const saveAction = () => {
        if (heartBeatList.length === 0) {
            message.error('请输入至少一个顶级事件类型');
        } else {
            const heartBeatList1 = heartBeatList.filter((type) => !!type.tag);
            console.log(heartBeatList1);
            request.post('/company/updateHeartBeatList', { heartBeatList: heartBeatList1 }, true).then((res) => {
                console.log(res);
                message.success('更新成功');
            });
        }
    };
    return (
        <>
            {/* part：设置顶级事件 */}
            <div className="setting-item">
                <div className="setting-title">设置数据链路</div>
                {heartBeatList.map((heart, index) => (
                    <Row className="mt-base option-row" align={'middle'} justify={'center'}>
                        <span className="option-label">数据链路心跳标签:</span>
                        <Input className="option-input" value={heart.tag} placeholder="请输入类型" onChange={(e) => onHeartBeatHandle('change', { ...heart, tag: e.target.value }, index)} />
                        <MinusCircleOutlined className="delete-btn" onClick={() => onHeartBeatHandle('delete', heart, index)} />
                    </Row>
                ))}
                <Button block size={'large'} className="add-btn-dashed mt-lg" type="dashed" onClick={() => onHeartBeatHandle('add')}>
                    <PlusOutlined /> 添加选项
                </Button>
            </div>

            <Row justify={'center'} align={'middle'} className="mt-lg">
                <Space size={'middle'}>
                    <Button size="middle" shape="default" className="com-btn com-btn-block green" onClick={() => saveAction()}>
                        提交
                    </Button>
                </Space>
            </Row>

        </>
    );
};
export default HearBeat;
