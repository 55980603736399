// 公式输入
import {Input, Tree} from 'antd';
import React, {useMemo, useState} from 'react';
import './formulaInput.scss';

const FormulaInput = ({value = '', onChange = () => {}, parameterList, processList}) => {
  const [searchText, setSearchText] = useState('');
  const treeData = useMemo(() => {
    const loadChildren = (list, type) => {
      return list
        .map((v, idx) => ({
          key: `${type}-${idx}`,
          title: v.label,
          id: idx || '',
          type,
        }))
        .filter((v) => v.title.includes(searchText));
    };
    const paramTagList = {
      key: 'paramTag',
      title: '参数',
      selectable: false,
      children: loadChildren(parameterList, 'paramTag'),
    };
    const tagList = {
      key: 'dataTag',
      title: 'TAG字符',
      selectable: false,
      children: loadChildren(processList, 'dataTag'),
    };
    return [paramTagList, tagList];
  }, [parameterList, processList, searchText]);
  const onSelect = (keys, info) => {
    onChange(`${value} 【${info.node.title}${info.node.type === 'paramTag' ? '_valid' : ''}】`);
  };
  const onSearchChange = (e) => {
    const {value} = e.target;
    setSearchText(value);
  };
  return (
    <div className="formulaInputDiv">
      <Input.TextArea
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="请输入公式"
        className="input"
        autoSize={{minRows: 2, maxRows: 10}}
      />
      <div className="action-view">
        <Input.Search style={{marginBottom: 8}} placeholder={'请输入关键字搜索tag'} onChange={onSearchChange} className="searchBar" />
      </div>

      <Tree height={150} treeData={treeData} onSelect={onSelect} />
    </div>
  );
};
export default FormulaInput;
