/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import PubChart from './pubChart';
import ScaleWrapper from './scaleWrapper';
import './pubChart.scss';
import axios from 'axios';

const Pub = () => {
  const [nodeInfo, setNodeInfo] = useState();
  const {node_id} = useParams();
  const [publicData, setPublicData] = useState();
  const queryNodeInfo = async () => {
    if (!node_id) return;
    const data = new URLSearchParams();
    data.append('node_id', node_id);
    axios
      .post('http://47.95.209.220/api/company/queryNodeInfoPub', data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        mode: 'cors',
      })
      .then((res) => {
        setNodeInfo(res.data.data.node);
        const allOptionList = res.data.data.allOptionList;
        const allGradeList = res.data.data.allGradeList;
        const optionList = {
          ...allOptionList,
          ...allGradeList,
        };
        const getSeverity = (id) => {
          return optionList.severityList?.find((v) => v.severity_id === id);
        };
        const getPfd = (id) => {
          const pfdList = optionList.pfdList;
          return pfdList?.find((v) => v.pfd_id === id);
        };
        const getRisk = (id) => {
          const riskList = optionList.riskList;
          return riskList?.find((v) => v.risk_id === id);
        };
        const getRiskForValue = (value) => {
          const riskList = optionList.riskList;
          return riskList?.find((v) => v.value === value);
        };
        const getRiskRole = (id) => {
          const riskRoleList = optionList.riskRoleList;
          return riskRoleList?.find((v) => v.risk_role_id === id);
        };
        const getTopEventType = (id) => {
          const {topEventTypeList} = optionList;
          return topEventTypeList?.find((v) => v.top_event_type_id === id);
        };
        const getStartEventType = (id) => {
          const {startEventTypeList} = optionList;
          return startEventTypeList?.find((v) => v.start_event_type_id === id);
        };
        const getDeviationType = (id) => {
          const {deviationTypeList} = optionList;
          return deviationTypeList?.find((v) => v.deviation_type_id === id);
        };
        const getBarrierWorkType = (id) => {
          const {barrierWorkTypeList} = optionList;
          return barrierWorkTypeList?.find((v) => v.barrier_work_type_id === id);
        };
        // const getDepartment = (department_id) => {
        //   const departmentList = optionList.departmentList;
        //   return departmentList?.find(
        //     (depart) => depart.department_id === department_id
        //   );
        // };
        const getRiskAlertValue = () => {
          const configData = optionList.configData;
          const riskAlertValue = configData?.find((c) => c.keyword === 'riskAlertValue')?.value || '0';
          return parseInt(riskAlertValue);
        };
        /**
         *  根据pfd值,得到pfd等级对象
         */
        const exchangePfdGrade = (pfd) => {
          if (pfd < 0) pfd = 0;
          if (pfd > 1) pfd = 1;
          return optionList?.pfdList?.find((grade) => pfd >= (grade.min_pfd ?? 0) && pfd <= (grade.max_pfd ?? 1));
        };
        /**
         *  根据后果值和可能性值得到风险等级
         * severityValue: 风险等级
         * pfdValue :可能性等级
         */
        const exchangeRiskGrade = (severityValue, pfdValue) => {
          const {riskRoleList, riskList} = optionList;
          const riskRole = riskRoleList?.find((role) => role.severity_value === severityValue && role.pfd_value === pfdValue);
          if (riskRole) {
            return riskList?.find((risk) => risk.value === riskRole.risk_value);
          }
        };

        setPublicData({
          optionList,
          getSeverity,
          getPfd,
          exchangeRiskGrade,
          getBarrierWorkType,
          getDeviationType,
          getStartEventType,
          getTopEventType,
          getRisk,
          getRiskForValue,
          getRiskRole,
          // getDepartment,
          exchangePfdGrade,
          getRiskAlertValue,
        });
      })
      .catch((error) => {
        console.error('请求出错:', error);
      });
  };
  useEffect(() => {
    queryNodeInfo();
    const interval = setInterval(queryNodeInfo, 10000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getWindowSize = () => ({
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
  });

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [scale, setScale] = useState(1);

  const handleResize = () => {
    const number = (document.body.clientWidth * 1.0) / window.screen.availWidth;
    const __scale = Number(number.toFixed(2));
    setScale(__scale);
  };

  useEffect(() => {
    setWindowSize({
      innerHeight: document.body.clientHeight,
      innerWidth: window.screen.availWidth,
    });
    const number = (document.body.clientWidth * 1.0) / window.screen.availWidth;
    const __scale = Number(number.toFixed(2));
    setScale(__scale);
    // 监听
    window.addEventListener('resize', handleResize);
    // 销毁
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <ScaleWrapper scale={scale} className="element">
      {nodeInfo && publicData && <PubChart key="chart" publicData={publicData} nodeInfo={nodeInfo} scale={scale} />}
    </ScaleWrapper>
  );
};

export default Pub;
