import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import {Avatar, Button, Col, Form, Input, message, Modal, Row, Select, Space, Table, Upload} from 'antd';
import dayjs from 'dayjs';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import config from '../../config';
import {request, stringUtil} from '../../util';

const People = (props) => {
  const {roleList, departList} = props;
  const tableColumns = [
    {
      title: '序号',
      align: 'center',
      render: (text, user, index) => `${index + 1}`,
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: '头像',
      key: 'avatar',
      align: 'center',
      render: (text, user, index) => <Avatar size={35} src={user.face} />,
    },
    {
      title: '工号',
      dataIndex: 'number',
      key: 'number',
      align: 'center',
    },
    {
      title: '账号',
      dataIndex: 'account',
      key: 'account',
      align: 'center',
    },
    {
      title: '职位',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
    },
    {
      title: '部门',
      dataIndex: 'departmentName',
      key: 'departmentName',
      align: 'center',
    },
    {
      title: '负责人',
      key: 'leader_id',
      align: 'center',
      render: (text, user, index) => (user.user_id === user.leader_id ? '是' : '否'),
    },
    {
      title: '加入时间',
      key: 'joinTime',
      align: 'center',
      render: (text, user, index) => dayjs(user.create_time).format('YYYY-MM-DD'),
    },
    // {
    //   title: '状态',
    //   key: 'status',
    //   align: 'center',
    //   render: (text, user, index) => (user.state === 1 ? '正常' : '停用'),
    // },
    {
      title: '操作',
      key: 'action',
      align: 'center',
      className: 'com-table-btn-group',
      render: (text, user, index) => (
        <>
          <Button type="link" onClick={() => editUser(user)} size="small">
            编辑
          </Button>
          <Button type="link" onClick={() => setLeader(user)} size="small">
            设为负责人
          </Button>
          {userInfo && userInfo.title === '系统管理员' && (
            <Button type="link" onClick={() => resetPassword(user)} size="small">
              重置密码
            </Button>
          )}
          <Button type="link" danger onClick={() => deleteUser(user)} size="small">
            删除
          </Button>
        </>
      ),
    },
  ];
  const navigate = useNavigate();
  /**
   *  =========    查询    ===========
   */
  const searchFormInstance = useRef(null);
  const [searchParam, setSearchParam] = useState();
  const resetSearchFormAction = () => {
    var _a;
    (_a = searchFormInstance.current) === null || _a === void 0 ? void 0 : _a.resetFields();
    setSearchParam({});
  };
  const searchUsers = (param) => {
    setSearchParam(param);
  };
  const [userList, setUserList] = useState([]);
  const queryUserList = useCallback(() => {
    const param = Object.assign({}, searchParam);
    if (param.role_id === '0') param.role_id = undefined;
    request.post('/company/queryUserList', param, true).then((res) => {
      const __userList = res.userList.map((user) => ({...user, key: user.user_id}));
      setUserList(__userList);
    });
  }, [searchParam]);
  useEffect(() => {
    queryUserList();
  }, [queryUserList, searchParam]);
  /**
   *    ============== 新增/编辑 =============
   */
  const [isModalVisible, setIsModalVisible] = useState(false);
  const editFormInstance = useRef(null);
  const [type, setType] = useState('add');
  const [currentUser, setCurrentUser] = useState();
  const [face, setFace] = useState();
  const [loading, setLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const addUser = () => {
    setIsModalVisible(true);
    setType('add');
    setCurrentUser(undefined);
    setTimeout(() => {
      var _a;
      (_a = editFormInstance.current) === null || _a === void 0 ? void 0 : _a.resetFields();
      setFace(undefined);
    }, 200);
  };
  const editUser = (user) => {
    setCurrentUser(user);
    setType('edit');
    setIsModalVisible(true);
    // 当前编辑的用户是部门负责人，获取该部门的所有短信接收人
    for (const dept of departList) {
      if (dept.leader_id === user.user_id) {
        user.receivers = dept.receivers;
        break;
      }
    }
    setTimeout(() => {
      var _a;
      (_a = editFormInstance.current) === null || _a === void 0 ? void 0 : _a.setFieldsValue(user);
      setFace(user.face);
    }, 200);
  };
  const deleteUser = (user) => {
    setCurrentUser(user);
    setType('delete');
    setShowDelete(true);
  };
  const setLeader = (user) => {
    request
      .post(
        '/company/editDepartment',
        {
          department_id: user.department_id,
          leader_id: user.user_id,
        },
        true,
      )
      .then((res) => {
        message.success('操作成功', 1.5, queryUserList);
      });
  };
  const editFormFinish = (param) => {
    setIsModalVisible(false);
    setShowDelete(false);
    param.face = face;
    if (type === 'add') {
      param.password = stringUtil.encryption(param.password);
      request.post('/company/createUser', param, true).then((res) => {
        message.success('操作成功', 1.5, queryUserList);
      });
    } else if (type === 'edit') {
      request
        .post(
          '/company/editUser',
          Object.assign(Object.assign({}, param), {user_id: currentUser === null || currentUser === void 0 ? void 0 : currentUser.user_id}),
          true,
        )
        .then((res) => {
          message.success('操作成功', 1.5, queryUserList);
          departList.map((dept) =>
            dept.leader_id === (currentUser === null || currentUser === void 0 ? void 0 : currentUser.user_id)
              ? (dept.receivers = param.receivers)
              : dept,
          );
        });
    } else if (type === 'delete') {
      request.post('/company/deleteUser', currentUser, true).then((res) => {
        message.success('操作成功', 1.5, queryUserList);
      });
    }
  };

  function beforeUpload(file, fileList) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('您只能选择图片');
    }
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
      message.error('图片大小不能超过 4MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{marginTop: 8}}>上传图片</div>
    </div>
  );
  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      setFace(undefined);
      return;
    }
    setLoading(false);
    if (info.file.status === 'done') {
      const response = info.file.response;
      if (response.code === 200) {
        setFace(response.data.url);
      } else {
        message.error(response.msg);
      }
    } else if (info.file.status === 'error') {
      message.error('图片上传失败');
    }
  };
  const [userInfo, setUserInfo] = useState();
  useEffect(() => {
    request.post('/company/queryMineInfo', {}, false).then((res) => {
      setUserInfo(res);
    });
  }, []);
  const [isResetModalVisible, setIsResetModalVisible] = useState(false);
  const resetFormInstance = useRef(null);
  const resetPassword = (user) => {
    setCurrentUser(user);
    setIsResetModalVisible(true);
    user.password = '';
    setTimeout(() => {
      var _a;
      (_a = resetFormInstance.current) === null || _a === void 0 ? void 0 : _a.setFieldsValue(user);
    }, 200);
  };
  const resetFormFinish = (param) => {
    setIsResetModalVisible(false);
    setShowDelete(false);
    param.newPassword = stringUtil.encryption(param.password);
    param.userId = param.user_id;
    request.post('/company/changePassword', param, true).then((res) => {
      message.success('操作成功', 1.5);
    });
  };

  return (
    <>
      {/* part:筛选条件 */}
      <Form
        className="com-filter-form-wrapper pt-0"
        colon={true}
        layout={'inline'}
        ref={searchFormInstance}
        onFinish={searchUsers}
        key={'search-form'}>
        <Form.Item label="职位" name="department_id" key={'deps'}>
          <Select placeholder="请选择查询部门">
            <Select.Option value="" key={'all-deps'}>
              全部
            </Select.Option>
            {departList.map((r, idx) => (
              <Select.Option value={r.department_id} key={`dep-${idx}`}>
                {r.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="职位" name="role_id">
          <Select placeholder="请选择查询职位">
            <Select.Option value="">全部</Select.Option>
            {roleList.map((r) => (
              <Select.Option value={r.role_id} key={r.role_id}>
                {r.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="姓名" name="name">
          <Input placeholder="请输入筛选姓名" />
        </Form.Item>
        <Form.Item label="工号" name="number">
          <Input placeholder="请输入筛选工号" />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button shape="default" type="primary" className="com-btn" htmlType="submit">
              查询
            </Button>
            <Button shape="default" className="com-btn" onClick={resetSearchFormAction}>
              重置
            </Button>
          </Space>
        </Form.Item>
      </Form>
      {/* part:内容 */}
      <Row justify={'end'} align={'middle'} className="mt-base">
        <Space size={'middle'}>
          <Button size={'middle'} shape="default" className="com-btn" type="primary" onClick={() => navigate('/home/user-record')}>
            变更记录
          </Button>
          <Button size="middle" shape="default" icon={<PlusOutlined />} onClick={addUser} className="com-btn com-btn-block green">
            新增人员
          </Button>
        </Space>
      </Row>
      <Table pagination={{position: ['bottomCenter'], defaultPageSize: 10}} bordered className="mt-lg" columns={tableColumns} dataSource={userList} />
      {/* part:新增人员 */}
      <Modal
        className="com-add-modal"
        title={type === 'add' ? '新增人员' : '编辑人员'}
        width={'50%'}
        okButtonProps={{shape: 'round'}}
        cancelButtonProps={{shape: 'round'}}
        open={isModalVisible}
        onOk={() => editFormInstance.current?.submit()}
        onCancel={() => setIsModalVisible(false)}>
        <Form className="" colon={true} labelCol={{span: 8}} ref={editFormInstance} onFinish={editFormFinish}>
          <Row gutter={[20, 0]}>
            <Col xl={12} lg={24} sm={24}>
              <Form.Item label="头像">
                <Upload
                  listType="picture-card"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  action={config.apiHost + '/public/uploadLogoImage'}
                  onChange={handleChange}
                  headers={{Authorization: config.token || ''}}
                  accept="img">
                  {face ? <img src={face} alt="avatar" style={{width: '100%'}} /> : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[20, 0]}>
            <Col xl={12} lg={24} sm={24}>
              <Form.Item label="姓名" name="name" rules={[{required: true, message: '请输入员工姓名'}]}>
                <Input placeholder="请输入员工姓名" />
              </Form.Item>
            </Col>

            <Col xl={12} lg={24} sm={24}>
              <Form.Item label="工号" name="number">
                <Input placeholder="请输入员工工号" />
              </Form.Item>
            </Col>
            <Col xl={12} lg={24} sm={24}>
              <Form.Item label="手机号码" name="account" rules={[{required: true, message: '请输入员工手机号'}]}>
                <Input placeholder="请输入员工手机号" />
              </Form.Item>
            </Col>
            {type === 'add' && (
              <Col xl={12} lg={24} sm={24}>
                <Form.Item label="初始密码" name="password" rules={[{required: true, message: '请为员工设置初始密码'}]}>
                  <Input name="phone" placeholder="请为员工设置初始密码" />
                </Form.Item>
              </Col>
            )}

            <Col xl={12} lg={24} sm={24}>
              <Form.Item label="部门" name="department_id" rules={[{required: true, message: '请选择部门'}]}>
                <Select placeholder="请选择部门">
                  {departList.map((role) => (
                    <Select.Option value={role.department_id} key={role.department_id}>
                      {role.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={12} lg={24} sm={24}>
              <Form.Item label="职位" name="role_id" rules={[{required: true, message: '请选择职位'}]}>
                <Select placeholder="请选择职位">
                  {roleList.map((role) => (
                    <Select.Option value={role.role_id} key={role.role_id}>
                      {role.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {type === 'edit' && currentUser && currentUser.user_id === currentUser.leader_id ? (
              <Col xl={24} lg={24} sm={24}>
                <Form.Item label="危情短信推送通知" name="receivers">
                  <Input placeholder="多个手机号码请用逗号分隔" />
                </Form.Item>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </Form>
      </Modal>
      <Modal title="提示" open={showDelete} onOk={() => editFormFinish(currentUser)} onCancel={() => setShowDelete(false)}>
        <p>{`您确定删除 ${currentUser?.name} 吗?`}</p>
      </Modal>
      <Modal
        className=""
        title={'重置用户密码'}
        okButtonProps={{shape: 'round'}}
        cancelButtonProps={{shape: 'round'}}
        open={isResetModalVisible}
        onOk={() => resetFormInstance.current?.submit()}
        onCancel={() => setIsResetModalVisible(false)}>
        <Form className="" colon={true} ref={resetFormInstance} onFinish={resetFormFinish}>
          <Form.Item label="用户名" name="user_id" hidden={true}>
            <Input disabled />
          </Form.Item>
          <Form.Item label="用户名" name="name">
            <Input disabled />
          </Form.Item>
          <Form.Item label="手机号" name="account">
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="新密码"
            name="password"
            rules={[
              {
                min: 6,
                message: '密码长度必须超过6个字符',
              },
            ]}>
            <Input.Password placeholder="请输入新密码" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default People;
