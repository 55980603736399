import { Avatar, Breadcrumb, Col, Row } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { request } from '../../util';
const RoleRecordPage = () => {
    const navigate = useNavigate();
    useEffect(() => {
        queryRoleRecordList();
        queryDepartmentList();
    }, []);
    const [recordList, setRecordList] = useState([]);
    const queryRoleRecordList = () => {
        request.post('/company/queryRoleRecordList', {}, true).then((res) => {
            setRecordList(res.recordList);
        });
    };
    const [departmentList, setDepartmentList] = useState([]);
    const queryDepartmentList = () => {
        request.post('/company/queryDepartments', {}, true).then((res) => {
            setDepartmentList(res.departmentList);
        });
    };
    return (
        <div className="p-lg">
            {/* part:导航栏 */}
            <Breadcrumb className="mb-base">
                <Breadcrumb.Item>平台管理</Breadcrumb.Item>
                <Breadcrumb.Item>
                    <span onClick={() => navigate(-1)}>角色配置</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>修改记录</Breadcrumb.Item>
            </Breadcrumb>
            {/* part:内容 */}
            {recordList.map((record) => {
                const depart_old = record.role_old ? JSON.parse(record.role_old) : undefined;
                const depart_new = record.role_new ? JSON.parse(record.role_new) : undefined;
                let texts = [];
                switch (record.action) {
                    case 1:
                        texts.push(`新增职位: ${depart_new?.title}`);
                        break;

                    case 2:
                        texts.push(`删除职位: ${depart_old?.title}`);
                        break;

                    case 3:
                        if (depart_old?.title !== depart_new?.title) {
                            texts.push(`更新职位名称:${depart_old?.title} 为 ${depart_new?.title}`);
                        }
                        if (depart_old?.department_id !== depart_new?.department_id) {
                            texts.push(`更新职位所属部门:${departmentList.find((d) => d.department_id === depart_old?.department_id)?.name || ''} 为 ${departmentList.find((d) => d.department_id === depart_new?.department_id)?.name || ''}`);
                        }
                        break;
                    default:
                        break;
                }
                return (
                    <div className="mb-5 mt-0 com-white-block">
                        <Row justify={'space-between'}>
                            <Col md={4} sm={24} xs={24}>
                                时间: {dayjs(record.create_time).format('YYYY-MM-DD HH:mm')}
                            </Col>
                            <Col span={14}>
                                {texts.map((text) => (
                                    <p>{text}</p>
                                ))}
                            </Col>
                            <Col span={6}>
                                操作人：
                                <Avatar size={25} src={record.operator_face} />
                                {record.operator_name}
                            </Col>
                        </Row>
                    </div>
                );
            })}
        </div>
    );
};
export default RoleRecordPage;
