import { Button, Image } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
const NoMatch = () => {
    const navigate = useNavigate();
    return (
        <div style={{ backgroundColor: '#fff', height: '100%', display: 'flex', position: 'relative' }}>
            <Image preview={false} width="100%" height="100%" src={'https://safety-chart.oss-cn-beijing.aliyuncs.com/image/project/404.jpg'} />
            <Button style={{ position: 'absolute', bottom: '50px', right: '50px' }} size="large" type="primary" onClick={() => navigate(-1)}>
                返回
            </Button>
        </div>
    );
};
export default NoMatch;
