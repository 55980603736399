import {Upload, Button, Form, Input, message} from 'antd';
import {inject, observer} from 'mobx-react';
import React, {useState} from 'react';
import {request} from '../../util';
import {FileExcelOutlined} from '@ant-design/icons';
import {saveAs} from 'file-saver';

const OpcUA = () => {
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [opcuaMysqlConf, setOpcuaMysqlConf] = useState();
    const [form] = Form.useForm();
    const props = {
        name: 'file',
        accept: '.csv',
        fileList,
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([file]);
            return false;
        },
    };
    const onParse = async () => {
        if (!fileList) {
            message.error('请选择文件');
            return;
        }
        const formData = new FormData();
        fileList.forEach((f) => {
            formData.append('files[]', f);
        });
        const name = form.getFieldValue('name') || 'opcua_processdata';
        formData.append('name', name);
        const serverIP = form.getFieldValue('serverIP') || '127.0.0.1';
        formData.append('serverIP', serverIP);
        const mysql = form.getFieldValue('mysql') || '127.0.0.1';
        formData.append('mysql', mysql);
        setUploading(true);
        request
            .post('/company/parseOpcua', formData, true)
            .then((res) => {
                setUploading(false);
                const opcuaMysqlConf1 = new Blob([res.opcua_mysql_conf], {type: 'text/plain;charset=utf-8'});
                setOpcuaMysqlConf(opcuaMysqlConf1);
            })
            .catch((err) => {
                setUploading(false);
                console.error(err);
            });
    };
    const template = `Tag Name,Address,Data Type
"ramp1","ns=2;s=Channel2.Device1.ramp1",Long
"sine1","ns=2;s=Channel2.Device1.sine1",Float
`;
    return (
        <div className="setting-item">
            <div className="setting-title">OPC UA</div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                <Form form={form} labelCol={{span: 8}} wrapperCol={{span: 16}}>
                    <Form.Item style={{marginTop: 6, marginBottom: 6}} name="serverIP" label="OPC服务器地址">
                        <Input placeholder="127.0.0.1" />
                    </Form.Item>
                    <Form.Item style={{marginTop: 6, marginBottom: 6}} name="mysql" label="数据库服务器地址">
                        <Input placeholder="127.0.0.1" />
                    </Form.Item>
                    <Form.Item style={{marginTop: 6, marginBottom: 6}} name="name" label="数据库表名">
                        <Input placeholder="opcua_processdata" />
                    </Form.Item>
                </Form>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                    <Button
                        onClick={() => saveAs(new Blob([template], {type: 'text/plain;charset=utf-8'}), 'opcua_template.csv')}
                        style={{margin: 6}}
                    >
                        {'CSV模版'}
                    </Button>
                    <Upload {...props}>
                        <Button icon={<FileExcelOutlined />}>选择文件</Button>
                    </Upload>
                </div>
                <Button
                    type="primary"
                    onClick={onParse}
                    disabled={fileList.length === 0}
                    loading={uploading}
                    style={{
                        marginTop: 16,
                    }}
                >
                    生成配置文件
                </Button>
                {opcuaMysqlConf && (
                    <Button onClick={() => saveAs(opcuaMysqlConf, 'opcua_mysql.conf')} style={{marginTop: 6, marginBottom: 6}}>
                        {'opcua_mysql.conf'}
                    </Button>
                )}
            </div>
        </div>
    );
};
export default inject('publicData')(observer(OpcUA));
