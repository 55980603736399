import { Breadcrumb, Button, Col, Image, Input, Modal, Row, Space } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import config from '../../config';
import { request } from '../../util';
import { getRecordDiffList } from '../apply/recordDiff';
import './record.scss';
// page:记录详情
const SettingRecordDetail = () => {
    const navigate = useNavigate();
    const recordId = useParams().recordId;
    const [adopt_reason, setAdopt_reason] = useState();
    const [showSubmitModal, setShowSubmitModal] = useState(false);
    const [actionType, setActionType] = useState();
    const loadRecordInfo = useCallback(() => {
        request.post('/company/queryRecordDetail', { grade_record_id: recordId }, true).then((res) => {
            setRecord(res);
        });
    }, [recordId]);
    useEffect(() => {
        loadRecordInfo();
    }, [loadRecordInfo]);
    const [record, setRecord] = useState();
    const typeStr = ['未知', '等级修改申请', '参数修改申请', '选项修改申请'];
    const recordStateStr = ['等待审核', '审核通过', '审核驳回', '已撤回'];
    const recordStateClass = ['text-color-primary', 'text-color-green', 'text-color-danger', 'text-color-primary'];
    /**
     *  通过
     */
    const adoptAction = () => {
        setActionType('adopt');
        setShowSubmitModal(true);
    };
    /**
     *  驳回
     */
    const rejectAction = () => {
        setActionType('reject');
        setShowSubmitModal(true);
    };
    /**
     *  撤回
     */
    const withdrawAction = () => {
        request.post('/company/withdrawMyAskRecord', { grade_record_id: record === null || record === void 0 ? void 0 : record.grade_record_id }, true).then((res) => {
            loadRecordInfo();
        });
    };
    /**
     *  保存
     */
    const saveAction = () => {
        setShowSubmitModal(false);
        request
            .post('/company/adoptGradeRecord', { grade_record_id: record === null || record === void 0 ? void 0 : record.grade_record_id, adopt_reason, state: actionType === 'adopt' ? 1 : 2 }, true)
            .then((res) => {
            loadRecordInfo();
        });
    };
    const diffList = getRecordDiffList(record);
    return (
        <div className="p-lg record-detail-wrapper">
            {/* part:导航栏 */}
            <Breadcrumb>
                <Breadcrumb.Item>平台管理</Breadcrumb.Item>
                <Breadcrumb.Item>
                    <a href="/" onClick={() => navigate(-1)}>
                        设置记录
                    </a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>设置详情</Breadcrumb.Item>
            </Breadcrumb>
            {/* part:内容 */}
            <div className="font-size-xl font-weight-bold com-align_center mt-lg mb-lg">{typeStr[record?.type || 0]}</div>
            {/* 申请人信息 */}
            <Row className="com-white-block">
                <Col span={24} className="record-title">
                    申请人信息
                </Col>
                <Col className="mt-base" md={24} xs={24} sm={24}>
                    <span className="label">头像:</span>
                    <Image width={60} src={record?.apply_user?.face} />
                </Col>
                <Col className="mt-base" md={8} xs={12} sm={12}>
                    <span className="label">姓名:</span>
                    <span>{record?.apply_user?.name}</span>
                </Col>
                <Col className="mt-base" md={8} xs={12} sm={12}>
                    <span className="label">工号:</span>
                    <span>{record?.apply_user?.number || '无'}</span>
                </Col>
                <Col className="mt-base" md={8} xs={12} sm={12}>
                    <span className="label">账号:</span>
                    <span>{record?.apply_user?.account}</span>
                </Col>
                <Col className="mt-base" md={8} xs={12} sm={12}>
                    <span className="label">申请时间:</span>
                    <span>{dayjs(record?.create_time).format('YYYY-MM-DD HH:mm')}</span>
                </Col>
                <Col className="mt-base" md={8} xs={12} sm={12}>
                    <span className="label">职位:</span>
                    <span>{record?.apply_user?.title}</span>
                </Col>
            </Row>
            {/* 修改内容 */}
            <div className="com-white-block mt-5">
                <Row>
                    <Col span={24} className="record-title">
                        申请内容
                    </Col>
                    <Col className="mt-base" md={24} xs={24} sm={24}>
                        <span className="label">修改原因:</span>
                        <span>{record?.apply_reason}</span>
                    </Col>
                </Row>
                <Row className="mt-base">
                    <Col className="label">修改内容:</Col>
                    {diffList?.map((dif) => (
                        <>
                            <Col style={{fontSize: 14, fontWeight: 'bold'}}>{dif.title}</Col>
                            {dif.diffList.map((content) => (
                                <Col span={24} className="mt-10 text-color-second">
                                    {content}
                                </Col>
                            ))}
                        </>
                    ))}
                </Row>
            </div>
            {/* 未审核 */}
            {record?.state === 0 && (
                <Row className="com-white-block mt-5" justify={'end'} align={'middle'}>
                    <Space size={'middle'} className="com-align_right">
                        {record.apply_user_id === config.userInfo?.user_id && (
                            <Button type="primary" danger className="com-btn" size={'small'} shape="default" onClick={() => withdrawAction()}>
                                撤回
                            </Button>
                        )}
                        <Button className="com-btn com-btn-block green" size={'small'} shape="default" onClick={() => adoptAction()}>
                            通过
                        </Button>
                        <Button className="com-btn" danger type="primary" size={'small'} shape="default" onClick={() => rejectAction()}>
                            驳回
                        </Button>
                    </Space>
                </Row>
            )}

            {/* 已审核 */}
            {(record?.state === 1 || record?.state === 2) && (
                <Row className="com-white-block mt-5">
                    <Col span={24} className="record-title">
                        审批人信息
                    </Col>
                    <Col className="mt-base" md={24} xs={24} sm={24}>
                        <span className="label">头像:</span>
                        <Image width={60} src={record?.adopt_user?.face} />
                    </Col>
                    <Col className="mt-base" md={8} xs={12} sm={12}>
                        <span className="label">姓名:</span>
                        <span>{record?.adopt_user?.name}</span>
                    </Col>
                    <Col className="mt-base" md={8} xs={12} sm={12}>
                        <span className="label">职位:</span>
                        <span>{record?.adopt_user?.title}</span>
                    </Col>

                    <Col className="mt-base" md={8} xs={12} sm={12}>
                        <span className="label">工号:</span>
                        <span>{record?.adopt_user?.number || '无'}</span>
                    </Col>

                    <Col className="mt-base" md={8} xs={12} sm={12}>
                        <span className="label">账号:</span>
                        <span>{record?.adopt_user?.account}</span>
                    </Col>
                    <Col className="mt-base" md={8} xs={12} sm={12}>
                        <span className="label">审批时间:</span>
                        <span>{dayjs(record?.adopt_time).format('YYYY-MM-DD HH:mm')}</span>
                    </Col>
                    <Col className="mt-base" md={8} xs={12} sm={12}>
                        <span className="label">审批结果:</span>
                        <span className={`${recordStateClass[record.state]} font-weight-bold`}>{recordStateStr[record.state]}</span>
                    </Col>
                    <Col className="mt-base" md={16} xs={12} sm={12}>
                        <span className="label">审批说明:</span>
                        <span>{record.adopt_reason}</span>
                    </Col>
                </Row>
            )}
            {/* 已撤回 */}

            {record?.state === 3 && (
                <Row className="com-white-block mt-5">
                    <Col className="mt-base" md={16} xs={12} sm={12}>
                        <span className="label">审批结果:</span>
                        <span>已撤回</span>
                    </Col>
                </Row>
            )}

            <Modal
                title="审批意见"
                okButtonProps={{shape: 'round'}}
                cancelButtonProps={{shape: 'round'}}
                visible={showSubmitModal}
                onOk={saveAction}
                onCancel={() => setShowSubmitModal(false)}>
                <Col span={24}>
                    <Input placeholder="请输入修改原因" value={adopt_reason} onChange={(e) => setAdopt_reason(e.target.value)} />
                </Col>
            </Modal>
        </div>
    );
};
export default SettingRecordDetail;
