// noinspection JSUnresolvedVariable,JSIgnoredPromiseFromCall

import {message} from 'antd';
import axios from 'axios';
import config from '../config';
import log from 'loglevel';
log.setLevel(config.debug ? log.levels.DEBUG : log.levels.ERROR);
message.config({
  maxCount: 1,
});
// noinspection JSUnusedLocalSymbols
class Request {
  static buildUrl(url, params) {
    if (!params) return url;
    const result = Request.getParamsArray(params);
    if (result.length === 0) {
      return url;
    }
    let prefix = '?';
    if (url.indexOf('?') > -1) {
      prefix = '&';
    }
    return url + prefix + result.join('&');
  }
  static getParamsArray(params) {
    const result = [];
    if (!params) return result;
    Object.keys(params).forEach((key) => {
      if (params.hasOwnProperty(key) && !!params[key]) {
        const value = params[key];
        result.push(`${key}=${encodeURIComponent(value)}`);
      }
    });
    return result;
  }
  // 清除参数中的空
  static cleanParam(params = {}) {
    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        const element = params[key];
        if (element === '' || element === undefined || element === null) {
          delete params[key];
        }
      }
    }
    return params;
  }
  /**
   *  http请求参以及响应处理
   */
  static requestTask(url, method, params = {}, loading = false, pretreatment = true) {
    params = Request.cleanParam(params);
    const requestData = {
      url,
      baseURL: config.apiHost,
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': method === 'GET' ? 'application/x-www-form-urlencoded;charset=UTF-8' : 'application/json;charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true,
        Authorization: `Bearer ${config.token || ''}`,
      },
      data: params,
    };
    // if (loading) {
    //   message.loading('奋力加载中...', 5000);
    // }
    return new Promise((resolve, reject) => {
      axios(requestData)
        .then((res) => {
          // if (loading) {
          //     message.destroy();
          // }
          if (res.status === 200) {
            const response = res.data;
            const {code} = response;
            const {data} = response;
            const msgStr = response.msg;
            if (code === 200) {
              resolve(data);
            } else {
              if (loading) {
                message.error(msgStr);
              }
              if (code === 201) {
                // 登录失效
                config.token = null;
                config.userInfo = undefined;
                config.companyInfo = undefined;
                window.localStorage.removeItem('company.token');
                const href = window.location.href;
                if (!href.includes(/\/pub\/|\/connect\/|\/login\//)) {
                  window.location.href = `${window.location.origin}${config.staticPath}#/login`;
                }
              }
              reject({message: msgStr, code});
            }
          } else {
            reject({message: '请求失败,请重试', rescode: 400});
          }
        })
        .catch((err) => {
          // if (loading) {
          //     //  关闭转圈
          //     message.destroy();
          //     // 网络错误 提示，并回调
          //     message.error('网络差，请稍后再试。');
          // }
          reject({message: '网络差，请稍后再试。', rescode: 500});
        });
    });
  }
  /**
   *  POST 请求
   */
  static post(url, params = {}, loading = false, pretreatment = true) {
    return this.requestTask(url, 'POST', params, loading, pretreatment);
  }
  /**
   *  GET 请求
   */
  static get(url, params = {}, loading = false, pretreatment = true) {
    return this.requestTask(url, 'GET', params, loading, pretreatment);
  }
}
export default Request;
