// page:home 主页
import {MenuOutlined, UserOutlined, DownOutlined} from '@ant-design/icons';
import {Avatar, Badge, Button, Drawer, Image, Layout, Modal, message, Dropdown, Space, Form, Input} from 'antd';
import React, {useEffect, useState, useRef} from 'react';
import {useNavigate, Outlet} from 'react-router-dom';
import MenuIndex, {MobileMenuIndex} from '../../component/menu';
import config from '../../config';
import {request, useLogout, stringUtil} from '../../util';
import './index.scss';
const Home = () => {
  // menu Drawer
  const [showDrawerMenus, setShowDrawerMenus] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const logout = useLogout();
  const onDrawerOpen = () => {
    setShowDrawerMenus(true);
  };
  const onDrawerClose = () => {
    setShowDrawerMenus(false);
  };
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(config.userInfo);
  const [company, setCompany] = useState(config.companyInfo);
  const [noticeList, setNoticeList] = useState([]);
  useEffect(() => {
    if (!userInfo) {
      request.post('/company/queryMineInfo', {}, false).then((res) => {
        config.userInfo = res;
        setUserInfo(res);
      });
      request.post('/company/queryMyCompanyInfo', {}, false).then((res) => {
        config.companyInfo = res;
        setCompany(res);
      });
    } else {
      request.post('/company/queryNoticeList', {}, true).then((res) => {
        setNoticeList(res.noticeList);
      });
    }
  }, [userInfo]);
  useEffect(() => {
    const interval = setInterval(() => {
      request.get('/pingKepserver', {}, true).then((res) => {
        if (!res.online) {
          message.error('数据链路掉线或故障，请联系技术人员');
        }
      });
    }, 60000);
    return () => clearInterval(interval);
  });
  const unReadNotice = (noticeList === null || noticeList === void 0 ? void 0 : noticeList.filter((n) => n.state === 0)) || [];
  const logoutClick = () => {
    setShowLogout(false);
    logout();
  };
  const onClick = ({key}) => {
    if (key === '1') {
      resetPassword(userInfo);
    } else if (key === '2') {
      setShowLogout(true);
    }
  };
  const items = [
    {
      label: '修改密码',
      key: '1',
    },
    {
      label: '退出登录',
      key: '2',
    },
  ];
  const [isResetModalVisible, setIsResetModalVisible] = useState(false);
  const resetFormInstance = useRef(null);
  const resetPassword = (user) => {
    console.table(user);
    setIsResetModalVisible(true);
    user.password = '';
    setTimeout(() => {
      var _a;
      (_a = resetFormInstance.current) === null || _a === void 0 ? void 0 : _a.setFieldsValue(user);
    }, 200);
  };
  const resetFormFinish = (param) => {
    setIsResetModalVisible(false);
    param.newPassword = stringUtil.encryption(param.password);
    param.userId = param.user_id;
    request.post('/company/changePassword', param, true).then((res) => {
      message.success('修改成功，请使用新密码重新登录', 1.5, logoutClick);
    });
  };
  return (
    <Layout className="home-container">
      {/* part:header 头部 */}
      <Layout.Header className="home-header-content com-flex">
        <div className="home-logo com-flex flex-align_center">
          <Image className="mr-10" height={40} width={80} src={company?.logo} alt="logo" preview={false} />
          <h1>{company?.company_name}</h1>
        </div>
        <ul className="header-user-info view-large com-flex flex-align_center">
          <i className="message">
            <Badge dot={unReadNotice.length > 0}>
              <span onClick={() => navigate('/home/notice')}>消息</span>
            </Badge>
            <span className="ml-5">({unReadNotice.length})</span>
          </i>
          <li>
            <div className="vertical-line">&nbsp;</div>
          </li>
          <li>
            <Dropdown
              menu={{
                items,
                onClick,
              }}>
              <Button type="link" onClick={(e) => e.preventDefault()}>
                <Space>
                  我的账号
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </li>
          <li>
            <div className="vertical-line">&nbsp;</div>
          </li>
          <li>
            {/* <Avatar size={35} icon={<UserOutlined />} src={userInfo?.face} /> */}
            <span className="ml-10 username">欢迎您，{userInfo?.name}</span>
          </li>
        </ul>
        {/* small screen menus show */}
        <MenuOutlined className="view-small icons-menu" onClick={onDrawerOpen} />
      </Layout.Header>
      {/* part:small screen menus show */}
      <Drawer
        title={
          <>
            <Avatar size={35} icon={<UserOutlined />} src={userInfo?.face} />
            <span className="ml-10 username">
              欢迎您，{userInfo?.name} / {userInfo?.title}
            </span>
          </>
        }
        className="drawer-menus"
        placement="right"
        closable={false}
        onClose={onDrawerClose}
        open={showDrawerMenus}>
        {/* <MenuIndex /> */}
        <MobileMenuIndex onDrawerClose={onDrawerClose} />
        <div className="small-btn-group">
          <Button shape="default" type="primary" block onClick={() => setShowLogout(true)}>
            退出登录
          </Button>
        </div>
      </Drawer>
      {/* part:内容 */}
      <Layout>
        {/* part:sider 左侧侧边栏 */}
        <Layout.Sider className="side-content view-large" theme="light">
          <MenuIndex />
        </Layout.Sider>
        {/* part：内容页面 */}
        <Layout.Content>
          {/*<Routes>*/}
          {/*    {Router.map((item, index) => {*/}
          {/*        return <Route key={index} path={item.path} element={<item.component/>}></Route>;*/}
          {/*    })}*/}
          {/*</Routes>*/}
          <Outlet />
        </Layout.Content>
      </Layout>
      <Modal title="提示" open={showLogout} onOk={logoutClick} onCancel={() => setShowLogout(false)}>
        <p>{`您确定退出登录吗?`}</p>
      </Modal>
      <Modal
        className=""
        title={'修改密码'}
        okButtonProps={{shape: 'round'}}
        cancelButtonProps={{shape: 'round'}}
        open={isResetModalVisible}
        onOk={() => resetFormInstance.current?.submit()}
        onCancel={() => setIsResetModalVisible(false)}>
        <Form className="" colon={true} ref={resetFormInstance} onFinish={resetFormFinish}>
          <Form.Item label="用户名" name="user_id" hidden={true}>
            <Input disabled />
          </Form.Item>
          <Form.Item label="用户名" name="name">
            <Input disabled />
          </Form.Item>
          <Form.Item label="手机号" name="account">
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="新密码"
            name="password"
            rules={[
              {
                min: 6,
                message: '密码长度必须超过6个字符',
              },
            ]}>
            <Input.Password placeholder="请输入新密码" />
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  );
};
export default Home;
