import {Image} from 'antd';
import {inject, observer} from 'mobx-react';
import React from 'react';
import {createPopoverItem} from './pubComponent';
import config from '../../../config';
import log from 'loglevel';
log.setLevel(config.debug ? log.levels.DEBUG : log.levels.ERROR);
const BarrierView = (props) => {
  let _a, _b, _c, _d;
  const workType = (_a = props.publicData) === null || _a === void 0 ? void 0 : _a.getBarrierWorkType(props.barrier.barrier_work_type_id);
  const barrierPopInfo = [];
  barrierPopInfo.push(`编号:${props.barrier.number}`);
  barrierPopInfo.push(`名称:${props.barrier.info}`);
  barrierPopInfo.push(
    `部门:${
      (_d =
        (_b = props.publicData) === null || _b === void 0
          ? void 0
          : _b.getDepartment((_c = props.barrier) === null || _c === void 0 ? void 0 : _c.department_id)) === null || _d === void 0
        ? void 0
        : _d.name
    }`,
  );
  barrierPopInfo.push(`PFD逻辑:${props.barrier.pfdTags}`);
  let linkImage = 'https://safety-chart.oss-cn-beijing.aliyuncs.com/image/project/barrierLink.png';
  const onDoubleClick = () => {
    props.onEditBarrier(props);
  };
  const onDragStart = (e) => {
    global.dragItem = e.target.id;
  };
  const onClick = () => {
    props.onSelectBarrier(props.barrier);
  };
  const barrierComponent = (
    <div
      className="barrier-view"
      id={`barrier_${props.streamIndex}_${props.from}_${props.fromIndex}_${props.barrierIndex}`}
      onDoubleClick={onDoubleClick}
      onClick={onClick}
      draggable
      onDragStart={onDragStart}>
      <Image src={linkImage} width="12px" height="28px" style={{alignSelf: 'center'}} />
      <div
        className="barrier-item"
        style={{
          backgroundColor: 'white',
          borderStyle: props.barrier.exist_type === 1 ? 'solid' : 'dashed',
          borderColor:
            props.selectedBarrier?.barrier_work_type_id === props.barrier?.barrier_work_type_id &&
            props.selectedBarrier?.number === props.barrier?.number
              ? 'red'
              : '#a0a0a0',
          borderWidth:
            props.selectedBarrier?.barrier_work_type_id === props.barrier?.barrier_work_type_id &&
            props.selectedBarrier?.number === props.barrier?.number
              ? 2
              : 1,
        }}>
        <span className="barrier-circle-number">{props.barrier.number || '1'}</span>
        <div className="barrier-info">
          <span>{workType?.label}</span>
        </div>
        <div className="barrier-state" style={{backgroundColor: '#4cd964'}}>
          <span>{'正常'}</span>
        </div>
      </div>
    </div>
  );
  // if (workType?.label === config.PSVBarrierWorkType) {
  //   barrierPopInfo.push(`仪表位号:${props.barrier.tag || ''}`);
  //   barrierPopInfo.push(`整定压力:${props.barrier.pressure_value || ''}`);
  //   barrierPopInfo.push(`保护时间:${props.barrier.reaction_time}秒`);
  // } else {
  barrierPopInfo.push(`触发逻辑:${props.barrier.touchTags}`);
  barrierPopInfo.push(`失效逻辑:${props.barrier.bypassTags}`);
  barrierPopInfo.push(`保护时间:${props.barrier.reaction_time}秒`);
  barrierPopInfo.push(`短信通知:${props.barrier.flag === 0 ? '是' : '否'}`);
  // };
  return createPopoverItem(barrierComponent, barrierPopInfo, 'top');
};
export default inject('editNode', 'publicData')(observer(BarrierView));
