import { Button, Form, Input, Modal, Row, Select, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { inject, observer } from 'mobx-react';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import config from '../../../config';
import log from 'loglevel';
log.setLevel(config.debug ? log.levels.DEBUG : log.levels.ERROR);
const StreamModal = (props, ref) => {
    const [streamForm] = useForm();
    const [showStreamModal, setShowStreamModal] = useState(false);
    const [streamIndex, setStreamIndex] = useState(0);
    const [type, setType] = useState('add');
    const [editData, setEditData] = useState();
    const onFinish = (data) => {
        if (type === 'add') {
            data.startEventList = [];
            data.resultList = [];
            props.editNode.addStream(streamIndex, data);
        }
        else {
            // 保护层要缓存下来
            props.editNode.updateStream(streamIndex, Object.assign(Object.assign({}, editData === null || editData === void 0 ? void 0 : editData.stream), data));
        }
        streamForm.resetFields();
        setShowStreamModal(false);
    };
    const onReset = () => {
        streamForm.resetFields();
        setShowStreamModal(false);
    };
    // 点击删除
    const onDelete = () => {
        streamForm.resetFields();
        setShowStreamModal(false);
        props.editNode.deleteStream(streamIndex);
    };
    // 复制操作
    const copyItem = () => {
        streamForm.resetFields();
        setShowStreamModal(false);
        props.editNode.copyStream(streamIndex);
    };
    useImperativeHandle(ref, () => ({
        open: (index, data) => {
            log.debug('打开事件流弹窗 ', data, index);
            setShowStreamModal(true);
            setStreamIndex(index);
            if (data) {
                setType('edit');
                setEditData(data);
                streamForm.setFieldsValue(data.stream);
            }
            else {
                setType('add');
            }
        },
        close: () => {
            setShowStreamModal(false);
        },
    }));
    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };
    return (
        <Modal title="添加事件流" visible={showStreamModal} closable={false} footer={null}>
            <Form form={streamForm} onFinish={onFinish} onReset={onReset} {...layout}>
                <Form.Item label="顶级事件" name="top_event_type_id" rules={[{required: true, message: '请选择顶级事件'}]}>
                    <Select placeholder="请选择顶级事件">
                        {props.publicData?.optionList.topEventTypeList?.map((t) => (
                            <Select.Option value={t.top_event_type_id || ''} key={t.top_event_type_id}>
                                {t.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="名称：" name="info" rules={[{required: true, message: '请输入事件名称'}]}>
                    <Input placeholder="请输入事件名称" maxLength={200} />
                </Form.Item>
                <Form.Item>
                    <Row justify="end">
                        <Space size="middle">
                            {type === 'edit' && (
                                <Button type="link" htmlType="button" onClick={copyItem}>
                                    复制
                                </Button>
                            )}
                            {type === 'edit' && (
                                <Button type="link" danger htmlType="button" onClick={onDelete}>
                                    删除
                                </Button>
                            )}
                            <Button type="default" htmlType="reset">
                                取消
                            </Button>

                            <Button type="primary" htmlType="submit">
                                确定
                            </Button>
                        </Space>
                    </Row>
                </Form.Item>
            </Form>
        </Modal>
    );
};
export default inject('editNode', 'publicData')(observer(forwardRef(StreamModal)));
