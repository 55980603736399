import { Table, Tabs } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { request } from '../../util';
import './index.scss';
const RiskReport = (props) => {
    const [reportType, setReportType] = useState('1');
    const [reportList, setReportList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [current, setCurrent] = useState(1);
    const queryReportList = (reportType, pageNum, pageSize) => {
        const param = {
            type: reportType,
            pageNum,
            pageSize,
        };
        request.post('/company/queryReportList', param, true).then((res) => {
            setReportList(res.reportList);
            setTotalCount(res.count);
            setCurrent(res.pageNum + 1);
        });
    };
    useEffect(() => {
        queryReportList(reportType, 0, 10);
    }, [reportType]);
    const tabChange = (activeKey) => {
        setReportType(activeKey);
        setCurrent(1);
    };
    const columns = [
        {
            title: '报告类型',
            align: 'center',
            key: 'title',
            dataIndex: 'title',
            width: 100,
        },
        {
            title: '统计起始时间',
            align: 'center',
            key: 'start_time',
            dataIndex: 'start_time',
            width: 150,
        },
        {
            title: '统计结束时间',
            align: 'center',
            key: 'end_time',
            dataIndex: 'end_time',
            width: 100,
        },
        {
            title: '报告生成时间',
            align: 'center',
            key: 'create_time',
            dataIndex: 'create_time',
            render: (value) => dayjs(value).format('YYYY-MM-DD HH:mm:ss'),
            width: 100,
        },
        {
            title: '操作',
            key: 'action',
            align: 'center',
            fixed: 'right',
            width: 100,
            render: (text, report, index) => <a href={report.link_url}>下载报告</a>,
        },
    ];
    const pageChange = (page, pageSize) => {
        queryReportList(reportType, page - 1, 10);
    };
    return (
        <div className="alert-report">
            <Tabs defaultActiveKey={reportType} tabBarStyle={{paddingLeft: '20px'}} onChange={tabChange}>
                <Tabs.TabPane tab="高风险事件报告" key="1"></Tabs.TabPane>
                <Tabs.TabPane tab="节点事件报告" key="2"></Tabs.TabPane>
                <Tabs.TabPane tab="保护层事件报告" key="3"></Tabs.TabPane>
                <Tabs.TabPane tab="起始事件报告" key="4"></Tabs.TabPane>
                <Tabs.TabPane tab="设计错误报告" key="5"></Tabs.TabPane>
            </Tabs>
            <Table
                dataSource={reportList}
                pagination={{position: ['bottomRight'], total: totalCount, onChange: pageChange, current: current}}
                columns={columns}
                scroll={{x: 1080, y: 500}}
            />
        </div>
    );
};
export default RiskReport;
