import {Menu} from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import React from 'react';
import {Link} from 'react-router-dom';
import './index.scss';
import {
    AreaChartOutlined,
    BranchesOutlined,
    MessageOutlined,
    DatabaseOutlined,
    RocketOutlined,
    ApartmentOutlined,
    CarryOutOutlined,
} from '@ant-design/icons';
import config from '../../config';
import log from 'loglevel';
log.setLevel(config.debug ? log.levels.DEBUG : log.levels.ERROR);
const MenuIndex = () => {
    // const [menuCurrent, setMenuCurrent] = useState<string>('node-process');
    const onMenuClick = (e) => {
        log.debug('导航点击', e);
        // setMenuCurrent(e.key);
    };
    return (
        <Menu onClick={onMenuClick} className="side-menu" mode="inline" defaultSelectedKeys={['risk_node']}>
            <Menu.Item key="dashboard" icon={<RocketOutlined />}>
                <Link to="/dashboard">风险动态</Link>
            </Menu.Item>
            <SubMenu key="risk" icon={<AreaChartOutlined />} title="风险统计">
                <Menu.Item key="risk_node">
                    <Link to="/home/risk-node">高风险事件统计</Link>
                </Menu.Item>
                <Menu.Item key="node-risk-chart">
                    <Link to="/home/node-risk-chart">节点风险历史趋势</Link>
                </Menu.Item>
                <Menu.Item key="node-history">
                    <Link to="/home/node-history">节点历史事件统计</Link>
                </Menu.Item>
                <Menu.Item key="node-process">
                    <Link to="/home/node-process">过程事件统计</Link>
                </Menu.Item>
                <Menu.Item key="risk-report">
                    <Link to="/home/risk-report">风险统计报告</Link>
                </Menu.Item>
            </SubMenu>
            <SubMenu key="solution" icon={<CarryOutOutlined />} title="闭环管理">
                <Menu.Item key="process-solution">
                    <Link to="/home/process-solution">过程事件处理</Link>
                </Menu.Item>
                <Menu.Item key="risk-solution">
                    <Link to="/home/risk-solution">高风险事件处理</Link>
                </Menu.Item>
            </SubMenu>
            <SubMenu key="node_manager" icon={<ApartmentOutlined />} title="节点管理">
                <Menu.Item key="chartList">
                    <Link to="/home/charts">节点列表</Link>
                </Menu.Item>
                <Menu.Item key="alertBarrier">
                    <Link to="/home/setting">节点配置</Link>
                </Menu.Item>
                <Menu.Item key="role">
                    <Link to="/home/role">岗位配置</Link>
                </Menu.Item>
            </SubMenu>
            <SubMenu key="company-info" icon={<DatabaseOutlined />} title="企业信息">
                <Menu.Item key="companyInfo">
                    <Link to="/home/company-info">企业信息</Link>
                </Menu.Item>
            </SubMenu>
            <SubMenu key="message" icon={<MessageOutlined />} title="系统消息">
                <Menu.Item key="risk-message">
                    <Link to="/home/risk-message">风险事件消息</Link>
                </Menu.Item>
                <Menu.Item key="notice">
                    <Link to="/home/notice">系统消息</Link>
                </Menu.Item>
                <Menu.Item key="loginlogs">
                    <Link to="/home/loginlogs">登录日志</Link>
                </Menu.Item>
            </SubMenu>
        </Menu>
    );
};
export const MobileMenuIndex = ({onDrawerClose}) => {
    // const [menuCurrent, setMenuCurrent] = useState<string>('node-process');
    const onMenuClick = (e) => {
        log.debug('导航点击', e);
        onDrawerClose();
        // setMenuCurrent(e.key);
    };
    return (
        <Menu onClick={onMenuClick} className="side-menu" mode="inline" defaultSelectedKeys={['risk_node']}>
            <Menu.Item key="dashboard" icon={<RocketOutlined />}>
                <Link to="/dashboard">风险动态</Link>
            </Menu.Item>
            <SubMenu key="risk" icon={<AreaChartOutlined />} title="风险统计">
                <Menu.Item key="risk_node">
                    <Link to="/home/risk-node">高风险事件统计</Link>
                </Menu.Item>
                <Menu.Item key="node-risk-chart">
                    <Link to="/home/node-risk-chart">节点风险历史趋势</Link>
                </Menu.Item>
                <Menu.Item key="node-history">
                    <Link to="/home/node-history">节点历史事件统计</Link>
                </Menu.Item>
                <Menu.Item key="node-process">
                    <Link to="/home/node-process">过程事件统计</Link>
                </Menu.Item>
                <Menu.Item key="risk-report">
                    <Link to="/home/risk-report">风险统计报告</Link>
                </Menu.Item>
            </SubMenu>
            <SubMenu key="node_manager" icon={<BranchesOutlined />} title="节点管理">
                <Menu.Item key="chartList">
                    <Link to="/home/charts">节点列表</Link>
                </Menu.Item>
            </SubMenu>
            <SubMenu key="company-info" icon={<DatabaseOutlined />} title="企业信息">
                <Menu.Item key="companyInfo">
                    <Link to="/home/company-info">企业信息</Link>
                </Menu.Item>
            </SubMenu>
            <SubMenu key="message" icon={<MessageOutlined />} title="系统消息">
                <Menu.Item key="risk-message">
                    <Link to="/home/risk-message">风险事件消息</Link>
                </Menu.Item>
                <Menu.Item key="notice">
                    <Link to="/home/notice">系统消息</Link>
                </Menu.Item>
                <Menu.Item key="loginlogs">
                    <Link to="/home/loginlogs">登录日志</Link>
                </Menu.Item>
            </SubMenu>
        </Menu>
    );
};
export default MenuIndex;
